<mat-sidenav-container>
    <div id="cards" class="page-layout emma simple fullwidth">
        <!-- Header -->
        <div  class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between" fxFlex="1 0 auto">
            <!-- Title -->
            <div class="title-container">
                <h1>{{'DASHBOARD.Title' | translate}}</h1>
                <h1 class="view-title"> {{viewTitle}}</h1>
            </div>

            <!-- Toolbar -->
            <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOrder="3">
                <div *ngIf="!editing">
                    <button *ngIf="isKioskMode" mat-button (click)="reloadPage()">
                        <mat-icon>refresh
                        </mat-icon>
                    </button>

                    <button mat-button (click)="changeKioskMode()">
                        <mat-icon>present_to_all
                        </mat-icon>
                    </button>

                    <button *ngIf="currentView" mat-button (click)="edit()">
                        <mat-icon>edit
                        </mat-icon>
                    </button>

                    <!-- Dashboard View Buttons -->
                    <button mat-raised-button class="navbar-button" (click)="dashboardSidenav.toggle()">
                        <mat-icon>view_compact
                        </mat-icon>
                        {{'DASHBOARD.SwitchViews' | translate}}
                    </button>
                </div>
                <div *ngIf="editing">
                    <mat-checkbox [(ngModel)]="showConnected" (ngModelChange)="onShowConnected()">{{'DEVICES.ConnectedDevices' | translate}}</mat-checkbox>
                    <mat-checkbox [(ngModel)]="showEventStream" (ngModelChange)="onShowEventStream()">{{'DASHBOARD.EventStream' | translate}}</mat-checkbox>
                    <button mat-raised-button class="navbar-button" color="warn" (click)="edit()">
                        <mat-icon>check
                        </mat-icon>
                        {{'DASHBOARD.Done' | translate}}
                    </button>
                </div>

                <!--button mat-icon-button aria-label="Settings" matTooltip="Settings">

                </button-->
                <!-- / Dashboard View Buttons -->
            </div>
            <!-- / Toolbar -->

        </div>
        <!-- / Header -->

        <div class="content">
            <gridster [options]="options">
                <gridster-item [item]="graph" *ngFor="let graph of graphs">
                    <ng-container [ngSwitch]="graph.dataType">
                        <app-connected-devices *ngSwitchCase="DataTypeModelKey.onlineDevices" class="emma-card auto-width" [devices]="devices" [ngClass]="{'emma-card-kiosk': isKioskMode}">
                        </app-connected-devices>

                        <device-card-soundpressure *ngSwitchCase="DataTypeModelKey.soundPressureLevel" class="emma-card auto-width"
                            [device]="graph.device" [ngClass]="{'emma-card-kiosk': isKioskMode}">
                        </device-card-soundpressure>

                        <device-band-spectra *ngSwitchCase="DataTypeModelKey.spectrum13Octave" class="emma-card auto-width" [device]="graph.device" [ngClass]="{'emma-card-kiosk': isKioskMode}">
                        </device-band-spectra>

                        <device-card-battery *ngSwitchCase="DataTypeModelKey.battery" class="emma-card auto-width" [device]="graph.device" [ngClass]="{'emma-card-kiosk': isKioskMode}">
                        </device-card-battery>

                        <div *ngSwitchCase="DataTypeModelKey.eventStream" class="emma-card auto-width">
                            <div class="emma-card__header">Events Stream</div>
                            <event-stream></event-stream>
                        </div>
                    </ng-container>
                </gridster-item>
            </gridster>
        </div>
    </div>

    <!-- Side nav -->
    <mat-sidenav #dashboardSidenav position="end">
        <app-dashboard-sidebar></app-dashboard-sidebar>
    </mat-sidenav>

</mat-sidenav-container>