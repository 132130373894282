/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./device-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/device-card-soundpressure/device-card-soundpressure.component.ngfactory";
import * as i3 from "../../../../components/device-card-soundpressure/device-card-soundpressure.component";
import * as i4 from "../../../../../@fuse/services/translation-loader.service";
import * as i5 from "../../../../services/api/data.service";
import * as i6 from "../../../../services/api/projectDevice.service";
import * as i7 from "../../../../services/api/device.mqtt.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../../services/context.service";
import * as i10 from "../../../../components/device-card-band-spectra/device-band-spectra.component.ngfactory";
import * as i11 from "../../../../components/device-card-band-spectra/device-band-spectra.component";
import * as i12 from "../../../../components/device-card-battery/device-card-battery.component.ngfactory";
import * as i13 from "../../../../components/device-card-battery/device-card-battery.component";
import * as i14 from "../../../../components/device-card-cellular/device-card-cellular.component.ngfactory";
import * as i15 from "../../../../components/device-card-cellular/device-card-cellular.component";
import * as i16 from "../../../../components/device-card-free-storage/device-card-free-storage.component.ngfactory";
import * as i17 from "../../../../components/device-card-free-storage/device-card-free-storage.component";
import * as i18 from "@angular/common";
import * as i19 from "./device-status.component";
var styles_DeviceStatusComponent = [i0.styles];
var RenderType_DeviceStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeviceStatusComponent, data: {} });
export { RenderType_DeviceStatusComponent as RenderType_DeviceStatusComponent };
function View_DeviceStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "device-card-soundpressure", [["class", "emma-card auto-width"]], null, null, null, i2.View_DeviceCardSoundPressure_0, i2.RenderType_DeviceCardSoundPressure)), i1.ɵdid(1, 245760, null, 0, i3.DeviceCardSoundPressure, [i4.FuseTranslationLoaderService, i5.DataService, i6.ProjectDeviceService, i7.DeviceMqttService, i8.ToastrService, i9.ContextService], { device: [0, "device"], showAvg30: [1, "showAvg30"], editGraphViews: [2, "editGraphViews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.device; var currVal_1 = true; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DeviceStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "device-band-spectra", [["class", "emma-card auto-width"]], null, null, null, i10.View_DeviceBandSpectra_0, i10.RenderType_DeviceBandSpectra)), i1.ɵdid(1, 245760, null, 0, i11.DeviceBandSpectra, [i5.DataService, i6.ProjectDeviceService, i7.DeviceMqttService, i8.ToastrService, i9.ContextService], { device: [0, "device"], editGraphViews: [1, "editGraphViews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.device; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DeviceStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "device-card-battery", [["class", "emma-card auto-width"]], null, null, null, i12.View_DeviceCardBattery_0, i12.RenderType_DeviceCardBattery)), i1.ɵdid(1, 245760, null, 0, i13.DeviceCardBattery, [i4.FuseTranslationLoaderService, i5.DataService, i6.ProjectDeviceService, i8.ToastrService, i7.DeviceMqttService], { device: [0, "device"], editGraphViews: [1, "editGraphViews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.device; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DeviceStatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "device-card-cellular", [["class", "emma-card auto-width"]], null, null, null, i14.View_DeviceCardCellular_0, i14.RenderType_DeviceCardCellular)), i1.ɵdid(1, 245760, null, 0, i15.DeviceCardCellular, [i4.FuseTranslationLoaderService, i5.DataService, i6.ProjectDeviceService, i8.ToastrService, i7.DeviceMqttService], { device: [0, "device"], editGraphViews: [1, "editGraphViews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.device; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DeviceStatusComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "device-card-free-storage", [["class", "emma-card auto-width"]], null, null, null, i16.View_DeviceCardFreeStorage_0, i16.RenderType_DeviceCardFreeStorage)), i1.ɵdid(1, 245760, null, 0, i17.DeviceCardFreeStorage, [i4.FuseTranslationLoaderService, i5.DataService, i6.ProjectDeviceService, i8.ToastrService, i7.DeviceMqttService], { device: [0, "device"], editGraphViews: [1, "editGraphViews"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.device; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DeviceStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceStatusComponent_2)), i1.ɵdid(3, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceStatusComponent_3)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceStatusComponent_4)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceStatusComponent_5)), i1.ɵdid(9, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSoundPressureLevel(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.hasSpectrum(); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.hasBattery(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.hasCellular(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.hasFreeStorage(); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_DeviceStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-device-status", [], null, null, null, View_DeviceStatusComponent_0, RenderType_DeviceStatusComponent)), i1.ɵdid(1, 114688, null, 0, i19.DeviceStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeviceStatusComponentNgFactory = i1.ɵccf("app-device-status", i19.DeviceStatusComponent, View_DeviceStatusComponent_Host_0, { device: "device" }, {}, []);
export { DeviceStatusComponentNgFactory as DeviceStatusComponentNgFactory };
