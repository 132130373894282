/**
 * Reflects the data describet by the data_type.go file
 * NEEDS TO BE UPDATED AND MAINTAINED IN SYNC WITH THE ORIGINAL GO ENTITY
 */
export enum DataTypeModelKey {
    soundPressureLevel = 'sound_pressure_level',
    battery = 'battery',
    overload = 'overload',
    underrange = 'underrange',
    powerSupplyStatus = 'power_supply_status',
    wifiSignalLevel = 'wifi_signal_level',
	cellullarSignalLevel = 'cellullar_signal_level',
	temperature = 'temperature',
    spectrum13Octave = 'spectrum_13_octave',
    onlineDevices = 'online_devices',
    eventStream = 'event_stream',
    freeStorage = 'free_storage',
}

export interface IDataType<T> {
    soundPressureLevel?: T;
    battery?: T;
    overload?: T;
    underrange?: T;
    powerSupplyStatus?: T;
    wifiSignalLevel?: T;
	cellullarSignalLevel?: T;
	temperature?: T;
	spectrum13Octave?: T;
    freeStorage?: T;
}
