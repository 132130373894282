import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventDataModel } from 'app/models/event.model';
import { ProjectAlarmModel } from 'app/models/projectAlarm.model';
import { Observable } from 'rxjs';
import { AlarmActiveEvents } from "../../models/alarm.model";
import { ApiService } from './api.service';

@Injectable()
export class ProjectAlarmService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'project';
    }

    getAllProject(projectId: string, deviceId?: string): Observable<ProjectAlarmModel[]> {
        const url = `${projectId}/alarm`;
        const params = deviceId ? new HttpParams().set('deviceId', deviceId) : new HttpParams();
        return this.http.get<ProjectAlarmModel[]>(this.getUrl(url), { params });
    }

    create(projectId: string, alarm: ProjectAlarmModel): Observable<ProjectAlarmModel> {
        const url = `${projectId}/alarm`;
        return this.http.post<ProjectAlarmModel>(this.getUrl(url), alarm);
    }

    edit(projectId: string, alarm: ProjectAlarmModel): Observable<ProjectAlarmModel> {
        const url = `${projectId}/alarm`;
        return this.http.put<ProjectAlarmModel>(this.getUrl(url), alarm);
    }

    get(projectId: string, alarmId: string): Observable<ProjectAlarmModel> {
        const url = `${projectId}/alarm/${alarmId}`;
        return this.http.get<ProjectAlarmModel>(this.getUrl(url));
    }

    delete(projectId: string, alarmId: string): Observable<EventDataModel> {
        const url = `${projectId}/alarm/${alarmId}`;
        return this.http.delete<EventDataModel>(this.getUrl(url));
    }

    getActiveEvents(projectId: string, alarmId: string): Observable<AlarmActiveEvents> {
        const url = `${projectId}/alarm/${alarmId}/active-events`;
        return this.http.get<AlarmActiveEvents>(this.getUrl(url));
    }
}
