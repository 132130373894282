<mat-table class="projects-table" #table [dataSource]="projects" [@animateStagger]="{ value: '50' }"
    fusePerfectScrollbar>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
        <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-check" *matCellDef="let project">
            <mat-checkbox [(ngModel)]="checkboxes[project.id]" (ngModelChange)="onSelectedChange(project)"
                (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{ 'PROJECTS.Name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let project" class="name-column">
            <p class="text-truncate">{{ project.name }}</p>
        </mat-cell>
    </ng-container>

    <!-- Archive/Un-archive Column -->
    <ng-container matColumnDef="archive">
        <mat-header-cell *matHeaderCellDef class="archive-column">{{ 'PROJECTS.Archive' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let project" class="archive-column">
            <button mat-button (click)="$event.stopPropagation(); onArchiveToggle(project)">
                <mat-icon>
                    {{ project.archived ? 'unarchive' : 'archive' }}
                </mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row *matRowDef="let project; columns: displayedColumns" class="project row-pointer"
        [ngClass]="{'archived-project': project.archived}" matRipple (click)="onClick(project)">
    </mat-row>
</mat-table>