import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CompanyListComponent } from 'app/components/company-list/company-list.component';
import { ProjectListComponent } from 'app/components/project-list/project-list.component';
import { CompanyModel } from 'app/models/company.model';
import { ProjectModel } from 'app/models/project.model';
import { UserModel } from 'app/models/user.model';
import { UserService } from 'app/services/api/user.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    animations: fuseAnimations,
})
export class UserComponent implements OnInit {
    @ViewChild(ProjectListComponent, { static: false })
    private projectList: ProjectListComponent;
    @ViewChild(CompanyListComponent, { static: false })
    private companyList: CompanyListComponent;

    private user: UserModel;
    public userForm: FormGroup;
    pageType: string;
    savedChangesString: string;

    constructor(
        private translation: FuseTranslationLoaderService,
        private userService: UserService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.pageType = 'new';

        translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
    }

    ngOnInit() {
        this.user = this.route.snapshot.data.data;
        if (this.user.id) {
            this.pageType = 'edit';
        }

        this.userForm = this.formBuilder.group({
            id: [this.user.id],
            name: [this.user.name, [Validators.required]],
            email: [this.user.email, [Validators.required]],
            password: ['', this.pageType === 'new' ? [Validators.required] : []],
            isAdmin: [this.user.isAdmin],
        });

        if (this.pageType === 'edit') {
            this.userForm.valueChanges.pipe(debounce(() => timer(1000))).subscribe(val => {
                this.save();
            });
        }
    }

    ngAfterViewInit() {
        if (this.pageType === 'edit') {
            this.projectList.selectedProjects = this.user.projects;
            this.companyList.selectedCompanies = this.user.companies;
        }
    }

    add() {
        this.userService.create(this.userForm.value).subscribe(
            data => {
                this.router.navigate(['/admin/users']);
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    save() {
        this.userService.edit(this.userForm.value).subscribe(
            data => {
                if (this.pageType === 'edit') {
                    this.toastr.success(this.savedChangesString);
                } else {
                    this.router.navigate(['/admin/users']);
                }
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                // In case of resource removed, return to the list
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/users']);
                }
            },
        );
    }

    cancel() {
        this.router.navigate(['/admin/users']);
    }

    onProjectCheck(project: ProjectModel) {
        const selectedProjects = this.projectList.checkedProjects();
        const projectsId = selectedProjects.map(project => project.id);

        const userEdit = new UserModel();
        userEdit.id = this.user.id;
        userEdit.projects = projectsId;

        this.userService.edit(userEdit).subscribe(
            data => {
                this.user = data;
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    onCompanyCheck(company: CompanyModel) {
        const selectedCompanies = this.companyList.checkedCompanies();
        const companiesId = selectedCompanies.map(company => company.id);

        const userEdit = new UserModel();
        userEdit.id = this.user.id;
        userEdit.companies = companiesId;

        this.userService.edit(userEdit).subscribe(
            data => {
                this.user = data;
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }
}
