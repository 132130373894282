import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { ToastrService } from "ngx-toastr";
import { debounce } from "rxjs/operators";
import { timer } from "rxjs";

import { ProjectListComponent } from "app/components/project-list/project-list.component";
import { CompanyModel } from "app/models/company.model";
import { CompanyService } from "app/services/api/company.service";
import { locale as catalan } from "../../i18n/ca";
import { locale as english } from "../../i18n/en";
import { locale as spanish } from "../../i18n/es";
import { ProjectModel } from "app/models/project.model";

@Component({
    selector: "app-company",
    templateUrl: "./company.component.html",
    styleUrls: ["./company.component.scss"],
    animations: fuseAnimations
})
export class CompanyComponent implements OnInit {
    @ViewChild(ProjectListComponent, { static: false })
    private projectList: ProjectListComponent;

    company: CompanyModel;
    companyForm: FormGroup;
    error: string;
    pageType: string;
    savedChangesString: string;

    constructor(
        private companyService: CompanyService,
        private translation: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.pageType = "new";

        translation.translateService.get("GLOBAL", { value: "SavedChanges" }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
    }

    ngOnInit() {
        this.company = this.route.snapshot.data.data;
        if (this.company.id) {
            this.pageType = "edit";
        }
        this.error = null;

        this.companyForm = this.formBuilder.group({
            id: [this.company.id],
            name: [this.company.name, [Validators.required]],
            street: [this.company.street, [Validators.required]],
            city: [this.company.city, [Validators.required]],
            zipCode: [this.company.zipCode, [Validators.required]],
            country: [this.company.country, [Validators.required]]
        });

        if (this.pageType === "edit") {
            this.companyForm.valueChanges.pipe(debounce(() => timer(1000))).subscribe(val => {
                this.save();
            });
        }
    }

    ngAfterViewInit() {
        if (this.pageType === "edit") {
            this.projectList.selectedProjects = this.company.projects;
        }
    }

    add() {
        this.companyService.create(this.companyForm.value).subscribe(
            data => {
                this.router.navigate(["/admin/companies"]);
            },
            error => {
                console.log(error);
				this.toastr.error("Error: " + error.message);
            }
        );
    }

    save() {
        this.companyService.edit(this.companyForm.value).subscribe(
            data => {
                this.toastr.success(this.savedChangesString);
            },
			error => {
				console.log(error);
                this.toastr.error("Error: " + error.message);
                // In case of resource deleted from another part, return to list
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/companies']);
                }
			}
        );
    }

    cancel() {
        this.router.navigate(["/admin/companies"]);
    }

    onProjectCheck(project: ProjectModel) {
        const selectedProjects = this.projectList.checkedProjects();
        const projectsId = selectedProjects.map(project => project.id);

        const companyEdit = Object.assign({}, this.company);
        companyEdit.projects = projectsId;
        this.companyService.edit(companyEdit).subscribe(
            data => {
                this.company = data;
            },
            error => {
                console.log(error);
				this.toastr.error("Error: " + error.message);
            }
        );
    }
}
