export class TimeModel {
    hour: number;
    minute: number;
}

export class ProjectPeriodModel {
    name: string;
    startTime: TimeModel;
    endTime: TimeModel;
}

export class ProjectModel {
    id: string;
    name: string;
    timezone: string;
    dateformat: string;
    periods: ProjectPeriodModel[];
    archived: boolean;
}

export const DateFormatValues = [
    'yyyy-MM-dd HH:mm:ss',
    'MM-dd-yyyy HH:mm:ss',
    'dd-MM-yyyy HH:mm:ss',
];