<mat-table class="alarms-table" #table [dataSource]="alarms" fusePerfectScrollbar>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
            {{ 'ALARM.Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm">
            <p class="text-truncate">{{ alarm.name }}</p>
        </mat-cell>
    </ng-container>

    <!-- Field Column -->
    <ng-container matColumnDef="field">
        <mat-header-cell *matHeaderCellDef>
            {{ 'ALARM.Field' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm">
            <p class="text-truncate">{{ 'FIELD.' + alarm.field | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Time Window Column -->
    <ng-container matColumnDef="window">
        <mat-header-cell *matHeaderCellDef>
            {{ 'ALARM.TimeWindow' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm">
            <p class="text-truncate">{{ 'ALARM.' + alarm.window | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Threshold Column -->
    <ng-container matColumnDef="threshold">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
            {{ 'ALARM.Threshold' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ 'ALARM.' + alarm.threshold | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Limit Column -->
    <ng-container matColumnDef="limit">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
            {{ 'ALARM.Limit' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ alarm.limit }}</p>
        </mat-cell>
    </ng-container>

    <!-- Samples Column -->
    <ng-container matColumnDef="samples">
        <mat-header-cell *matHeaderCellDef>
            {{ 'ALARM.Samples' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm">
            <p class="text-truncate">{{ alarm.samples }}</p>
        </mat-cell>
    </ng-container>

    <!-- Severity Column -->
    <ng-container matColumnDef="severity">
        <mat-header-cell *matHeaderCellDef>
            <span fxHide fxShow.gt-xs>{{ 'ALARM.Severity' | translate }}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let alarm">
            <app-alarm-type-span [severity]="alarm.severity"></app-alarm-type-span>
            <p class="text-truncate" fxHide fxShow.gt-xs>{{ 'SEVERITY.' + alarm.severity | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Extra buttons column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-check" *matCellDef="let alarm">
            <div fxFlex="row" fxLayoutAlign="end center">
                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                    (click)="$event.stopPropagation()">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>
                <!-- Dotted menu entries -->
                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="onDeleteClick(alarm)">
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'ALARM.Remove' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row *matRowDef="let alarm; columns: displayedColumns" class="alarm" matRipple (click)="onClick(alarm)">
    </mat-row>
</mat-table>
