import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { AgmCoreModule } from "@agm/core";
import { AuthGuard } from "app/guards/auth.guard";

import { MapComponent } from './map.component';

const routes = [
	{
		path: 'map',
		component: MapComponent,
		canActivate: [AuthGuard],
	}
];

@NgModule({
	declarations: [
		MapComponent
	],
	imports: [
		RouterModule.forChild(routes),

		TranslateModule,

		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyC2ez9tqzGdSZEv3Sk5etWSEDCNf1uUHYs'
		}),

		FuseSharedModule,
		FuseWidgetModule,

		LeafletModule,
	],
	exports: [
		MapComponent
	],
})

export class MapModule { }
