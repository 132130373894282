import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectModel } from 'app/models/project.model';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { ApiService } from './api.service';

@Injectable()
export class ProjectService extends ApiService {
    constructor(protected http: HttpClient, private authService: AuthenticationService) {
        super(http);
        this.apiEndpoint = 'project';
    }

    get(id: string): Observable<ProjectModel> {
        return this.http.get<ProjectModel>(this.getUrl(id));
    }

    getAll(project?: object): Observable<ProjectModel[]> {
        return this.http.get<ProjectModel[]>(this.getUrl(''), project);
    }

    create(project: ProjectModel): Observable<ProjectModel> {
        const obs = this.http.post<ProjectModel>(this.getUrl(''), project).pipe(share());
        obs.subscribe(() => {
            // Force reload of user in case of data edit
            this.authService.me().subscribe();
        });
        return obs;
    }

    edit(project: ProjectModel): Observable<ProjectModel> {
        const obs = this.http.put<ProjectModel>(this.getUrl(''), project).pipe(share());
        obs.subscribe(() => {
            // Force reload of user in case of data edit
            this.authService.me().subscribe();
        });
        return obs;
    }

    delete(id: string) {
        const obs = this.http.delete(this.getUrl(String(id)));
        obs.subscribe(() => {
            // Force reload of user in case of data edit
            this.authService.me().subscribe();
        });
        return obs.pipe(share());
    }
}
