export const locale = {
    lang: 'es',
    data: {
        DASHBOARD: {
            Title: 'Control Center',
            SwitchViews: 'Switch View',
            Done: 'Cerrar',
            EventStream: 'Events',
            KioskMode: 'Modo Kiosko',
            KioskModeMessage: 'El modo Kiosko funciona mejor en pantalla completa, quieres ir a pantalla completa?',
        },
        DEVICES: {
            ConnectedDevices: 'Dispositivos conectados',
            Connected: 'Connectados',
            Total: 'Total',
        },
        SIDEBAR: {
            Views: 'Vistas',
            Delete: 'Estas seguro que quieres borrarlo ?',
            AddView: 'Añadir Vista',
        },
    },
};
