export const locale = {
    lang: 'ca',
    data: {
        GLOBAL: {
            SavedChanges: 'Canvis guardats',
            CreatedSuccess: 'Creat amb èxit',
        },
        USERS: {
            Users: 'Usuaris',
            Name: 'Nom',
            Email: 'Email',
            Admin: 'Administrador',
            IsAdmin: 'És administrador?',
            Password: 'Contrasenya',
            ADD: 'AFEGEIX',
            SAVE: 'GUARDA',
            CANCEL: 'CANCEL·LA',
            Create: 'Crea',
            UserName: 'Nom',
            BasicInfo: 'Informació bàsica',
            Projects: 'Projectes',
            Companies: 'Empreses',
        },
        COMPANIES: {
            Companies: 'Empreses',
            Name: 'Nom',
            Street: 'Carrer',
            City: 'Ciutat',
            ZipCode: 'Codi Postal',
            Country: 'País',
            BasicInfo: 'Info Bàsica',
            Company: 'Empresa',
            CompanyName: 'Nom Empresa',
            Address: 'Adreça',
            ADD: 'AFEGIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCEL',
            Projects: 'Projectes',
            Create: 'Crear',
        },
        DEVICES: {
            ActiveProject: 'Projecte Actiu',
            Devices: 'Devices',
            Name: 'Name',
            Manufacturer: 'Manufacturer',
            Model: 'Model',
            SerialNumber: 'Serial Number',
            DeviceType: 'Device Type',
            DataFrequency: 'Data Frequency (Seconds)',
            DataFrequencyDevice: 'Device Data Frequency (Seconds): ',
            DataFrequencyCustom: 'Custom Data Frequency (Seconds): ',
            SimOrImei: 'SIM o IMEI',
            LastCalibrationDate: 'Data de última calibració',
            Protocol: 'Protocol',
            Picture: 'Picture',
            Comments: 'Comments',
            Location: 'Location',
            Active: 'Active',
            Device: 'Device',
            Activate: 'Activate',
            Deactivate: 'Deactivate',
            Remove: 'Remove',
            Create: 'Create',
            DeviceInfo: 'Device Information',
            FolderFTP: 'FTP Folder',
            Address: 'Address',
            Latitude: 'Latitude',
            Longitude: 'Longitude',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            Projects: 'Projectes',
            ConfirmActivate:
                'Aquest dispositiu ja està activat al projecte {{project}} amb el nom {{deviceName}}.  NEstà segur que voleu activar-lo?',
            Image: 'Imatge',
            DropFiles: 'Arrossegueu imatge aquí',
            RemoveImage: 'Elimina la imatge',
        },
        DEVICE: {
            ALARM: {
                AlarmDeleteTitle: 'Alerta',
                AlarmDeleteMessage: 'Aquesta alarma té {{activeEvents}} esdeveniments actius. \nSegur que la vols eliminar?',
            },
        },
        PROJECTS: {
            Projects: 'Projectes',
            Project: 'Projecte',
            BasicInfo: 'Info Bàsica',
            Name: 'Nom',
            Timezone: 'Zona horària',
            Devices: 'Dispositius',
            Alarms: 'Alarmas',
            Create: 'Crea',
            ProjectName: 'Nom del projecte',
            ADD: 'AFEGEIX',
            SAVE: 'GUARDA',
            CANCEL: 'CANCEL·LA',
            ProjectPeriodName: 'Nom',
            ProjectPeriodStart: "Hora d'inici",
            ProjectPeriodEnd: 'Fi del temps',
            ProjectPeriodHours: 'Hores',
            ProjectPeriodMinutes: 'Minuts',
            ProjectPeriodNew: 'Periode Nou',
            ProjectPeriodError:
                "ATENCIÓ: L'hora de finalització és abans de l'hora d'inici. Esteu creant un període que abasta diversos dies",
            Dateformat: 'Format Data',
            Example: 'Exemple',
            Archive: 'Arxivar',
            Unarchive: 'Desarxivar',
        },
    },
};
