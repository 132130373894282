import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserModel } from 'app/models/user.model';
import { UserService } from 'app/services/api/user.service';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<Observable<UserModel>> {
    constructor(private userService: UserService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') == 'new') {
            return of(new UserModel());
        }

        return this.userService.get(route.paramMap.get('id')).pipe(
            mergeMap(user => {
                if (user) {
                    return of(user);
                } else {
                    this.router.navigate(['/']);
                    return EMPTY;
                }
            }),
        );
    }
}
