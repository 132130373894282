import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DeviceModel } from 'app/models/device.model';
import { DeviceService } from 'app/services/api/device.service';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { Observable, forkJoin } from 'rxjs';
import { ManufacturerModel } from 'app/models/manufacturer.model';
import { ManufacturerService } from 'app/services/api/manufacturer.service';

@Component({
    selector: 'app-devices-admin',
    templateUrl: './devicesAdmin.component.html',
    styleUrls: ['./devicesAdmin.component.scss'],
    animations: fuseAnimations,
})
export class DevicesAdminComponent implements OnInit {
    devices: DeviceModel[] = [];
    isLoading: boolean;
    displayedColumns = [
        'checkbox',
        'icon',
        'picture',
        'name',
        'manufacturer',
        'model',
        'serial-number',
        'device-type',
        'active-project',
        'buttons',
    ];
    checkboxes: any;
    selectedDevices: number;
    manufacturerMap: { [id: string]: ManufacturerModel } = {};

    constructor(
        private devicesService: DeviceService,
        private manufacturerService: ManufacturerService,
        private translation: FuseTranslationLoaderService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.isLoading = false;
        this.selectedDevices = 0;
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.selectedDevices = 0;
        this.isLoading = true;

        let loadingObservables: Observable<any>[] = [];
        loadingObservables.push(this.devicesService.getAll());
        loadingObservables.push(this.manufacturerService.getAll());

        forkJoin(loadingObservables).subscribe(
            ([devices, manufacturers]) => {
                this.checkboxes = {};

                devices.map((device) => {
                    this.checkboxes[device.id] = false;
                });
                this.devices = devices;

                manufacturers.map((manufacturer) => {
                    this.manufacturerMap[manufacturer.id] = manufacturer;
                });

                this.isLoading = false;
            },
          (error) => {
                this.isLoading = false;
                console.log(error);
            },
        );
    }

    onSelectedChange(userID): void {
        this.selectedDevices = 0;
        for (let check in this.checkboxes) {
            if (this.checkboxes[check]) {
                this.selectedDevices++;
            }
        }
    }

    deleteDevice(device: DeviceModel) {
        this.devicesService.delete(device.id).subscribe(
            () => {
                this.loadData();
            },
          (error) => {
                console.log(error);
            },
        );
    }

    deleteDevices() {
        let observables: Observable<any>[] = [];
        for (let check in this.checkboxes) {
            if (this.checkboxes[check]) {
                observables.push(this.devicesService.delete(check));
            }
        }

        forkJoin(observables).subscribe(
            () => {
                this.loadData();
            },
          (error) => {
                console.log(error);
            },
        );
    }
}
