import { DataTypeModelKey } from './dataType.model';
import { EventModelSeverity } from './event.model';

/**
 * TO BE MAINTAINED IN SYNC with backend data
 */
export enum AlarmModelTimeWindow {
    // AlarmTimeWindowInstant alarm that it's checked against instant values
    AlarmModelTimeWindowInstant = 'time_window_instant',
    // AlarmTimeWindowRolling5 alarm that it's checked against instant 5 minutes rolling average
    AlarmModelTimeWindowRolling5 = 'time_window_rolling_5',
    // AlarmTimeWindowRolling30 alarm that it's checked against instant 30 minutes rolling average
    AlarmModelTimeWindowRolling30 = 'time_window_rolling_30',
    // AlarmTimeWindowBucket5 alarm that it's checked against instant 5 minutes buckets
    AlarmModelTimeWindowBucket5 = 'time_window_bucket_5',
}

export enum AlarmModelThreshold {
    // AlarmThresholdCrossingOver alarm that it's fire as soon as the value crosses the threadshold with a higher value
    AlarmModelThresholdCrossingOver = 'threshold_cross_over',
    // AlarmThresholdCrossingBelow alarm that it's fire as soon as the value crosses the threadshold with a lower value
    AlarmModelThresholdCrossingBelow = 'threshold_cross_below',
}

export interface AlarmActiveEvents {
    alarmId: string;
    activeEvents: number;
}

export class AlarmModel {
    id: string;
    name: string;
    description: string;
    limit: number;
    samples: number;
    window: AlarmModelTimeWindow;
    threshold: AlarmModelThreshold;
    severity: EventModelSeverity;
    field: DataTypeModelKey;
    data: any;
    notify: boolean;
    needACK: boolean;
    notificationList: string[];
    silent: boolean;
    selected: boolean;

    constructor() {
        this.notify = false;
        this.silent = false;
        this.needACK = false;
    }
}
