import { Injectable } from "@angular/core";
import { LoginModel } from "../../models/login.model";
import { PasswordModel } from "../../models/password.model";
import { HttpClient } from '@angular/common/http';
import { TokenModel } from "app/models/token.model";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthService extends ApiService {
	
	constructor(protected http: HttpClient) {
		super(http);
		this.apiEndpoint = 'auth';
	}

	login(login: LoginModel): Observable<TokenModel> {
		return this.http.post<TokenModel>(this.getUrl('login'), login);
	}

	passwordRecovery(email: string) {
		return this.http.post(this.getUrl('password_recovery'), { email });
	}

	resetPassword(password: PasswordModel) {
		return this.http.post(this.getUrl(`reset_password`), password);
	}

	resetUserPassword(userID: string, password: PasswordModel) {
		return this.http.post(this.getUrl(`reset_password/${userID}`), password);
	}
}
