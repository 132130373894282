import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/services/api/user.service';
import { UserModel } from 'app/models/user.model';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { locale as catalan } from '../../i18n/ca';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: fuseAnimations,
})
export class UsersComponent implements OnInit {
    users: UserModel[] = [];
    isLoading: boolean;
    displayedColumns = ['checkbox', 'name', 'email', 'admin'];
    checkboxes: any;
    selectedUsers: number;

    constructor(
        private usersService: UserService,
        private translation: FuseTranslationLoaderService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.isLoading = false;
        this.selectedUsers = 0;
    }

	ngOnInit() {
		this.loadData();
	}
	
	loadData() {
		this.isLoading = true;
		this.usersService.getAll().subscribe(
			users => {
				this.checkboxes = {};
				users.map(user => {
					this.checkboxes[user.id] = false;
				});
				this.users = users;

				this.isLoading = false;
			},
			error => {
				console.log(error);
				this.isLoading = false;
			},
		);
	}

	deleteUsers() {
		let observables: Observable<any>[] = [];
		for (let check in this.checkboxes) {
			if (this.checkboxes[check]) {
				observables.push(this.usersService.delete(check));
			}
		}

		forkJoin(observables).subscribe(
			() => {
				this.loadData();
			},
			error => {
				console.log(error);
			},
		);

	}

    onSelectedChange(userID): void {
        this.selectedUsers = 0;
        for (let check in this.checkboxes) {
            if (this.checkboxes[check]) {
                this.selectedUsers++;
            }
        }
    }
}
