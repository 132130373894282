<div id="login" fxLayout="column">

	<div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="logo">
				<img src="assets/images/logos/emma-b.png">
			</div>

			<div class="title">{{ 'login.title' | translate }}</div>

			<form name="loginForm" [formGroup]="loginForm" (ngSubmit)="loginForm.valid && signin()">
				<mat-form-field>
					<input matInput placeholder="Email" formControlName="email">
					<mat-error *ngIf="loginForm.get('email').hasError('required')">
						Email is required
					</mat-error>
					<mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
						Please enter a valid email address
					</mat-error>
				</mat-form-field>

				<mat-form-field>
					<input matInput type="password" placeholder="Password" formControlName="password">
					<mat-error>
						Password is required
					</mat-error>
				</mat-form-field>

				<div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
					<mat-checkbox class="remember-me" aria-label="Remember Me" formControlName="remember">
						Remember Me
					</mat-checkbox>

					<a class="forgot-password" [routerLink]="'/forgot-password'">
						Forgot Password?
					</a>
				</div>

				<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
					<mat-error *ngIf="error">
						{{ this.error }}
					</mat-error>
				</div>

				<button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN" [disabled]="loginForm.invalid">
					LOGIN
				</button>
				
			</form>
		</div>
	</div>
</div>