import { Component, OnInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CompanyListComponent } from 'app/components/company-list/company-list.component';
import { CompanyService } from 'app/services/api/company.service';
import { forkJoin, Observable } from 'rxjs';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { Router } from '@angular/router';
import { CompanyModel } from 'app/models/company.model';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
    animations: fuseAnimations,
})
export class CompaniesComponent implements OnInit {
    @ViewChild(CompanyListComponent, { static: true })
    private companyList: CompanyListComponent;
    public editAvailable: boolean;

    constructor(
        private companyService: CompanyService,
        private translation: FuseTranslationLoaderService,
        private router: Router,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {}

    onCompanyClick(project: CompanyModel) {
        this.router.navigate(['/admin/company/' + project.id]);
    }

    onCompanyCheck(project: CompanyModel) {
        this.editAvailable = this.companyList.checkedCompanies().length > 0;
    }

    deleteCompanies() {
        let observables: Observable<any>[] = [];
        this.companyList.checkedCompanies().forEach(company => {
            observables.push(this.companyService.delete(company.id));
        });

        forkJoin(observables).subscribe(
            data => {
                this.companyList.refresh();
            },
            error => {
                console.log(error);
            },
        );
    }
}
