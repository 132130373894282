const ɵ0 = [], ɵ1 = [];
export const chartReport = {
    constructor: "chart",
    options: {
        chart: {
            type: "spline",
            backgroundColor: 'transparent'
        },
        rangeSelector: {
            enabled: false
        },
        plotOptions: {
            line: {
                gapSize: 0,
                gapUnit: "value",
                dataGrouping: {
                    enabled: false,
                }
            },
            series: {
                marker: {
                    enabled: false,
                },
                turboThreshold: 0,
            }
        },
        xAxis: {
            visible: true,
            type: "datetime",
            title: {
                text: null
            },
            tickInterval: 1800 * 1000,
        },
        yAxis: [{
                visible: true,
                floor: 0,
                softMax: 110,
                min: 40,
                tickInterval: 5,
                labels: {
                    'format': '{value} dB',
                },
                title: {
                    enabled: false,
                }
            }],
        legend: {
            enabled: false
        },
        credits: {
            enabled: true,
            text: '@ Axioma Consultors Acústics',
        },
        time: {
            useUTC: false,
        },
        tooltip: {
            enabled: false,
        },
        title: {
            text: '',
        },
        series: [
            {
                data: ɵ0,
                name: "LAeq 30min",
                lineWidth: 1,
                color: '#0848A8',
                states: {
                    hover: {
                        enabled: false,
                    }
                },
                visible: true,
                tooltip: {
                    enabled: false
                },
            },
            {
                data: ɵ1,
                name: "LAeq 5min",
                lineWidth: 1,
                color: '#5695F5',
                visible: true,
                dashStyle: 'ShortDot',
            },
        ],
    },
    update: true,
    visible: false
};
export { ɵ0, ɵ1 };
