import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectListComponent } from 'app/components/project-list/project-list.component';
import { ProjectModel } from 'app/models/project.model';
import { ProjectService } from 'app/services/api/project.service';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'app-projects-admin',
    templateUrl: './projects-admin.component.html',
    styleUrls: ['./projects-admin.component.scss'],
    animations: fuseAnimations,
})
export class ProjectsAdminComponent implements OnInit {
    @ViewChild(ProjectListComponent, { static: true })
    private projectList: ProjectListComponent;
    public editAvailable: boolean;

    constructor(
        private translation: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private router: Router,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {}

    onProjectClick(project: ProjectModel) {
        this.router.navigate(['/admin/project/' + project.id]);
    }

    onProjectCheck(project: ProjectModel) {
        this.editAvailable = this.projectList.checkedProjects().length > 0;
    }

    deleteProjects() {
        let observables: Observable<any>[] = [];
        this.projectList.checkedProjects().forEach(project => {
            observables.push(this.projectService.delete(project.id));
        });

        forkJoin(observables).subscribe(
            data => {
                this.projectList.refresh();
            },
            error => {
                console.log(error);
            },
        );
    }

    archiveProjects() {
        let observables: Observable<any>[] = [];
        this.projectList.checkedProjects().forEach(project => {
            project.archived = true;
            observables.push(this.projectService.edit(project));
        });

        forkJoin(observables).subscribe(
            data => {
                this.projectList.refresh();
            },
            error => {
                console.log(error);
            },
        );
    }

    unarchiveProjects() {
        let observables: Observable<any>[] = [];
        this.projectList.checkedProjects().forEach(project => {
            project.archived = false;
            observables.push(this.projectService.edit(project));
        });

        forkJoin(observables).subscribe(
            data => {
                this.projectList.refresh();
            },
            error => {
                console.log(error);
            },
        );
    }
}
