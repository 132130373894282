import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { UserModel } from 'app/models/user.model';
import { ContextService } from 'app/services/context.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    isKioskMode: boolean = false;
    theme: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /*
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _navigationService: FuseNavigationService,
        private authenticationService: AuthenticationService,
        private _contextService: ContextService,
        @Inject(DOCUMENT) private _document: any,

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /*
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.fuseConfig = config;
            this._updateTheme();
        });
        this._contextService.onKioskModeChange.subscribe(isKioskMode => {
            this.isKioskMode = isKioskMode;
        });

        // Set current navigation appropriately
        this.updateNavigation(this.authenticationService.currentUser);

        // Subscribe to changes in the user and update the current navigation layout appropriately
        this.authenticationService.onCurrentUserChange.subscribe(currentUser => {
            this.updateNavigation(currentUser);
        });
    }

    /*
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private updateNavigation(user: UserModel) {
        if (user) {
            const navigation = user.isAdmin ? 'admin' : 'user';
            this._navigationService.setCurrentNavigation(navigation);
        }
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.fuseConfig.colorTheme);
    }
}
