import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { CompanyService } from 'app/services/api/company.service';
import { CompanyModel } from 'app/models/company.model';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class CompanyResolver implements Resolve<Observable<CompanyModel>> {
	constructor(
		private companies: CompanyService,
		private router: Router,
	) { }

	resolve(route: ActivatedRouteSnapshot) {
		if (route.paramMap.get('id') == "new") {
			return of(new CompanyModel());
		}

		return this.companies.get(route.paramMap.get('id')).pipe(mergeMap(company => {
            if (company) { 
                return of(company) 
            } else {
                this.router.navigate(['/'])
                return EMPTY;
            }
        }));
	}
}