import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class ApiService {

	protected apiEndpoint = 'auth';
	protected baseUrl: string;

	constructor(
		protected http: HttpClient,
	) {
		this.baseUrl = env.http.apiUrl;
	}

	getUrl(endpoint: string, apiEndpoint?: string) {
		return `${this.baseUrl}/${apiEndpoint ? apiEndpoint : this.apiEndpoint}/${endpoint}`;
	}
}
