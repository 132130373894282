import { Component, Input, OnInit } from '@angular/core';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';

@Component({
    selector: 'app-device-status',
    templateUrl: './device-status.component.html',
    styleUrls: ['./device-status.component.scss'],
})
export class DeviceStatusComponent implements OnInit {
    @Input() device: ProjectDeviceModel;

    constructor() { }

    ngOnInit() { }

    public hasSoundPressureLevel() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.soundPressureLevel) !== -1 : false;
    }

    public hasBattery() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.battery) !== -1 : false;
    }

    public hasSpectrum() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.spectrum13Octave) !== -1 : false;
    }

    public hasTemperature() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.temperature) !== -1 : false;
    }

    public hasCellular() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.cellullarSignalLevel) !== -1 : false;
    }

    public hasFreeStorage() {
        return (this.device.fields) ? this.device.fields.indexOf(DataTypeModelKey.freeStorage) !== -1 : false;
    }
}
