import { OnInit } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { chartBandSpectra } from 'app/chart-config/chart-band-spectra';
import { chartBatteryLevel } from 'app/chart-config/chart-battery-level';
import { chartBatteryLevel5Minutes } from 'app/chart-config/chart-battery-level-5-minutes';
import { chartPowerSupplyStatus } from 'app/chart-config/chart-power-supply-status';
import { chartPowerSupplyStatus5Minutes } from 'app/chart-config/chart-power-supply-status-5-minutes';
import { chartSoundPressure } from 'app/chart-config/chart-sound-pressure';
import { chartSoundPressure5Minutes } from 'app/chart-config/chart-sound-pressure-5-minutes';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceDataType } from 'app/models/deviceData.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DataService } from 'app/services/api/data.service';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { Router } from '@angular/router';
import { AlarmService } from 'app/services/api/alarm.service';
import { AlarmModelTimeWindow, AlarmModelThreshold } from 'app/models/alarm.model';
import { EventModelSeverity } from 'app/models/event.model';
import { ProjectService } from 'app/services/api/project.service';
import { formatDate } from '@angular/common';
import { EmmaDateAdapter } from 'app/shared/date.adapter';
import { DeviceMqttService } from 'app/services/api/device.mqtt.service';
import { ProjectAlarmService } from 'app/services/api/projectAlarm.service';
import { ContextService } from 'app/services/context.service';
import { chartCellularLevel } from 'app/chart-config/chart-cellular-level';
import { chartCellularLevel5Minutes } from 'app/chart-config/chart-cellular-level-5-minutes';
import { chartFreeStorage } from 'app/chart-config/chart-free-storage';
import { chartFreeStorage5Minutes } from 'app/chart-config/chart-free-storage-5-minutes';
import { chartBandSpectraNoAvg } from 'app/chart-config/chart-band-spectra-noavg';
const ɵ0 = {
    parse: {
        dateInput: 'emma',
    },
    display: {
        dateInput: 'emma',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export class DeviceDataComponent {
    constructor(dataService, alarmService, projectDeviceService, projectService, deviceMqtt, projectAlarmService, contextService, toastr, router, locale) {
        this.dataService = dataService;
        this.alarmService = alarmService;
        this.projectDeviceService = projectDeviceService;
        this.projectService = projectService;
        this.deviceMqtt = deviceMqtt;
        this.projectAlarmService = projectAlarmService;
        this.contextService = contextService;
        this.toastr = toastr;
        this.router = router;
        this.locale = locale;
        this.startDate = null;
        this.startTime = "00:00";
        this.endDate = null;
        this.endTime = "23:59";
        this.endDateMin = null;
        this.endDateMax = null;
        this.groupingData = '5minutes';
        this.dateRange = 'lasthour';
        this.liveData = true;
        this.forceUpdate = true;
        this.showL10 = true;
        this.showL90 = true;
        this.enablePercentiles = true;
        this.enableLiveData = true;
        this.enableInstantValues = true;
        this.loadingData = false;
        this.exporting = false;
        this.startDateExport = null;
        this.startTimeExport = "00:00";
        this.endDateExport = null;
        this.endTimeExport = "23:59";
        this.groupingDataExport = 'no';
        //MQTT Subscription
        this.subscription = null;
        this.subscriptionBucket = null;
        this.Highcharts = Highcharts; // required
        this.chartsConfig = [
            Object.assign({}, chartSoundPressure),
            Object.assign({}, chartSoundPressure5Minutes),
            Object.assign({}, chartBatteryLevel),
            Object.assign({}, chartBatteryLevel5Minutes),
            Object.assign({}, chartPowerSupplyStatus),
            Object.assign({}, chartPowerSupplyStatus5Minutes),
            Object.assign({}, chartBandSpectra),
            Object.assign({}, chartCellularLevel),
            Object.assign({}, chartCellularLevel5Minutes),
            Object.assign({}, chartFreeStorage),
            Object.assign({}, chartFreeStorage5Minutes),
            Object.assign({}, chartBandSpectraNoAvg),
        ];
        this.chartIndex = 0;
    }
    ngOnInit() {
        this.dataField = DataTypeModelKey.soundPressureLevel;
        this.dataFieldExport = DataTypeModelKey.soundPressureLevel;
        // Adjust device frequency gaps
        this.adjustPlotGaps();
        this.loadData();
        this.loadAlarms();
        this.startDate = moment();
        this.updateEndDate(null);
        this.startDateExport = moment().startOf('hour');
        this.endDateExport = moment().startOf('hour');
        this.projectService.get(this.device.project).subscribe(val => {
            this.project = val;
            let format = this.project.dateformat;
            if (format) {
                format = format.toUpperCase();
                let formats = format.split(" ");
                EmmaDateAdapter.dateFormat = formats[0];
            }
            // Force date refresh to use the new format
            this.startDateExport = moment(this.startDateExport);
            this.endDateExport = moment(this.endDateExport);
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionBucket) {
            this.subscriptionBucket.unsubscribe();
        }
    }
    updateEndDate(event) {
        this.endDateMin = this.startDate;
        this.endDateMax = moment(this.startDate).add(7, 'days');
        if (this.endDateMax.isAfter(moment())) {
            this.endDateMax = moment();
        }
        if (moment(this.endDate).isAfter(this.endDateMax)) {
            this.endDate = this.endDateMax;
        }
    }
    loadAlarms() {
        this.alarmService.getAllDevice(this.device.id).subscribe(alarms => {
            this.alarms = alarms;
        }, error => {
            console.log(error);
            this.toastr.error('Error: ' + error.message);
        });
        const currentProject = this.contextService.currentProject;
        this.projectAlarmService.getAllProject(currentProject, this.device.id).subscribe((alarms) => {
            if (this.alarms == null)
                this.alarms = [];
            if (alarms != null)
                this.alarms = this.alarms.concat(alarms);
        }, (error) => {
            console.log(error);
            this.toastr.error('Error: ' + error.message);
        });
    }
    reloadData() {
        this.forceUpdate = true;
        this.loadData();
    }
    reloadGraph() {
        this.forceUpdate = true;
        this.createSensorData();
    }
    subscribeToTopic() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
        if (this.subscriptionBucket) {
            this.subscriptionBucket.unsubscribe();
            this.subscriptionBucket = null;
        }
        let group = DeviceDataType.instant;
        if (this.groupingData == "5minutes" && this.dataField != DataTypeModelKey.spectrum13Octave && this.dataField != DataTypeModelKey.battery && this.dataField != DataTypeModelKey.freeStorage) {
            group = DeviceDataType.bucket;
        }
        //TODO: Alarms MQTT
        //Subscribe to device mqtt messages
        this.subscription = this.deviceMqtt.deviceTopic(this.device.id, group, this.dataField)
            .subscribe((data) => {
            let item = JSON.parse(data.payload.toString());
            if (this.dataField == DataTypeModelKey.spectrum13Octave) {
                this.device.lastData.spectrum13Octave = item;
                this.createSensorData();
                return;
            }
            //Process message based on the aggregation
            if (this.groupingData == "5minutes") {
                if (moment(this.deviceData[this.deviceData.length - 1].date).isSame(moment(item.date))) {
                    this.deviceData[this.deviceData.length - 1] = item;
                }
                else {
                    this.deviceData.push(item);
                }
                //Let's check the total items to keep the date range as it should
                let max = 0;
                switch (this.dateRange) {
                    case 'last15minutes':
                        max = 4;
                        break;
                    case 'lasthour':
                        max = 13;
                        break;
                    case 'last12hours':
                        max = 12 * 12 + 1;
                        break;
                    case 'last24hours':
                        max = 24 * 12 + 1;
                        break;
                }
                if (this.deviceData.length > max) {
                    this.deviceData.shift();
                }
            }
            else {
                //Add item to last bucket (as buckets are not used for the graph, it doesn't matter it's not the right bucket)
                this.deviceData[this.deviceData.length - 1].data.push(item);
                //Let's check the total items to keep the date range as it should
                let total = this.chartsConfig[this.chartIndex].options.series[0].data.length;
                let max = 0;
                switch (this.dateRange) {
                    case 'last15minutes':
                        max = 15 * (60 / this.device.dataFrequency) + 1;
                        break;
                    case 'lasthour':
                        max = 60 * (60 / this.device.dataFrequency) + 1;
                        break;
                }
                //let's remove an item if needed
                if (total + 1 > max) {
                    this.deviceData[0].data.shift();
                    if (this.deviceData[0].data.length <= 0) {
                        this.deviceData.shift();
                    }
                }
            }
            //Update graph
            this.createSensorData();
        });
        if (this.dataField == DataTypeModelKey.spectrum13Octave) {
            this.subscription = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.bucket, this.dataField)
                .subscribe((data) => {
                let item = JSON.parse(data.payload.toString());
                //On the initial load we get the rolling average, but on the mqtt we ask for the bucket avg as this is coming from the device and is more accurate
                this.device.dataAggregation.spectrum13Octave = item.aggregation;
                this.createSensorData();
            });
        }
    }
    loadData() {
        if (this.loadingData) {
            return;
        }
        this.loadingData = true;
        switch (this.dateRange) {
            case 'last15minutes':
                this.startDate = moment().subtract(15, 'minutes');
                this.endDate = moment();
                this.enableLiveData = true;
                this.enableInstantValues = true;
                break;
            case 'lasthour':
                this.startDate = moment().subtract(1, 'hours');
                this.endDate = moment();
                this.enableLiveData = true;
                this.enableInstantValues = true;
                break;
            case 'last12hours':
                this.startDate = moment().subtract(12, 'hours');
                this.endDate = moment();
                this.enableLiveData = false;
                this.enableInstantValues = false;
                if (this.groupingData == "no") {
                    this.groupingData = "5minutes";
                }
                break;
            case 'last24hours':
                this.startDate = moment().subtract(24, 'hours');
                this.endDate = moment();
                this.enableLiveData = false;
                this.enableInstantValues = false;
                if (this.groupingData == "no") {
                    this.groupingData = "5minutes";
                }
                break;
            case 'custom':
                let start = this.startTime.split(":");
                if (start.length != 2) {
                    this.loadingData = false;
                    return;
                }
                if (!this.startDate) {
                    this.startDate = moment();
                }
                this.startDate.set('hour', start[0]);
                this.startDate.set('minute', start[1]);
                let end = this.endTime.split(":");
                if (end.length != 2) {
                    this.loadingData = false;
                    return;
                }
                if (!this.endDate) {
                    this.endDate = moment();
                }
                this.endDate.set('hour', end[0]);
                this.endDate.set('minute', end[1]);
                this.enableLiveData = false;
                this.enableInstantValues = true;
                if (this.groupingData == "no" && this.endDate.diff(this.startDate, 'hours') > 1) {
                    this.groupingData = "5minutes";
                }
                if (this.endDate.diff(this.startDate, 'hours') > 1) {
                    this.enableInstantValues = false;
                }
                break;
        }
        if (this.forceUpdate == false && (!this.enableLiveData || !this.liveData)) {
            this.loadingData = false;
            return;
        }
        if (!this.startDate || !this.endDate) {
            this.loadingData = false;
            return;
        }
        //Spectrum is a special case of graph, we only need the last item, so we query 10 seconds and we get the last one
        if (this.dataField == DataTypeModelKey.spectrum13Octave) {
            this.startDate = moment().subtract(10, 'seconds');
            this.endDate = moment();
            // Fetch bucket of data of last hour (12 buckets)
            this.projectDeviceService.get(this.device.id).subscribe((data) => {
                this.device.lastData = data.lastData;
                this.device.dataAggregation30 = data.dataAggregation30;
                this.device.dataAggregation = data.dataAggregation;
                this.createSensorData();
                this.loadingData = false;
                //Subscribe to MQTT message to update the graph
                this.subscribeToTopic();
            }, error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                this.loadingData = false;
            });
            return;
        }
        this.dataService
            .getDeviceDataRange(this.device.id, [this.dataField], this.startDate, this.endDate, this.groupData)
            .subscribe(data => {
            this.deviceData = data[this.dataField];
            // From API data are coming from the most recent to the latest, reverting the order.
            this.deviceData.sort((d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime());
            //Draw the graph
            this.createSensorData();
            this.loadingData = false;
            //Subscribe to MQTT message to update the graph
            this.subscribeToTopic();
        }, error => {
            console.log(error);
            this.toastr.error('Error: ' + error.message);
            if (error.error.statusCode === 404) {
                this.router.navigate(['/devices']);
            }
            this.loadingData = false;
        });
    }
    createSensorData() {
        let activeAlarms;
        if (!this.deviceData) {
            return;
        }
        if (!this.forceUpdate) {
            if (!this.liveData) {
                return;
            }
        }
        this.forceUpdate = false;
        this.enablePercentiles = false;
        //Set which graph to show
        switch (this.dataField) {
            case DataTypeModelKey.soundPressureLevel:
                this.chartIndex = 0;
                if (this.groupData)
                    this.enablePercentiles = true;
                break;
            case DataTypeModelKey.battery:
                this.chartIndex = 2;
                break;
            case DataTypeModelKey.powerSupplyStatus:
                this.chartIndex = 4;
                break;
            case DataTypeModelKey.spectrum13Octave:
                this.chartIndex = 6;
                if (this.device.dataAggregation.spectrum13Octave.average == null)
                    this.chartIndex = 11;
                else if (!Array.isArray(this.device.dataAggregation.spectrum13Octave.average) || this.device.dataAggregation.spectrum13Octave.average.length == 0)
                    this.chartIndex = 11;
                break;
            case DataTypeModelKey.cellullarSignalLevel:
                this.chartIndex = 7;
                break;
            case DataTypeModelKey.freeStorage:
                this.chartIndex = 9;
                break;
        }
        if (this.alarms) {
            activeAlarms = this.alarms.filter((element, index, array) => {
                if (this.groupingData == '5minutes') {
                    return element.field == this.dataField && element.window == AlarmModelTimeWindow.AlarmModelTimeWindowBucket5;
                }
                else if (this.groupingData == 'no') {
                    return element.field == this.dataField && element.window == AlarmModelTimeWindow.AlarmModelTimeWindowInstant;
                }
            });
        }
        if (this.groupData && this.dataField != DataTypeModelKey.spectrum13Octave)
            this.chartIndex++;
        //Show and hide the corresponding graphs
        this.chartsConfig.forEach((element, key) => {
            if (key == this.chartIndex) {
                this.chartsConfig[key].visible = true;
            }
            else {
                this.chartsConfig[key].visible = false;
            }
        });
        //Clean data array
        this.chartsConfig[this.chartIndex].options.series.forEach(element => {
            element.data = [];
        });
        if (this.dataField == DataTypeModelKey.spectrum13Octave) {
            if (this.device.dataAggregation.spectrum13Octave.average == null || Array.isArray(this.device.dataAggregation.spectrum13Octave.average) == false) {
                //Process data to set it ready for drawing the graph
                this.device.lastData.spectrum13Octave.value.forEach((data, i) => {
                    data = Math.round(data * 10) / 10;
                    if (i <= 30) {
                        this.chartsConfig[this.chartIndex].options.series[0].data.push(data);
                    }
                });
            }
            else {
                //Process data to set it ready for drawing the graph
                this.device.dataAggregation.spectrum13Octave.average.forEach((data, i) => {
                    data = Math.round(data * 10) / 10;
                    if (i > 30) {
                        this.chartsConfig[this.chartIndex].options.series[0].data.push({
                            y: data,
                            color: '#0C65E8',
                        });
                    }
                    else {
                        this.chartsConfig[this.chartIndex].options.series[0].data.push(data);
                    }
                });
                this.device.lastData.spectrum13Octave.value.forEach((data, i) => {
                    data = Math.round(data * 10) / 10;
                    if (i > 30) {
                        this.chartsConfig[this.chartIndex].options.series[1].data.push({
                            y: data,
                            color: '#0C65E877',
                        });
                    }
                    else {
                        this.chartsConfig[this.chartIndex].options.series[1].data.push(data);
                    }
                });
            }
        }
        else {
            //Process data to set it ready for drawing the graph
            this.deviceData.forEach(data => {
                // When grouping data use the average
                if (this.groupData) {
                    this.chartsConfig[this.chartIndex].options.series[0].data.push([
                        new Date(data.date).getTime(),
                        Math.round(data.aggregation.average * 10) / 10,
                    ]);
                    if (this.dataField == DataTypeModelKey.soundPressureLevel) {
                        this.chartsConfig[this.chartIndex].options.series[1].data.push([
                            new Date(data.date).getTime(),
                            Math.round(data.aggregation.percentile90 * 10) / 10,
                        ]);
                        this.chartsConfig[this.chartIndex].options.series[2].data.push([
                            new Date(data.date).getTime(),
                            Math.round(data.aggregation.percentile10 * 10) / 10,
                        ]);
                        this.chartsConfig[this.chartIndex].options.series[1].visible = this.showL10;
                        this.chartsConfig[this.chartIndex].options.series[2].visible = this.showL90;
                    }
                }
                else {
                    const pointData = data.data ? data.data : [];
                    pointData.sort((d1, d2) => new Date(d1.timestamp).getTime() - new Date(d2.timestamp).getTime());
                    pointData.forEach(pointData => {
                        this.chartsConfig[this.chartIndex].options.series[0].data.push([
                            new Date(pointData.timestamp).getTime(),
                            Math.round(pointData.value * 10) / 10,
                        ]);
                    });
                }
            });
            this.chartsConfig[this.chartIndex].options.yAxis[0].plotLines.length = 0;
            if (activeAlarms) {
                let plotLines = [];
                activeAlarms.forEach(alarm => {
                    plotLines.push({
                        value: alarm.limit,
                        color: (alarm.severity == EventModelSeverity.EventModelSeverityAlarm) ? 'red' : 'orange',
                        dashStyle: 'shortdash',
                        width: 2,
                        zIndex: 10,
                        label: {
                            text: alarm.name,
                            style: {
                                color: (alarm.severity == EventModelSeverity.EventModelSeverityAlarm) ? 'red' : 'orange',
                            },
                            verticalAlign: 'bottom',
                            y: (alarm.threshold == AlarmModelThreshold.AlarmModelThresholdCrossingBelow) ? 16 : -5,
                        }
                    });
                });
                this.chartsConfig[this.chartIndex].options.yAxis[0].plotLines = plotLines;
            }
        }
        //Force graph update
        this.chartsConfig[this.chartIndex].update = true;
    }
    get groupData() {
        return this.groupingData == '5minutes';
    }
    downloadData() {
        let result = [];
        let headers = [];
        const graphData = this.chartsConfig[this.chartIndex].options.series[0].data;
        if (this.groupData) {
            const l10Data = this.chartsConfig[this.chartIndex].options.series[1].data;
            const l90Data = this.chartsConfig[this.chartIndex].options.series[2].data;
            graphData.forEach((element, idx) => {
                let time = formatDate(new Date(element[0]), this.project.dateformat, this.locale, this.project.timezone);
                result.push({ time: time, value: element[1], l10: l10Data[idx][1], l90: l90Data[idx][1] });
            });
            headers = ['timestamp', 'value', 'l10', 'l90'];
        }
        else {
            graphData.forEach(element => {
                let time = formatDate(new Date(element[0]), this.project.dateformat, this.locale, this.project.timezone);
                result.push({ time: time, value: element[1] });
            });
            headers = ['timestamp', 'value'];
        }
        const csvOptions = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            headers: headers,
        };
        new AngularCsv(result, 'GraphData-' + new Date().getTime(), csvOptions);
    }
    downloadDataExport() {
        if (this.exporting) {
            return;
        }
        this.exporting = true;
        let groupData = this.groupingDataExport == '5minutes';
        let self = this;
        let startTime = moment(this.startTimeExport, "HH:mm");
        let startDate = this.startDateExport.startOf('hour');
        startDate.hour(startTime.get('hour'));
        startDate.minute(startTime.get('minutes'));
        let endTime = moment(this.endTimeExport, "HH:mm");
        let endDate = this.endDateExport.startOf('hour');
        endDate.hour(endTime.get('hour'));
        endDate.minute(endTime.get('minutes'));
        this.dataService.getDeviceDataRange(this.device.id, [this.dataFieldExport], startDate, endDate, groupData).subscribe(data => {
            let deviceDataExport = data[this.dataFieldExport];
            let exportData = [];
            let headers = [];
            // From API data are coming from the most recent to the latest, reverting the order.
            deviceDataExport.sort((d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime());
            deviceDataExport.forEach(data => {
                // When grouping data use the average
                if (groupData) {
                    exportData.push({ time: formatDate(data.date, this.project.dateformat, this.locale, this.project.timezone), value: data.aggregation.average, l10: data.aggregation.percentile90, l90: data.aggregation.percentile10 });
                    headers = ['timestamp', 'value', 'l10', 'l90'];
                }
                else {
                    const pointData = data.data ? data.data : [];
                    pointData.sort((d1, d2) => new Date(d1.timestamp).getTime() - new Date(d2.timestamp).getTime());
                    pointData.forEach(pointData => {
                        let d = moment(pointData.timestamp);
                        if (d.isSameOrBefore(endDate) && d.isSameOrAfter(startDate)) {
                            if (this.dataFieldExport == DataTypeModelKey.spectrum13Octave) {
                                exportData.push({ time: formatDate(pointData.timestamp, this.project.dateformat, this.locale, this.project.timezone), value: pointData.value });
                            }
                            else {
                                exportData.push({ time: formatDate(pointData.timestamp, this.project.dateformat, this.locale, this.project.timezone), value: pointData.value });
                            }
                        }
                    });
                    if (this.dataFieldExport == DataTypeModelKey.spectrum13Octave) {
                        headers = ['timestamp', '20 Hz', '25 Hz', '31.5 Hz', '40 Hz', '50 Hz', '63 Hz', '80 Hz', '100 Hz', '125 Hz', '160 Hz', '200 Hz', '250 Hz',
                            '315 Hz', '400 Hz', '500 Hz', '630 Hz', '800 Hz', '1 kHz', '1.25 kHz', '1.6 kHz', '2 kHz', '2.5 kHz', '3.150 kHz', '4 kHz', '5 kHz',
                            '6.3 kHz', '8 kHz', '10 kHz', '12.5 kHz', '16 kHz', '20 kHz', 'A', 'C', 'Z'
                        ];
                    }
                    else {
                        headers = ['timestamp', 'value'];
                    }
                }
            });
            const csvOptions = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalseparator: '.',
                showLabels: true,
                headers: headers,
            };
            new AngularCsv(exportData, 'GraphData-' + new Date().getTime(), csvOptions);
            this.exporting = false;
        }, error => {
            console.log(error);
            this.toastr.error('Error: ' + error.message);
            if (error.error.statusCode === 404) {
                this.router.navigate(['/devices']);
            }
            this.exporting = false;
        });
    }
    adjustPlotGaps() {
        //We set the gap to data frequency for instant charts (plus 25% to avoid small date deviations)
        this.chartsConfig[0].options.plotOptions.line.gapSize =
            this.device.dataFrequency * 1000 * 2;
        this.chartsConfig[2].options.plotOptions.line.gapSize =
            this.device.dataFrequency * 1000 * 2;
        this.chartsConfig[4].options.plotOptions.line.gapSize =
            this.device.dataFrequency * 1000 * 2;
    }
}
export { ɵ0 };
