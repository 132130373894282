import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventDataModel } from 'app/models/event.model';
import { fuseAnimations } from '@fuse/animations';
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss'],
    animations: fuseAnimations,
})
export class EventListComponent implements OnInit {
    @Input()
    events: EventDataModel[] = [];
    @Input()
    showDevice: boolean = true;
    @Input()
    allowsEdit: boolean = true;

    @Output() onEventClick = new EventEmitter<EventDataModel>();
    @Output() onEventDelete = new EventEmitter<EventDataModel>();
    @Output() onEventACK = new EventEmitter<EventDataModel>();

    ackEnabled = EventDataModel.ackEnabled;

    get displayedColumns() {

        let columns = [];

        if (this.events && this.events.length) {
            for (let event of this.events) {
                if (event.ackUser) {
                    columns = columns.concat(['ackUser']);
                    break;
                }
            }
        }

        columns = columns.concat(['severity', 'type', 'alarm']);

        if (this.showDevice) {
            columns.push('device');
        }

        if (this.events && this.events.length) {
            for (let event of this.events) {
                if (this.ackEnabled(event)) {
                    columns = ['ACK'].concat(columns);
                    break;
                }
            }
        }

        columns = columns.concat(['startTime', 'endTime', 'description']);
        if (this.allowsEdit) {
            columns.push('buttons');
        }
        return columns;
    }

    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {}

    public eventClick(event: EventDataModel) {
        this.onEventClick.emit(Object.assign({}, event));
    }

    public deleteEvent(event: EventDataModel) {
        this.onEventDelete.emit(Object.assign({}, event));
    }

    public ackEvent(event: EventDataModel) {
        this.onEventACK.emit(Object.assign({}, event));
    }
}
