import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class ProjectDeviceService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'project-device';
    }

    getAll(projectId: string): Observable<ProjectDeviceModel[]> {
        const url = `${projectId}/project-device`;
        const apiEndpoint = 'project';
        return this.http
            .get<ProjectDeviceModel[]>(this.getUrl(url, apiEndpoint))
            .pipe(map((array) => array.map((pD) => Object.assign(new ProjectDeviceModel(), pD))));
    }

    create(projectId: string, projectDevice: ProjectDeviceModel) {
        const url = `${projectId}/project-device`;
        const apiEndpoint = 'project';
        return this.http
            .post(this.getUrl(url, apiEndpoint), projectDevice)
            .pipe(map((t) => Object.assign(new ProjectDeviceModel(), t)));
    }

    edit(projectId: string, projectDevice: ProjectDeviceModel) {
        const url = `${projectId}/project-device`;
        const apiEndpoint = 'project';
        delete projectDevice.project;
        delete projectDevice.baseDevice;
        return this.http
            .put(this.getUrl(url, apiEndpoint), projectDevice)
            .pipe(map((t) => Object.assign(new ProjectDeviceModel(), t)));
    }

    get(projectDeviceId: string): Observable<ProjectDeviceModel> {
        const url = `${projectDeviceId}`;
        return this.http.get<ProjectDeviceModel>(this.getUrl(url)).pipe(
            map((projectDevice) => {
                return Object.assign(new ProjectDeviceModel(), projectDevice);
            }),
        );
    }

    delete(projectDeviceId: string) {
        const url = `${projectDeviceId}`;
        return this.http.delete(this.getUrl(url));
    }

    activate(projectDeviceId: string): Observable<any> {
        const url = `${projectDeviceId}/activate`;
        return this.http.post(this.getUrl(url), {});
    }

    deactivate(projectDeviceId: string): Observable<any> {
        const url = `${projectDeviceId}/deactivate`;
        return this.http.post(this.getUrl(url), {});
    }

    addImage(projectDeviceId: string, image: any) {
        const url = `${projectDeviceId}/image`;
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        return this.http.post(this.getUrl(url), formData);
    }

    removeImage(projectDeviceId: string) {
        const url = `${projectDeviceId}/image`;
        return this.http.delete(this.getUrl(url));
    }

    addFile(projectDeviceId: string, file: any) {
        const url = `${projectDeviceId}/file`;
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(this.getUrl(url), formData);
    }

}
