import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations/index';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { ProjectViewModel } from 'app/models/projectView.model';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ProjectViewService } from 'app/services/api/projectView.service';
import { ContextService } from 'app/services/context.service';
import { ProjectViewPreferenceService } from 'app/services/projectViewPreference.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { locale as catalan } from './i18n/ca';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { GridsterConfig, GridsterItem, GridType, DisplayGrid } from 'angular-gridster2';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

interface IDashboardGraph {
    cols: number;
    rows: number;
    y: number;
    x: number;
    device: ProjectDeviceModel;
    dataType: DataTypeModelKey;
    data: any;
}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DashboardComponent implements OnInit {
    DataTypeModelKey = DataTypeModelKey;
    widget5: any;
    sensor01: any;
    widgets: any;
    devices: ProjectDeviceModel[] = [];
    projectViews: ProjectViewModel[] = [];

    updatingView: boolean = false;

    options: GridsterConfig;
    graphs: IDashboardGraph[];
    editing: boolean = false;

    showConnected: boolean = false;
    showEventStream: boolean = false;
    isKioskMode: boolean = false;
    mouseTimeout: NodeJS.Timeout;

    ngOnInit() {
        this.loadData();


        // In case of a force data refresh reload dashboard data
        this.contextService.onForceDataRefresh.subscribe(() => {
            this.loadData();
        });

        // In case preferred project view is changed, simply redraw the view
        this.projectViewPreferenceService.onPreferredProjectViewChange.subscribe(() =>
            this.loadData(),
        );

        // If project view have changed, reload data
        this.projectViewService.onEditProjectView.subscribe(() => this.loadData());

        this.options = {
            itemChangeCallback: this.itemChange.bind(this),
            itemResizeCallback: this.itemResize.bind(this),
            gridType: GridType.VerticalFixed,
            draggable: {
                enabled: this.editing,
            },
            resizable: {
                enabled: this.editing,
            },
            minCols: 4,
            maxCols: 4,
            minRows: 2,
            maxRows: 100,
            maxItemCols: 4,
            minItemCols: 1,
            maxItemRows: 2,
            minItemRows: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedRowHeight: 370,
            keepFixedHeightInMobile: true,
            displayGrid: DisplayGrid.None,
            pushItems: true,
            pushResizeItems: true,
        };

        this.graphs = [
        ];

        // In case of a kiosk mode change, update the view
        this.isKioskMode = this.contextService.isKioskModeEnabled;
        this.contextService.onKioskModeChange.subscribe(isKioskMode => {
            this.isKioskMode = isKioskMode;
            if (this.isKioskMode) {
                this.showConnected = false;
                this.showEventStream = false;

                this.options.minRows = 1;
                this.options.maxRows = 1;
                this.options.pushItems = false;
                this.options.pushResizeItems = false;
                this.options.gridType = GridType.Fit;
            } else {
                this.options.minRows = 2;
                this.options.maxRows = 100;
                this.options.pushItems = true;
                this.options.pushResizeItems = true;
                this.options.gridType = GridType.VerticalFixed;
            }
            if (this.options && this.options.api)
                this.options.api.optionsChanged();
        });

    }

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private projectDeviceService: ProjectDeviceService,
        private projectViewPreferenceService: ProjectViewPreferenceService,
        private projectViewService: ProjectViewService,
        private contextService: ContextService,
        private toastr: ToastrService,
        private config: FuseConfigService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);

        this.sensor01 = {
            scheme: {
                domain: ['#00ABFF'],
            },
            scale: 55,
            barPadding: 4,
            roundEdges: false,
            data: [
                {
                    name: '10:00',
                    value: 68,
                },
                {
                    name: '10:05',
                    value: 68.1,
                },
                {
                    name: '10:10',
                    value: 68,
                },
                {
                    name: '10:15',
                    value: 69.1,
                },
                {
                    name: '10:20',
                    value: 71.2,
                },
                {
                    name: '10:25',
                    value: 70,
                },
                {
                    name: '10:30',
                    value: 71,
                },
                {
                    name: '10:35',
                    value: 66,
                },
                {
                    name: '10:40',
                    value: 65,
                },
                {
                    name: '10:45',
                    value: 64.2,
                },
                {
                    name: '10:50',
                    value: 68,
                },
                {
                    name: '10:55',
                    value: 68.1,
                },
            ],
        };

        this.widget5 = {
            currentRange: 'TW',
            xAxis: true,
            yAxis: true,
            gradient: false,
            legend: false,
            showXAxisLabel: false,
            xAxisLabel: 'Days',
            showYAxisLabel: false,
            yAxisLabel: 'Alarms',
            showGridLines: false,
            scheme: {
                domain: ['#F2B134', '#ED553B', '#f44336', '#AAAAAA'],
            },
            onSelect: ev => {
                console.log(ev);
            },
            data: {
                title: 'Github Issues',
                ranges: {
                    TW: 'This Week',
                    LW: 'Last Week',
                },
                mainChart: {
                    TW: [
                        {
                            name: 'Mon',
                            series: [
                                {
                                    name: 'warning',
                                    value: 12,
                                },
                                {
                                    name: 'alarm',
                                    value: 2,
                                },
                            ],
                        },
                        {
                            name: 'Tue',
                            series: [
                                {
                                    name: 'warning',
                                    value: 16,
                                },
                                {
                                    name: 'alarm',
                                    value: 4,
                                },
                            ],
                        },
                        {
                            name: 'Wed',
                            series: [
                                {
                                    name: 'warning',
                                    value: 3,
                                },
                                {
                                    name: 'alarm',
                                    value: 1,
                                },
                            ],
                        },
                        {
                            name: 'Thu',
                            series: [
                                {
                                    name: 'warning',
                                    value: 9,
                                },
                                {
                                    name: 'alarm',
                                    value: 6,
                                },
                            ],
                        },
                        {
                            name: 'Fri',
                            series: [
                                {
                                    name: 'warning',
                                    value: 16,
                                },
                                {
                                    name: 'alarm',
                                    value: 8,
                                },
                            ],
                        },
                        {
                            name: 'Sat',
                            series: [
                                {
                                    name: 'warning',
                                    value: 2,
                                },
                                {
                                    name: 'alarm',
                                    value: 1,
                                },
                            ],
                        },
                        {
                            name: 'Sun',
                            series: [
                                {
                                    name: 'warning',
                                    value: 0,
                                },
                                {
                                    name: 'alarm',
                                    value: 0,
                                },
                            ],
                        },
                    ],
                },
            },
        };
    }

    ngAfterViewInit() {
        if (this.isKioskMode) {
            setTimeout(() => {
                if (document.fullscreenElement == null && !(window.screenTop == 0 && window.innerHeight >= screen.availHeight)) {
                    let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                        disableClose: false,
                    });

                    const message = this._fuseTranslationLoaderService.translateService.instant('DASHBOARD.KioskModeMessage');

                    confirmDialogRef.componentInstance.title = 'DASHBOARD.KioskMode';
                    confirmDialogRef.componentInstance.confirmMessage = message;
                    confirmDialogRef.afterClosed().subscribe((confirm) => {
                        if (confirm) {
                            this.changeKioskMode(true);
                        }
                        confirmDialogRef = null;
                    });
                } else {
                    this.changeKioskMode(true);
                }
            }, 500);
        }
    }

    get viewTitle(): string {
        return this.currentView ? this.currentView.name : 'Overview'; // TODO: translate
    }

    get currentView(): ProjectViewModel {
        const currentProject = this.contextService.currentProject;
        const preferredView = this.projectViewPreferenceService.savedView(currentProject);
        return this.projectViews.find(v => v.id === preferredView);
    }

    displayedGraphs(): IDashboardGraph[] {
        const currentView = this.currentView;

        this.showConnected = false;
        this.showEventStream = false;
        if (!currentView) {
            // No view selected, show first 5
            const maxDevice = 5;
            let devices = this.devices;
            devices = this.devices.filter(device => device.lastData);

            let x = 1, y = 0;
            let views = devices.splice(0, maxDevice).map(d => {
                if (y == 0 && x >= 3) {
                    y = 1;
                    x = 0;
                }
                return { device: d, dataType: DataTypeModelKey.soundPressureLevel, cols: 1, rows: 1, x: x++, y: y, data: null };
            });

            views.push({ device: devices[0], dataType: DataTypeModelKey.onlineDevices, cols: 1, rows: 1, x: 0, y: 0, data: null })
            views.push({ device: devices[0], dataType: DataTypeModelKey.eventStream, cols: 1, rows: 2, x: 3, y: 0, data: null })

            return views;
        } else {
            const views = currentView.entries.map(e => {
                const device = this.devices.find(d => d.id === e.projectDeviceId);
                if (e.dataType == DataTypeModelKey.onlineDevices) {
                    this.showConnected = true;
                } else if (e.dataType === DataTypeModelKey.eventStream) {
                    this.showEventStream = true;
                }
                return { device: device, dataType: e.dataType, cols: e.cols, rows: e.rows, x: e.x, y: e.y, data: e };
            });
            return views;
        }
    }

    private loadData() {
        const currentProject = this.contextService.currentProject;
        const projectRequest = this.projectDeviceService.getAll(currentProject);
        const viewRequest = this.projectViewService.getAll(currentProject);
        forkJoin([projectRequest, viewRequest]).subscribe(
            data => {
                this.devices = data[0];
                this.projectViews = data[1];
                this.graphs = this.displayedGraphs();
            },
            error => {
                this.toastr.error('Error: ' + error.message);
                console.log(error);
            },
        );
    }

    private itemChange(item, itemComponent) {
        if (item.data.cols != item.cols || item.data.rows != item.rows || item.data.x != item.x || item.data.y != item.y) {
            item.data.cols = item.cols;
            item.data.rows = item.rows;
            item.data.x = item.x;
            item.data.y = item.y;
            //window.dispatchEvent(new Event('resize'));

            this.projectViewService.edit(this.contextService.currentProject, this.currentView).subscribe(
                err => console.log(err),
            );
        }
    }

    private itemResize(item, itemComponent) {
        //console.info('itemResized');
    }

    private edit() {
        this.editing = !this.editing;
        this.options.draggable.enabled = this.editing;
        this.options.resizable.enabled = this.editing;
        this.options.api.optionsChanged();
    }

    private onShowConnected() {
        let view = this.currentView;

        if (!this.showConnected) {
            const index = view.entries.findIndex(
                e => e.projectDeviceId === this.devices[0].id && e.dataType === DataTypeModelKey.onlineDevices,
            );
            if (index > -1) {
                view.entries.splice(index, 1);
            }
        } else {
            view.entries.push({ projectDeviceId: this.devices[0].id, dataType: DataTypeModelKey.onlineDevices, cols: 1, rows: 1, x: 0, y: 0 });
        }

        this.projectViewService
            .edit(this.contextService.currentProject, view)
            .subscribe(null, err => console.log(err));
    }

    private onShowEventStream() {
        let view = this.currentView;

        if (!this.showEventStream) {
            const index = view.entries.findIndex(
                e => e.projectDeviceId === this.devices[0].id && e.dataType === DataTypeModelKey.eventStream,
            );
            if (index > -1) {
                view.entries.splice(index, 1);
            }
        } else {
            view.entries.push({ projectDeviceId: this.devices[0].id, dataType: DataTypeModelKey.eventStream, cols: 1, rows: 2, x: 0, y: 0 });
        }

        this.projectViewService
            .edit(this.contextService.currentProject, view)
            .subscribe(null, err => console.log(err));
    }

    private changeKioskMode(force: boolean = false) {
        if (!force) {
            this.contextService.isKioskModeEnabled = !this.contextService.isKioskModeEnabled;
        }

        const element = document.documentElement;
        if (this.isKioskMode) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }

            this.config.setConfig({ colorTheme: 'theme-dark' }, { emitEvent: true });

            this.resetMouseTimeout();

            document.addEventListener('mousemove', () => {
                this.showMouse();
                this.resetMouseTimeout();
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            this.config.setConfig({ colorTheme: 'theme-default' }, { emitEvent: true });
        }
    }

    private hideMouse() {
        document.body.style.cursor = 'none';
    }

    private showMouse() {
        document.body.style.cursor = 'default';
    }

    private resetMouseTimeout() {
        clearTimeout(this.mouseTimeout);
        this.mouseTimeout = setTimeout(this.hideMouse, 5000); // hide the mouse after 5 seconds of inactivity
    }

    reloadPage() {
        location.reload();
    }
}
