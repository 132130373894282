export const chartPowerSupplyStatus5Minutes = {
	constructor: "stockChart",
	options: {
		chart: {
			type: "areaspline",
			height: 600,
		},
		rangeSelector: {
			enabled: false
		},
		plotOptions: {
			line: {
				gapSize: 0,
				gapUnit: "value",
				dataGrouping: {
					enabled: false,
				}
			},
			series: {
				marker: {
					enabled: false,
					states: {
						hover: {
							enabled: true,
							radiusPlus: 2,
							lineWidthPlus: 2
						}
					}
				},
				states: {
					hover: {
						lineWidth: 2
					}
				}
			}
		},
		xAxis: {
			type: "datetime",
			title: {
				text: null
			},
			ordinal: false
		},
		yAxis: [{
			labels: {
				align: "left"
			},
			floor: 0,
			ceiling: 1.05,
			max: 1.05,
			min: 0,
			plotLines: [],
		}],
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		time: {
			useUTC: false,
		},
		series: [
			{
				data: [],
				name: "Power Supply",
				lineWidth: 2,
				color: '#0C65E8',
				states: {
					hover: {
						enabled: true,
					}
				},
				visible: true,
			}
		]
	},
	update: true,
	visible: false
};