import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { EmmaCloudinaryModule } from 'app/components/emma-cloudinary.module';
import { AuthGuard } from 'app/guards/auth.guard';
import { CompanyResolver } from 'app/resolvers/company.resolver';
import { DeviceResolver } from 'app/resolvers/device.resolver';
import { ProjectResolver } from 'app/resolvers/project.resolver';
import { UserResolver } from 'app/resolvers/user.resolver';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastrModule } from 'ngx-toastr';
import { CompaniesComponent } from './company-admin/companies/companies.component';
import { CompanyComponent } from './company-admin/company/company.component';
import { DeviceAdminComponent } from './devices-admin/device/deviceAdmin.component';
import { DevicesAdminComponent } from './devices-admin/devices/devicesAdmin.component';
import { ProjectAlarmComponent } from './projects-admin/project-alarm/project-alarm.component';
import { ProjectDeviceAddEditComponent } from './projects-admin/project-device/project-device-add-edit/project-device-add-edit.component';
import { ProjectDevicesComponent } from './projects-admin/project-device/project-devices/project-devices.component';
import { ProjectPeriodAddEditComponent } from './projects-admin/project-period-add-edit/project-period-add-edit.component';
import { ProjectPeriodsComponent } from './projects-admin/project-periods/project-periods.component';
import { ProjectAdminComponent } from './projects-admin/project/project-admin.component';
import { ProjectsAdminComponent } from './projects-admin/projects/projects-admin.component';
import { UserComponent } from './users-admin/user/user.component';
import { UsersComponent } from './users-admin/users/users.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material';

const routes = [
    {
        path: 'admin/users',
        component: UsersComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/user/:id',
        component: UserComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: UserResolver,
        },
    },
    {
        path: 'admin/companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/company/:id',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: CompanyResolver,
        },
    },
    {
        path: 'admin/devices',
        component: DevicesAdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/device/:id',
        component: DeviceAdminComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: DeviceResolver,
        },
    },
    {
        path: 'admin/projects',
        component: ProjectsAdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/project/:id',
        component: ProjectAdminComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ProjectResolver,
        },
    },
];

@NgModule({
    declarations: [
        CompaniesComponent,
        CompanyComponent,
        DeviceAdminComponent,
        DevicesAdminComponent,
        ProjectAdminComponent,
        ProjectAlarmComponent,
        ProjectDeviceAddEditComponent,
        ProjectDevicesComponent,
        ProjectPeriodAddEditComponent,
        ProjectPeriodsComponent,
        ProjectsAdminComponent,
        UserComponent,
        UsersComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        CommonModule,
        ComponentsModule,
        TranslateModule,

        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatDialogModule,
        MatTooltipModule,

        NgxFileDropModule,

        FuseSharedModule,
        FuseWidgetModule,

        FontAwesomeModule,

        EmmaCloudinaryModule,

        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 2500,
            maxOpened: 1,
            autoDismiss: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
    ],
    entryComponents: [ProjectDeviceAddEditComponent, ProjectPeriodAddEditComponent],
    exports: [UsersComponent, CompaniesComponent],
})
export class AdminModule {}
