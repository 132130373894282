import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlarmActiveEvents, AlarmModel } from 'app/models/alarm.model';
import { EventDataModel } from 'app/models/event.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class AlarmService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'project-device';
    }

    getAllDevice(projectDeviceId: string): Observable<AlarmModel[]> {
        const url = `${projectDeviceId}/alarm`;
        return this.http.get<AlarmModel[]>(this.getUrl(url));
    }

    create(projectDeviceId: string, alarm: AlarmModel): Observable<AlarmModel> {
        const url = `${projectDeviceId}/alarm`;
        return this.http.post<AlarmModel>(this.getUrl(url), alarm);
    }

    edit(projectDeviceId: string, alarm: AlarmModel): Observable<AlarmModel> {
        const url = `${projectDeviceId}/alarm/`;
        return this.http.put<AlarmModel>(this.getUrl(url), alarm);
    }

    get(projectDeviceId: string, alarmId: string): Observable<AlarmModel> {
        const url = `${projectDeviceId}/alarm/${alarmId}`;
        return this.http.get<AlarmModel>(this.getUrl(url));
    }

    delete(projectDeviceId: string, alarmId: string): Observable<EventDataModel> {
        const url = `${projectDeviceId}/alarm/${alarmId}`;
        return this.http.delete<EventDataModel>(this.getUrl(url));
    }

    getActiveEvents(projectDeviceId: string, alarmId: string): Observable<AlarmActiveEvents> {
        const url = `${projectDeviceId}/alarm/${alarmId}/active-events`;
        return this.http.get<AlarmActiveEvents>(this.getUrl(url));
    }
}
