import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventDataModel } from 'app/models/event.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PaginationRequest } from './interfaces/request.interface';
import { PaginationResponse } from './interfaces/response.interface';

export interface EventRequest extends PaginationRequest {
    project?: string;
    devicesId?: string[];
    severity?: string[];
    pendingAck?: boolean;
}

@Injectable()
export class EventService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'event';
    }

    getAll(req: EventRequest): Observable<PaginationResponse<EventDataModel>> {
        return this.http.get<PaginationResponse<EventDataModel>>(this.getUrl(''), {
            params: req as any,
        });
    }

    get(id: string): Observable<EventDataModel> {
        return this.http.get<EventDataModel>(this.getUrl(id));
    }

    create(event: EventDataModel): Observable<EventDataModel> {
        delete event.type;
        return this.http.post<EventDataModel>(this.getUrl(''), event);
    }

    edit(event: EventDataModel): Observable<EventDataModel> {
        delete event.type;
        delete event.projectId;
        return this.http.put<EventDataModel>(this.getUrl(''), event);
    }

    delete(id: string): Observable<EventDataModel> {
        return this.http.delete<EventDataModel>(this.getUrl(String(id)));
    }

    acknowledge(id: string): Observable<void> {
        return this.http.post<void>(this.getUrl(`${id}/ack`), event);
    }
}
