<mat-table class="events-table" #table [dataSource]="events" [@animateStagger]="{ value: '50' }" fusePerfectScrollbar>
    <!-- Confirmation checkbox -->
    <ng-container matColumnDef="ACK">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let event" (click)="$event.stopPropagation()">
            <div *ngIf="this.ackEnabled(event)">
                <div *ngIf="!event.ACK">
                    <button mat-button (click)="ackEvent(event)">
                        <mat-icon>radio_button_unchecked</mat-icon>
                    </button>
                </div>
                <div *ngIf="event.ACK">
                    <button mat-button disabled="true">
                        <mat-icon class="margin-icon">check_circle_outline</mat-icon>
                    </button>
                </div>
            </div>
        </mat-cell>
    </ng-container>

    <!-- ackUser Column -->
    <ng-container matColumnDef="ackUser">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.AckUser' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event">
            <p class="text-truncate" fxHide fxShow.gt-xs>{{ event.ackUser?.name }}</p>
        </mat-cell>
    </ng-container>

    <!-- Severity Column -->
    <ng-container matColumnDef="severity">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.Severity' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event">
            <app-alarm-type-span [severity]="event.severity"></app-alarm-type-span>
            <p class="text-truncate" fxHide fxShow.gt-xs>{{ 'SEVERITY.' + event.severity | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.Type' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event">
            <p class="text-truncate">{{ 'TYPE.' + event.type | translate }}</p>
        </mat-cell>
    </ng-container>

    <!-- Alarm Column -->
    <ng-container matColumnDef="alarm">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.Alarm' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event">
            <p *ngIf="event.alarm" class="text-truncate">{{ event.alarm.name }}</p>
        </mat-cell>
    </ng-container>

    <!-- Device Column -->
    <ng-container matColumnDef="device">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.Device' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event" (click)="$event.stopPropagation()">
            <p class="text-truncate">
                <a routerLink="/device/{{event.deviceId}}">{{event.deviceData?.name}}</a>
            </p>
        </mat-cell>
    </ng-container>

    <!-- Start TIme Column -->
    <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef>
            {{ 'EVENTS.StartTime' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event">
            <p class="text-truncate" fxHide fxShow.gt-xs>
                {{ event.startTime | date: 'shortDate' }}
                {{ event.startTime | date: 'mediumTime' }}
            </p>
            <p class="text-truncate" fxHide fxShow.xs>
                {{ event.startTime | date: 'dd/MM/yy' }}<br/>
                {{ event.startTime | date: 'HH:mm' }}
            </p>
        </mat-cell>
    </ng-container>

    <!-- End TIme Column -->
    <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
            {{ 'EVENTS.EndTime' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event" fxHide fxShow.gt-xs>
            <p *ngIf="event.endTime > '2000-01-01'" class="text-truncate">
                {{ event.endTime | date: 'shortDate' }}
                {{ event.endTime | date: 'mediumTime' }}
            </p>
        </mat-cell>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
            {{ 'EVENTS.Description' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let event" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{ event.description }}</p>
        </mat-cell>
    </ng-container>

    <!-- Extra buttons column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-check" *matCellDef="let event">
            <div *ngIf="event.type == 'user_defined'" fxFlex="row" fxLayoutAlign="end center">
                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                    (click)="$event.stopPropagation()">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>
                <!-- Dotted menu entries -->
                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="remove" (click)="deleteEvent(event)">
                        <mat-icon>delete</mat-icon>
                        <span>{{ 'EVENTS.Remove' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row *matRowDef="let event; columns: displayedColumns" class="event" matRipple (click)="eventClick(event)"
        [class.ack-background]="this.ackEnabled(event) && event.ACK">
    </mat-row>
</mat-table>
