<div class="page-layout emma simple fullwidth">

    <!-- SIDENAV HEADER -->
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center">
        <h2>{{'SIDEBAR.Views' | translate}}</h2>
    </div>
    <!-- / SIDENAV HEADER -->

    <!-- SIDENAV CONTENT -->
    <div class="content-card">
        <!-- VIEW LIST -->
        <mat-action-list class="views-list">
            <mat-list-item (click)="selectView(null)" [ngClass]="{'selectedItem': !selectedView}">
                <p class="text-truncate">DEFAULT</p>
                <!-- TODO: Localize -->
            </mat-list-item>

            <mat-list-item *ngFor="let view of views" [ngClass]="{'selectedItem': isSelectedView(view)}"
                (click)="selectView(view)">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <p *ngIf="!isEditView(view)" class="text-truncate">{{ view.name }}</p>
                    <mat-form-field *ngIf="isEditView(view)" floatLabel="never">
                        <input matInput placeholder="View Name" (value)="view.name" [(ngModel)]="editViewName"
                            (click)="$event.stopPropagation()">
                    </mat-form-field>
                    <div>
                        <!-- Edit/remove buttons -->
                        <button *ngIf="!isEditView(view)" mat-icon-button (click)="editView(view, $event)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="!isEditView(view)" mat-icon-button (click)="deleteView(view, $event)">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <!-- Edit custom actions -->
                        <button *ngIf="isEditView(view)" [disabled]="editViewName.length == 0" mat-icon-button
                            (click)="confirmEdit(view, $event)">
                            <mat-icon>check</mat-icon>
                        </button>
                        <button *ngIf="isEditView(view)" mat-icon-button (click)="cancelEdit(view, $event)">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-list-item>


            <mat-list-item disableRipple>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                    <!-- Add button -->
                    <button mat-raised-button class="add-view-button" color="primary" *ngIf="!addingView"
                        (click)="addView()">{{'SIDEBAR.AddView' | translate}}</button>
                    <!-- Add name -->
                    <mat-form-field *ngIf="addingView" floatLabel="never">
                        <input matInput placeholder="View Name" [(ngModel)]="newViewName">
                    </mat-form-field>
                    <div>
                        <!-- Add actions -->
                        <button *ngIf="addingView" [disabled]="newViewName.length == 0" mat-icon-button
                            (click)="confirmAdd()">
                            <mat-icon>check</mat-icon>
                        </button>
                        <button *ngIf="addingView" mat-icon-button (click)="cancelAdd()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-list-item>
        </mat-action-list>
        <!-- VIEW LIST -->
    </div>
    <!-- / SIDENAV CONTENT -->
</div>