import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AlarmModelTimeWindow } from 'app/models/alarm.model';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceAggregatedData, DeviceDataModel, DeviceDataType } from 'app/models/deviceData.model';
import { EventModelSeverity } from 'app/models/event.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DataService } from 'app/services/api/data.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import * as moment from 'moment';
import { IMqttMessage } from "ngx-mqtt";
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { DeviceMqttService } from "../../services/api/device.mqtt.service";
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import * as Highcharts from 'highcharts/highcharts';
import { chartFreeStorage5MinutesCard } from './chart-free-storage-5-minutes-card';

@Component({
    selector: 'device-card-free-storage',
    templateUrl: './device-card-free-storage.component.html',
    styleUrls: ['./device-card-free-storage.component.scss'],
})
export class DeviceCardFreeStorage implements OnInit, OnDestroy {
    @Input() device: ProjectDeviceModel;
    @Input() editGraphViews = false;

    dataType = DataTypeModelKey.freeStorage;
    loadingData = false;
    deviceData: DeviceDataModel[];
    sensorGraphData: any = null;

    statusValue: EventModelSeverity;
    statusAvg5: EventModelSeverity;
    statusAvg30: EventModelSeverity;

    Highcharts = Highcharts; // required
    chart = JSON.parse(JSON.stringify(chartFreeStorage5MinutesCard));


    subsInstant: Subscription;
    subsStatus: Subscription;
    subsAvg30: Subscription;
    subsBucket: Subscription;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly dataService: DataService,
        private readonly projectDeviceService: ProjectDeviceService,
        private toastr: ToastrService,
        private deviceService: DeviceMqttService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.fetchData();
        this.subscribeToTopics();
    }

    ngOnDestroy(): void {
        this.subsInstant.unsubscribe();
        this.subsStatus.unsubscribe();
        this.subsAvg30.unsubscribe();
        this.subsBucket.unsubscribe();
    }

    subscribeToTopics(): void {
        this.subsInstant = this.deviceService.deviceTopic(this.device.id, DeviceDataType.instant, DataTypeModelKey.freeStorage)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.lastData.freeStorage.value = item.value;
                this.device.lastData.freeStorage.timestamp = item.timestamp;
            });

        this.subsStatus = this.deviceService.deviceTopic(this.device.id, DeviceDataType.status, null)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.connected = item.connected;
                this.device.activeEvents = item.activeEvents;
                this.checkAlarms();
            })

        this.subsAvg30 = this.deviceService.deviceTopic(this.device.id, DeviceDataType.thirtyMinutes, DataTypeModelKey.freeStorage)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.dataAggregation30.freeStorage.average = item.average;
            })

        this.subsBucket = this.deviceService.deviceTopic(this.device.id, DeviceDataType.bucket, DataTypeModelKey.freeStorage)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());

                let last = this.deviceData[this.deviceData.length - 1];


                if (moment(last.date).format('hh:mm') == moment(item.date).format('hh:mm')) {
                    this.deviceData[this.deviceData.length - 1].aggregation = item.aggregation;
                } else {
                    this.deviceData.shift();
                    this.deviceData.push(item);
                }
                this.createSensorData();
            })
    }

    private refreshData() {
        if (this.loadingData) {
            return;
        }
        this.loadingData = true;

        this.fetchDevice();
        this.fetchData();
    }

    private checkAlarms() {
        this.statusAvg30 = this.device.getAlarmStatus(
            DataTypeModelKey.freeStorage,
            AlarmModelTimeWindow.AlarmModelTimeWindowRolling30,
        );
        this.statusValue = this.device.getAlarmStatus(
            DataTypeModelKey.freeStorage,
            AlarmModelTimeWindow.AlarmModelTimeWindowInstant,
        );
    }

    private fetchDevice() {
        // Fetch bucket of data of last hour (12 buckets)
        this.projectDeviceService.get(this.device.id).subscribe(
            (data: ProjectDeviceModel) => {
                this.device = Object.assign(new ProjectDeviceModel(), data);
                this.checkAlarms();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    private fetchData() {
        // Fetch bucket of data of last hour (12 buckets)
        this.dataService
            .getDeviceDataSample(this.device.id, [DataTypeModelKey.freeStorage], 12, true)
            .subscribe(
                (data: DeviceAggregatedData) => {
                    this.deviceData = data[DataTypeModelKey.freeStorage];
                    // From API data are coming from the most recent to the latest, reverting the order.
                    this.deviceData.sort(
                        (d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime(),
                    );
                    this.createSensorData();
                    this.loadingData = false;
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                    this.loadingData = false;
                },
            );
    }

    private createSensorData() {

        if (!this.deviceData) {
            return;
        }

        this.chart.options.series[0].data = [];

        this.deviceData.forEach(data => {
            let value = Math.round(data.aggregation.average * 10) / 10;
            let date = moment(data.date).format('HH:mm');
            this.chart.options.series[0].data.push([date, value]);
        });

        this.chart.update = true;
        this.chart.visible = true;
    }
}
