<button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" (click)="$event.stopPropagation()">
    <mat-icon class="secondary-text">star</mat-icon>
</button>

<mat-menu #moreMenu="matMenu">
    <mat-action-list>
        <mat-list-item *ngFor="let view of projectViews" (click)="clickView(view.id, $event)">
            <div xLayout="row" fxLayoutAlign="space-between center" fxFlex="1 0 auto">
                <p class="text-truncate">{{view.name}}</p>
                <mat-icon *ngIf="deviceInView(view)">check</mat-icon>
            </div>
        </mat-list-item>
    </mat-action-list>
</mat-menu>