<ul *ngFor="let event of events">
    <li class="event" [class.ack-background]="this.ackEnabled(event) && event.ACK">
        <app-alarm-type-span [severity]="event.severity" fxFlex="10"></app-alarm-type-span>
        <div class="info" fxFlex="75">
            <div>
                {{ 'TYPE.' + event.type | translate }}
                <span class="alarm-name" *ngIf="event.alarm">- {{event.alarm.name}}</span>
            </div>
            <div>
                <a *ngIf="event.deviceData" routerLink="/device/{{event.deviceId}}">{{event.deviceData.name}}</a>
                <span *ngIf="event.deviceData"> - </span><span class="timestamp">{{event.startTime | timeAgo}}</span>
            </div>
        </div>
        <div class="ack" fxFlex="15">
            <div *ngIf="this.ackEnabled(event)">
                <div *ngIf="!event.ACK">
                    <button mat-button (click)="acknowledgeEvent(event)">
                        <mat-icon>radio_button_unchecked</mat-icon>
                    </button>
                </div>
                <div *ngIf="event.ACK">
                    <button mat-button (click)="acknowledgeEvent(event)" disabled="true">
                        <mat-icon class="border-icon">check_circle_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </li>
</ul>