import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { FuseConfirmDialogModule, FuseWidgetModule } from '@fuse/components';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { AuthGuard } from 'app/guards/auth.guard';
import { TimeAgoPipe } from 'time-ago-pipe';
import { AlarmsComponent } from './components/alarms/alarms.component';
import { ConnectedDevicesComponent } from './components/connected-devices/connected-devices.component';
import { EventStreamComponent } from './components/event-stream/event-stream.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { DashboardComponent } from './dashboard.component';
import { GridsterModule } from 'angular-gridster2';
import { MatCheckboxModule } from '@angular/material/checkbox';

const routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        DashboardComponent,
        ConnectedDevicesComponent,
        EventStreamComponent,
        AlarmsComponent,
        ConnectedDevicesComponent,
        DashboardSidebarComponent,
        TimeAgoPipe,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSidenavModule,
        MatListModule,
        MatCheckboxModule,
        TranslateModule,

        FuseConfirmDialogModule,
        FuseSharedModule,
        FuseWidgetModule,

        GridsterModule,

        ComponentsModule,
    ],
    exports: [DashboardComponent],
    entryComponents: [FuseConfirmDialogComponent],
})
export class DashboardModule {}
