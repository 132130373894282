<div class="upload">
    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="h3">{{ "DEVICE.UploadFile" | translate }}</div>
    </div>

    <ngx-file-drop dropZoneLabel="{{ 'FILE.DropFile' | translate }}" (onFileDrop)="fileDropped($event)">
    </ngx-file-drop>
</div>

<div class="table">
    <div class="emma-card auto-width">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table class="files-table" #table [dataSource]="device.files" fusePerfectScrollbar>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FILE.Name' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <p class="text-truncate">{{ file.originalName }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Field Column -->
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FILE.Status' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <p class="text-truncate">{{ 'FILE.' + file.status | translate }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Time Window Column -->
                <ng-container matColumnDef="uploadDate">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FILE.UploadDate' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let file">
                        <p class="text-truncate">{{ file.uploadDate | date:'medium' }}</p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let file; columns: displayedColumns" class="file" matRipple>
                </mat-row>
            </mat-table>
        </div>
        <!-- / CONTENT CARD -->
    </div>
</div>