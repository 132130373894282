import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import 'hammerjs';

import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthModule } from 'app/modules/auth/auth.module';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { DevicesModule } from 'app/modules/devices/devices.module';
import { MapModule } from 'app/modules/map/map.module';
import { AuthGuard } from './guards/auth.guard';
import { AdminModule } from './modules/admin/admin.module';
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { httpInterceptorProviders } from './network/interceptors';
import { CompanyResolver } from './resolvers/company.resolver';
import { DeviceResolver } from './resolvers/device.resolver';
import { ProjectResolver } from './resolvers/project.resolver';
import { AuthService } from './services/api/auth.service';
import { CompanyService } from './services/api/company.service';
import { DeviceMqttService } from "./services/api/device.mqtt.service";
import { DeviceService } from './services/api/device.service';
import { ManufacturerService } from './services/api/manufacturer.service';
import { ProjectService } from './services/api/project.service';
import { UserService } from './services/api/user.service';
import { AuthenticationService } from './services/authentication.service';
import { TokenService } from './services/token.service';
import { UserResolver } from './resolvers/user.resolver';
import { ContextService } from './services/context.service';
import { DataService } from './services/api/data.service';
import { ProjectDeviceService } from './services/api/projectDevice.service';
import { ProjectDeviceResolver } from './resolvers/projectDevice.resolver';
import { EventsModule } from './modules/events/events.module';
import { EventService } from './services/api/event.service';
import { AlarmService } from './services/api/alarm.service';
import { ProjectViewPreferenceService } from './services/projectViewPreference.service';
import { ProjectViewService } from './services/api/projectView.service';
import { ProjectAlarmService } from './services/api/projectAlarm.service';
import { EventMqttService } from './services/api/event.mqtt.service';
import { environment as env } from '../environments/environment';
// import { Bugfender } from '@bugfender/sdk';

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'dashboard',
    },
];

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: env.mqtt.server,
    port: env.mqtt.port,
    protocol: (env.mqtt.protocol === "wss") ? "wss" : "ws",
    path: '',
};

// Bugfender.init({
//     appKey: 'MXesdk5Sb8LRJBk2ODQAa90pPx31sjEY',
//     version: '0.1',
//     build: '20200527',
// });

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        //Leaflet
        LeafletModule.forRoot(),

        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),

        // App modules
        AuthModule,
        DashboardModule,
        DevicesModule,
        EventsModule,
        LayoutModule,
        MapModule,

        // Admin modules
        AdminModule,
    ],
    providers: [
        httpInterceptorProviders,

        AlarmService,
        AuthenticationService,
        AuthService,
        CompanyService,
        ContextService,
        DataService,
        DeviceService,
        EventService,
        EventMqttService,
        ManufacturerService,
        DeviceMqttService,
        ProjectAlarmService,
        ProjectDeviceService,
        ProjectService,
        ProjectViewPreferenceService,
        ProjectViewService,
        TokenService,
        UserService,

        // Resolvers
        CompanyResolver,
        DeviceResolver,
        ProjectDeviceResolver,
        ProjectResolver,
        UserResolver,

        AuthGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
