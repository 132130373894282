import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DeviceModel } from 'app/models/device.model';
import { ProjectModel } from 'app/models/project.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DeviceService } from 'app/services/api/device.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { locale as catalan } from '../../../i18n/ca';
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { UploadUtils } from 'app/shared/upload.utils';

enum ProjectAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
}
@Component({
    selector: 'app-project-device-add-edit',
    templateUrl: './project-device-add-edit.component.html',
    styleUrls: ['./project-device-add-edit.component.scss'],
})
export class ProjectDeviceAddEditComponent implements OnInit {
    public deviceForm: FormGroup;
    device: ProjectDeviceModel;
    deviceName: string;
    devices: DeviceModel[] = [];
    projectDevices: ProjectDeviceModel[];
    project: ProjectModel;
    action: ProjectAction;
    editImage: boolean;

    public uploadedFile: File = undefined;
    public imageUrl: any = undefined;

    constructor(
        private formBuilder: FormBuilder,
        private translation: FuseTranslationLoaderService,
        private deviceService: DeviceService,
        public matDialogRef: MatDialogRef<ProjectDeviceAddEditComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private toastr: ToastrService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);

        if (_data.action === 'new') {
            this.action = ProjectAction.NEW;
            this.projectDevices = _data.devices;
            this.project = _data.project;
        } else if (_data.action === 'edit') {
            this.action = ProjectAction.EDIT;
            this.device = _data.device;
        }
    }

    ngOnInit() {
        this.createForm();
        this.updateDeviceData();
    }

    get baseDevice() {
        if (!this.devices) {
            return undefined;
        }

        if (this.action === ProjectAction.EDIT) {
            return this.devices.find(obj => obj.id === this.device.baseDevice);
        } else {
            return this.devices.find(obj => obj.id === this.deviceForm.get('baseDevice').value);
        }
    }

    public updatedBaseDevice() {
        if (!this.deviceForm.get('name').value) {
            this.deviceForm.get('name').setValue(this.baseDevice.name);
        }
    }

    public save() {
        const saveDevice = this.deviceForm.getRawValue();
        if (this.device && this.device.active) {
            delete saveDevice.location;
            delete saveDevice.dataFrequency;
        }

        this.matDialogRef.close({
            action: this.action === ProjectAction.NEW ? 'new' : 'edit',
            device: saveDevice,
            file: this.uploadedFile,
            editImage: this.editImage,
        });
    }

    public cancel() {
        this.matDialogRef.close();
    }

    private createForm() {
        this.deviceForm = this.formBuilder.group({
            id: [this.device ? this.device.id : null],
            name: [this.device ? this.device.name : null, [Validators.required]],
            baseDevice: [Validators.required],
            comments: [this.device ? this.device.comments : null],
            ftpFolder: [this.device ? this.device.ftpFolder : null],
            dataFrequency: [this.device ? this.device.dataFrequency : null],
            location: this.formBuilder.group({
                address: [
                    this.device ? this.device.location.address : undefined,
                    [Validators.required],
                ],
                latitude: [
                    this.device ? this.device.location.latitude : undefined,
                    [Validators.required, Validators.min(-90), Validators.max(+90)],
                ],
                longitude: [
                    this.device ? this.device.location.longitude : undefined,
                    [Validators.required, Validators.min(-180), Validators.max(+180)],
                ],
            }),
        });

        if (this.action == ProjectAction.EDIT && this.device.active) {
            this.deviceForm.get('location').disable();
            this.deviceForm.get('dataFrequency').disable();
        }
    }

    private updateDeviceData() {
        if (this.action === ProjectAction.EDIT) {
            this.imageUrl = this.device.picture;
        }

        this.deviceService.getAll().subscribe(
            devices => {
                this.devices = devices;
                if (this.action === ProjectAction.EDIT) {
                    this.deviceForm.get('baseDevice').setValue(this.baseDevice.id);
                    this.deviceForm.get('baseDevice').disable();
                    this.updatedBaseDevice();
                }
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public removeImage() {
        this.uploadedFile = undefined;
        this.imageUrl = undefined;

        this.editImage = true;
    }

    public imageDropped(files: NgxFileDropEntry[]) {
        this.editImage = true;

        const uploadedImage = files[0];
        // Check image

        // Is it a file?
        if (uploadedImage.fileEntry.isFile) {
            let fileEntry = uploadedImage.fileEntry as FileSystemFileEntry;
            fileEntry.file(file => {
                if (!UploadUtils.isFileAllowed(file)) {
                    alert('File is not a supported image');
                    return;
                }
                if (!UploadUtils.isFileSizeAllowed(file)) {
                    alert('File Size is larger than 2 MB');
                    return;
                }
                this.uploadedFile = file;

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.imageUrl = reader.result;
                };
            });
        } else {
            // It was a directory (nothing to be done)
        }
    }
}
