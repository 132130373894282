export const locale = {
    lang: 'es',
    data: {
        EVENTS: {
            Events: 'Eventos',
            Severity: 'Gravedad',
            Type: 'Tipo',
            Device: 'Dispositivo',
            StartTime: 'Hora de inicio',
            EndTime: 'Hora final',
            Description: 'Descripción',
            Remove: 'Borrar',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCELAR',
            EventType: 'Tipo de evento: ',
            NewEvent: 'Nuevo Evento',
            EventDetail: 'Detalle del evento',
            EventTimeError: 'La fecha de finalización debe ser posterior a la fecha de inicio'
        },
        SEVERITY: {
            alarm: 'Alarma',
            info: 'información',
            success: 'Ėxito',
            warning: 'Advertencia',
        },
        TYPE: {
            device_alarm_off: 'Alarma Apagada',
            device_alarm_on: 'Alarma Activada',
            device_added: 'Dispositivo Añadido',
            device_offline: 'Dispositivo fuera de línea',
            device_online: 'Dispositivo en línea',
            device_removed: 'Dispositivo eliminado',
            user_defined: 'Definido por el usuario',
            device_warning_off: 'Advertencia desactivada',
            device_warning_on: 'Advertencia activada',
        },
    },
};
