<!-- Graph with date picker -->
<div class="emma-card auto-width">
    <div class="filters">
        <mat-form-field>
            <mat-select placeholder="{{'DEVICE.Data' | translate}}" [(value)]="dataField" (selectionChange)="reloadData()">
                <mat-option *ngFor="let field of device.fields" [value]="field">{{ "FIELD." + field | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="dataField != 'spectrum_13_octave'">
            <mat-select placeholder="{{'DEVICE.DateRange' | translate}}" [(value)]="dateRange" (selectionChange)="reloadData()">
                <mat-option value="last15minutes">{{ "DEVICE.Last15Minutes" | translate }}</mat-option>
                <mat-option value="lasthour">{{ "DEVICE.LastHour" | translate }}</mat-option>
                <mat-option value="last12hours">{{ "DEVICE.Last12Hours" | translate }}</mat-option>
                <mat-option value="last24hours">{{ "DEVICE.Last24Hours" | translate }}</mat-option>
                <mat-option value="custom">{{ "DEVICE.Custom" | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="dateRange == 'custom'">
            <input matInput [matDatepicker]="start" placeholder="{{'DEVICE.StartDate' | translate}}" [(ngModel)]="startDate"
                (dateChange)="updateEndDate($event)" (dateInput)="updateEndDate($event)" />
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start (closed)="reloadData()"></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="dateRange == 'custom'">
            <mat-icon matSuffix [inline]="true">access_time</mat-icon>
            <input matInput type="time" [(ngModel)]="startTime" placeholder="{{'DEVICE.StartTime' | translate}}" step="300" (change)="reloadData()" />
        </mat-form-field>
        <mat-form-field *ngIf="dateRange == 'custom'">
            <input matInput [matDatepicker]="end" placeholder="{{'DEVICE.EndDate' | translate}}" [min]="endDateMin" [max]="endDateMax"
                [(ngModel)]="endDate" />
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end (closed)="reloadData()"></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="dateRange == 'custom'">
            <mat-icon matSuffix [inline]="true">access_time</mat-icon>
            <input matInput type="time" [(ngModel)]="endTime" placeholder="{{'DEVICE.EndTime' | translate}}" step="300" (change)="reloadData()" />
        </mat-form-field>
        <mat-form-field *ngIf="dataField != 'spectrum_13_octave' && dataField != 'battery' && dataField!='free_storage'">
            <mat-select placeholder="{{'DEVICE.DataAggregation' | translate}}" [(value)]="groupingData" (selectionChange)="reloadData()">
                <mat-option *ngIf="enableInstantValues" value="no">{{"DEVICE.NoAggregation" | translate}}</mat-option>
                <mat-option value="5minutes">{{"DEVICE.Minutes5" | translate}}</mat-option>
                <!--<mat-option value="30minutes">30 Minutes</mat-option>-->
            </mat-select>
        </mat-form-field>
        <mat-checkbox *ngIf="enableLiveData" [(ngModel)]="liveData">{{"DEVICE.LiveUpdate" | translate}}</mat-checkbox>
        <mat-checkbox *ngIf="enablePercentiles" [(ngModel)]="showL10" (change)="reloadGraph()">
            {{"DEVICE.ShowL10" | translate}}
        </mat-checkbox>
        <mat-checkbox *ngIf="enablePercentiles" [(ngModel)]="showL90" (change)="reloadGraph()">
            {{"DEVICE.ShowL90" | translate}}
        </mat-checkbox>
        <button mat-raised-button class="download-button" (click)="downloadData()" *ngIf="dataField != 'spectrum_13_octave'">
            <mat-icon>cloud_download</mat-icon>
            {{ "DEVICE.Export" | translate }}
        </button>
    </div>

    <div *ngFor="let chart of chartsConfig">
        <highcharts-chart *ngIf="chart.visible" [Highcharts]="Highcharts" [constructorType]="chart.constructor" [options]="chart.options"
            [(update)]="chart.update" style="width: 100%; display: block;"></highcharts-chart>
    </div>
</div>

<div class="emma-card auto-width card-export">
    <div class="filters">
        <mat-form-field>
            <mat-select placeholder="{{'DEVICE.Data' | translate}}" [(value)]="dataFieldExport">
                <mat-option *ngFor="let field of device.fields" [value]="field">{{ "FIELD." + field | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="start" placeholder="{{'DEVICE.StartDate' | translate}}" [(ngModel)]="startDateExport" />
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-icon matSuffix [inline]="true">access_time</mat-icon>
            <input matInput type="time" [(ngModel)]="startTimeExport" placeholder="{{'DEVICE.StartTime' | translate}}" step="300" />
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="end" placeholder="{{'DEVICE.EndDate' | translate}}" [(ngModel)]="endDateExport" />
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-icon matSuffix [inline]="true">access_time</mat-icon>
            <input matInput type="time" [(ngModel)]="endTimeExport" placeholder="{{'DEVICE.EndTime' | translate}}" step="300" />
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="{{'DEVICE.DataAggregation' | translate}}" [(value)]="groupingDataExport">
                <mat-option value="no">{{"DEVICE.NoAggregation" | translate}}</mat-option>
                <mat-option value="5minutes">{{"DEVICE.Minutes5" | translate}}</mat-option>
                <!--<mat-option value="30minutes">30 Minutes</mat-option>-->
            </mat-select>
        </mat-form-field>
    </div>
    <div style="text-align: center;" class="print-hide">
        <button mat-raised-button class="download-button" (click)="downloadDataExport()" *ngIf="dataField != 'spectrum_13_octave'"
            [disabled]="exporting">
            <mat-spinner [diameter]="25" *ngIf="exporting"></mat-spinner>
            <mat-icon *ngIf="!exporting">cloud_download</mat-icon>
            {{ "DEVICE.Download" | translate }}
        </button>
    </div>
</div>