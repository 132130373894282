<div class="dialog-content-wrapper">
    <!-- Header start -->
    <mat-toolbar class="mat-accent toolbar m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span *ngIf="action == 'NEW'" class="title dialog-title">{{'PROJECTS.ProjectPeriodNew' | translate}}</span>
            <span *ngIf="action == 'EDIT'" class="title dialog-title">{{period?.name}}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- Header end -->

    <mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form name="periodForm" [formGroup]="periodForm" class="period-form w-100-p" fxLayout="column" fxFlex>
            <div class="section">
                <!-- Name -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'PROJECTS.ProjectPeriodName' | translate }}</mat-label>
                    <input matInput name="name" formControlName="name"
                        placeholder="{{ 'PROJECTS.ProjectPeriodName' | translate }}" />
                </mat-form-field>
            </div>

            <div class="section">
                <!-- Start Time -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'PROJECTS.ProjectPeriodStart' | translate }}</mat-label>
                    <mat-select formControlName="startTime" (selectionChange)="checkPeriods()">
                        <mat-option *ngFor="let interval of timeIntervals" [value]="interval">
                            {{ interval }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- End Time -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'PROJECTS.ProjectPeriodEnd' | translate }}</mat-label>
                    <mat-select formControlName="endTime" (selectionChange)="checkPeriods()">
                        <mat-option *ngFor="let interval of timeIntervals" [value]="interval">
                            {{ interval }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-label color="warn" *ngIf="warnUser">
                    {{'PROJECTS.ProjectPeriodError' | translate}}</mat-label>
            </div>
            <div class="form-action">
                <button *ngIf="action == 'NEW'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    [disabled]="periodForm.invalid" ngsw>
                    {{ 'PROJECTS.ADD' | translate }}
                </button>
                <button *ngIf="action == 'EDIT'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    ngsw>
                    {{ 'PROJECTS.SAVE' | translate }}
                </button>
                <button mat-raised-button (click)="cancel()">
                    {{ 'PROJECTS.CANCEL' | translate }}
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>