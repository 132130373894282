<div class="dialog-content-wrapper">
    <!-- Header start -->
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span *ngIf="action == 'NEW'" class="title dialog-title">{{
                'ALARM.NewAlarm' | translate
            }}</span>
            <span *ngIf="action == 'EDIT'" class="title dialog-title">{{
                'ALARM.AlarmDetail' | translate
            }}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- Header end -->

    <mat-dialog-content fusePerfectScrollbar>
        <form name="alarmForm" [formGroup]="alarmForm" class="alarm-form w-100-p" fxLayout="column" fxFlex>
            <!-- Name Input -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Name' | translate }}</mat-label>
                <input matInput formControlName="name" placeholder="{{ 'ALARM.Name' | translate }}" type="text" />
            </mat-form-field>

            <!-- Description Input -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Description' | translate }}</mat-label>
                <textarea matInput name="description" formControlName="description"
                    placeholder="{{ 'ALARM.Description' | translate }}">
                </textarea>
            </mat-form-field>

            <!-- Field Select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Field' | translate }}</mat-label>
                <mat-select formControlName="field" placeholder="{{ 'ALARM.Field' | translate }}">
                    <mat-option *ngFor="let field of alarmFields" [value]="field">
                        {{ 'FIELD.'+ field | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Window select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.TimeWindow' | translate }}</mat-label>
                <mat-select formControlName="window" placeholder="{{ 'ALARM.TimeWindow' | translate }}">
                    <mat-option *ngFor="let window of windowValues" [value]="window">
                        {{ 'ALARM.'+ window | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Hidden -->
            <mat-checkbox formControlName="silent">{{ 'ALARM.Silent' | translate }}</mat-checkbox>

            <!-- ACK -->
            <mat-checkbox formControlName="needACK">{{ 'ALARM.NeedACK' | translate }}</mat-checkbox>

            <!-- Threshold select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Threshold' | translate }}</mat-label>
                <mat-select formControlName="threshold" placeholder="'ALARM.Threshold' | translate">
                    <mat-option *ngFor="let threshold of thresholdValues" [value]="threshold">
                        {{ 'ALARM.'+ threshold | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Limit Select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Limit' | translate }}</mat-label>
                <input matInput formControlName="limit" placeholder="{{ 'ALARM.Limit' | translate }}" type="number" />
            </mat-form-field>

            <!-- Samples Select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Samples' | translate }}</mat-label>
                <input matInput formControlName="samples" placeholder="{{ 'ALARM.Samples' | translate }}"
                    type="number" />
            </mat-form-field>

            <!-- Severity -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'ALARM.Severity' | translate }}</mat-label>
                <mat-select formControlName="severity" placeholder="Severity" required>
                    <mat-option *ngFor="let severity of severityValues" [value]="severity">
                        {{ 'SEVERITY.' + severity | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Notify -->
            <mat-checkbox *ngIf="user.isAdmin" formControlName="notify">{{ 'ALARM.Notify' | translate }}</mat-checkbox>

            <!-- Notification List -->
            <mat-form-field appearance="outline" *ngIf="user.isAdmin">
                <mat-chip-list #notificationList>
                    <mat-chip *ngFor="let item of alarm.notificationList" removable
                        (removed)="removeNotificationItem(item)">
                        {{item}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="{{'ALARM.NewNotificationItem' | translate}}"
                        [matChipInputFor]="notificationList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addNotificationItem($event)">
                </mat-chip-list>
            </mat-form-field>

            <!-- Ignored Devices -->
            <mat-form-field *ngIf="alarmType == 'PROJECT'" appearance="outline">
                <mat-label>{{ 'ALARM.IgnoredDevices' | translate }}</mat-label>
                <mat-select formControlName="ignoredDevices" placeholder="{{ 'ALARM.IgnoredDevices' | translate }}"
                    multiple>
                    <mat-option *ngFor="let device of projectDevices" [value]="device.id">
                        {{ device.deviceName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- <mat-label color="warn" *ngIf="eventForm.errors?.timeOrderError">{{
                'EVENTS.EventTimeError' | translate
            }}</mat-label> -->

            <!-- Actions -->
            <div class="form-action">
                <button *ngIf="action == 'NEW'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    [disabled]="alarmForm.invalid" ngsw>
                    {{ 'ALARM.ADD' | translate }}
                </button>
                <button *ngIf="action == 'EDIT'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    [disabled]="alarmForm.invalid" ngsw>
                    {{ 'ALARM.SAVE' | translate }}
                </button>
                <button mat-raised-button (click)="cancel()">
                    {{ 'ALARM.CANCEL' | translate }}
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>