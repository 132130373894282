/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm-type-span.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./alarm-type-span.component";
var styles_AlarmTypeSpanComponent = [i0.styles];
var RenderType_AlarmTypeSpanComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmTypeSpanComponent, data: {} });
export { RenderType_AlarmTypeSpanComponent as RenderType_AlarmTypeSpanComponent };
export function View_AlarmTypeSpanComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "dot"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "red": 0, "green": 1, "orange": 2, "primary": 3 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "red": 0, "green": 1, "orange": 2, "primary": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dot"; var currVal_1 = _ck(_v, 3, 0, (_co.severity === "alarm"), (_co.severity === "success"), (_co.severity === "warning"), (_co.severity === "info")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.severity === "alarm"), (_co.severity === "success"), (_co.severity === "warning"), (_co.severity === "info")); var currVal_3 = "dot"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_AlarmTypeSpanComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-type-span", [], null, null, null, View_AlarmTypeSpanComponent_0, RenderType_AlarmTypeSpanComponent)), i1.ɵdid(1, 114688, null, 0, i5.AlarmTypeSpanComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmTypeSpanComponentNgFactory = i1.ɵccf("app-alarm-type-span", i5.AlarmTypeSpanComponent, View_AlarmTypeSpanComponent_Host_0, { severity: "severity" }, {}, []);
export { AlarmTypeSpanComponentNgFactory as AlarmTypeSpanComponentNgFactory };
