const ɵ0 = [];
export const chartCellularLevel = {
    constructor: "stockChart",
    options: {
        chart: {
            type: "line",
            height: 600,
        },
        rangeSelector: {
            enabled: false
        },
        tooltip: {
            valueSuffix: "%"
        },
        plotOptions: {
            line: {
                gapSize: 15 * 1000,
                gapUnit: "value",
                dataGrouping: {
                    enabled: false,
                }
            },
            series: {
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            radiusPlus: 2,
                            lineWidthPlus: 2
                        }
                    }
                },
                states: {
                    hover: {
                        lineWidth: 2
                    }
                }
            }
        },
        xAxis: {
            type: "datetime",
            title: {
                text: null
            },
            ordinal: false
        },
        yAxis: [{
                labels: {
                    align: "left"
                },
                floor: 0,
                ceiling: 105,
                max: 105,
                min: 0,
                plotLines: [],
            }],
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false,
        },
        series: [
            {
                data: ɵ0,
                name: "Cellular",
                lineWidth: 2,
                color: '#0C65E8',
                states: {
                    hover: {
                        enabled: true,
                    }
                },
                visible: true,
            }
        ]
    },
    update: true,
    visible: false
};
export { ɵ0 };
