<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button href="http://www.axioma-acustic.com">Powered by Axioma Acoustics</a>
            <span>&bull;</span>
            <a mat-button href="#">Copyright Emma 2018</a>
        </div>
    </div>

</mat-toolbar>
