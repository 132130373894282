import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectViewModel } from 'app/models/projectView.model';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class ProjectViewService extends ApiService {
    private _onDeleteProjectView = new Subject<ProjectViewModel>();
    private _onAddProjectView = new Subject<ProjectViewModel>();
    private _onEditProjectView = new Subject<ProjectViewModel>();
    private _viewList = [];

    public readonly onDeleteProjectView: Observable<
        ProjectViewModel
    > = this._onDeleteProjectView.asObservable();
    public readonly onAddProjectView: Observable<
        ProjectViewModel
    > = this._onAddProjectView.asObservable();
    public readonly onEditProjectView: Observable<
        ProjectViewModel
    > = this._onEditProjectView.asObservable();

    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'project';
    }

    public get viewList(): ProjectViewModel[] {
        return this._viewList;
    }

    getAll(projectId: string): Observable<ProjectViewModel[]> {
        return this.http
            .get<ProjectViewModel[]>(this.getUrl(`${projectId}/view`))
            .pipe(tap(data => (this._viewList = data)));
    }

    create(projectId: string, projectView: ProjectViewModel): Observable<ProjectViewModel> {
        return this.http.post<ProjectViewModel>(this.getUrl(`${projectId}/view`), projectView).pipe(
            tap(data =>
                // Force a refresh of local list and notify
                this.getAll(projectId).subscribe(() => this._onAddProjectView.next(data)),
            ),
        );
    }

    edit(projectId: string, projectView: ProjectViewModel): Observable<ProjectViewModel> {
        return this.http.put<ProjectViewModel>(this.getUrl(`${projectId}/view`), projectView).pipe(
            tap(data =>
                // Force a refresh of local list and notify
                this.getAll(projectId).subscribe(() => this._onEditProjectView.next(data)),
            ),
        );
    }

    delete(projectId: string, viewId: string) {
        return this.http.delete<ProjectViewModel>(this.getUrl(`${projectId}/view/${viewId}`)).pipe(
            tap(data =>
                // Force a refresh of local list and notify
                this.getAll(projectId).subscribe(() => this._onDeleteProjectView.next(data)),
            ),
        );
    }
}
