import { FuseNavigation } from '@fuse/types';

export const userNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARD',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard',
            },
            {
                id: 'devices',
                title: 'Devices',
                translate: 'NAV.DEVICES',
                type: 'item',
                icon: 'dvr',
                url: '/devices',
            },
            {
                id: 'map',
                title: 'Map',
                translate: 'NAV.MAP',
                type: 'item',
                icon: 'map',
                url: '/map',
            },
            {
                id: 'events',
                title: 'Events',
                translate: 'NAV.EVENTS',
                type: 'item',
                icon: 'notifications',
                url: '/events',
            },
            /*{
                id: 'reports',
                title: 'Reports',
                translate: 'NAV.REPORTS',
                type: 'item',
                icon: 'assessment',
                url: '/reports',
            },*/
        ],
    },
];

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'NAV.DASHBOARD',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard',
            },
            {
                id: 'devices',
                title: 'Devices',
                translate: 'NAV.DEVICES',
                type: 'item',
                icon: 'dvr',
                url: '/devices',
            },
            {
                id: 'map',
                title: 'Map',
                translate: 'NAV.MAP',
                type: 'item',
                icon: 'map',
                url: '/map',
            },
            {
                id: 'events',
                title: 'Events',
                translate: 'NAV.EVENTS',
                type: 'item',
                icon: 'notifications',
                url: '/events',
            },
            /*{
                id: 'reports',
                title: 'Reports',
                translate: 'NAV.REPORTS',
                type: 'item',
                icon: 'assessment',
                url: '/reports',
            },*/
        ],
    },
    {
        id: 'applications',
        title: 'ADMIN',
        type: 'group',
        children: [
            {
                id: 'companies',
                title: 'Companies',
                translate: 'NAV.COMPANIES',
                type: 'item',
                icon: 'domain',
                url: '/admin/companies',
            },
            {
                id: 'users',
                title: 'Users',
                translate: 'NAV.USERS',
                type: 'item',
                icon: 'group',
                url: '/admin/users',
            },
            {
                id: 'admin-devices',
                title: 'Administration Devices',
                translate: 'NAV.ADMIN-DEVICES',
                type: 'item',
                icon: 'device_hub',
                url: '/admin/devices',
            },
            {
                id: 'admin-projects',
                title: 'Administration Projects',
                translate: 'NAV.ADMIN-PROJECTS',
                type: 'item',
                icon: 'assignment',
                url: '/admin/projects',
            },
        ],
    },
];
