export const locale = {
    lang: 'ca',
    data: {
        TAB: {
            Status: 'Estat',
            Data: 'Dades',
            Alarms: 'Alarmes',
            Events: 'Esdeveniments',
            Info: 'Info',
            Report: 'Informe',
            Files: 'Arxius',
        },
        DEVICES: {
            ID: 'ID',
            Name: 'Nom',
            Active: 'Actiu',
            LastData: 'Últimes Dades',
            Address: 'Dispositius',
            Devices: 'Dispositius',
        },
        DEVICE: {
            NoiseLevel: 'Nivel Soroll',
            Limit: 'Limit:',
            Info: 'Información',
            Name: 'Nom',
            SerialNumber: 'Número de Sèrie',
            Location: 'Localització',
            Latitude: 'Latitud',
            Longitude: 'Longitud',
            NoGPSData: 'Dades GPS invàlides',
            Comments: 'Comentaris',
            Last15Minutes: 'Últims 15 Minuts',
            LastHour: 'Última Hora',
            Last12Hours: 'Últimes 12 Hores',
            Last24Hours: 'Últimes 24 Hores',
            StartDate: "Data D'inici",
            EndDate: 'Data de Finalització',
            Data: 'Dades',
            DateRange: 'Interval',
            DataAggregation: 'Mitjana',
            NoAggregation: 'Valors Puntuals',
            Minutes5: '5 Minuts',
            LiveUpdate: 'Actualització en Directe',
            ShowL10: 'Mostra L10',
            ShowL90: 'Mostra L90',
            Custom: 'Custom',
            Download: 'Descarregar Dades CSV',
            Export: 'Exportar CSV',
            StartTime: 'Hora Inici',
            EndTime: 'Hora Fi',
            Image: 'Imatge',
            DropFiles: 'Arrossegueu imatge aquí',
            RemoveImage: 'Elimina la imatge',
            Picture: 'Imatge',
            SimOrImei: 'SIM o IMEI',
            LastCalibrationDate: 'Data de última calibració',
            BaseName: 'Dispositiu',
            UploadFile: 'Pujar Arxiu',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Nom',
            Description: 'Descripció',
            TimeWindow: 'Finestra de temps',
            Samples: 'Mostres',
            Threshold: 'Llindar',
            Limit: 'Límit',
            Severity: 'Severitat',
            Field: 'Camp',
            Remove: 'Elimina',
            time_window_instant: 'Instant',
            time_window_rolling_5: '5 minuts de mitjana rodant',
            time_window_rolling_30: '30 minuts mitjans rodants',
            time_window_bucket_5: '5 minuts bucket',
            threshold_cross_over: 'Creuem SOBRE',
            threshold_cross_below: 'Creuem SOTA',
            ADD: 'AFEGIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCEL·LAR',
            AlarmDetail: "Detall d'alarma",
            NewAlarm: 'Nova Alarma',
            Notify: 'Notificar',
            Silent: 'Silenciosa',
            NewNotificationItem: 'email/telèfon',
            ProjectAlarm: 'Alarmes del projecte',
            AlarmDeleteTitle: 'Alerta',
            AlarmDeleteMessage: 'Aquesta alarma té {{activeEvents}} esdeveniments actius. \nSegur que la vols eliminar?',
        },
        SEVERITY: {
            alarm: 'Alarma',
            info: 'Informació',
            success: 'Èxito',
            warning: 'Atenció',
        },
        FIELD: {
            sound_pressure_level: 'Nivel Soroll',
            battery: 'Bateria',
            power_supply_status: "Font d'alimetació",
            spectrum_13_octave: 'Espectre 1/3 Octava',
            temperature: 'Temperatura Ext.',
            overload: 'Sobrecàrrega',
            underrange: 'Underrange',
            wifi_signal_level: 'Senyal WiFi',
            cellullar_signal_level: 'Senyal Cel·lular',
            free_storage: 'Espai Lliure',
        },
        REPORT: {
            General: 'Dades Generals',
            Device: 'Equip / Número de Sèrie',
            Address: 'Adreça',
            Comments: 'Comentaris',
            Register: 'Registre',
            GenerateReport: 'Generar Informe',
            Dates: 'Dates',
            GPS: 'Coordenades GPS',
            Photo: 'Fotografia',
            RegisteredParameters: 'Paràmetres Registrats',
            Graph: 'Gràfic',
            Observations: 'Observacions',
            CustomAlarm: 'Limit Personalitzat',
            Limits: 'Límits',
            Fields: 'Camps',
            Map: 'Mapa',
        },
        FILE: {
            Name: 'Nom',
            Status: 'Estat',
            UploadDate: 'Data de pujada',
            DropFile: 'Arrossegueu arxiu aquí',
            uploaded: 'Pujat',
            processing: 'Processant',
            processed: 'Processat',
        }
    },
};
