import { Data } from './data.model';
import { DataAggregation } from './dataAggregation.model';
import { DataTypeModelKey } from './dataType.model';

/**
 * Represent a bucket of data of the last 5 minutes
 */
export class DeviceAggregatedData {
    [key: string]: DeviceDataModel[];
}

export enum DeviceDataType {
    instant = 'instant',
    bucket = 'bucket',
    thirtyMinutes = '30_minutes',
    fiveMinutes = '5_minutes',
    status = 'status',
}

export class DeviceDataModel {
    id: string;
    deviceId: string;
    type: DataTypeModelKey;
    date: Date;
    data?: Data<any>[];
    aggregation: DataAggregation<any>;
}
