const ɵ0 = {
    noise_sensor: 'Noise Sensor',
    acoustic_limiter: 'Acoustic Limiter',
    GLOBAL: {
        SavedChanges: 'Saved Changes',
        CreatedSuccess: 'Created Succesfully',
    },
    USERS: {
        Users: 'Users',
        User: 'User',
        Name: 'Name',
        Email: 'Email',
        Admin: 'Admin',
        IsAdmin: 'Is Admin?',
        Password: 'Password',
        ADD: 'ADD',
        SAVE: 'SAVE',
        CANCEL: 'CANCEL',
        Create: 'Create',
        UserName: 'Namw',
        BasicInfo: 'Basic Info',
        Projects: 'Projects',
        Companies: 'Companies',
    },
    COMPANIES: {
        Companies: 'Companies',
        Name: 'Name',
        Street: 'Street',
        City: 'City',
        ZipCode: 'Zip Code',
        Country: 'Country',
        BasicInfo: 'Basic Info',
        Company: 'Company',
        CompanyName: 'Company Name',
        Address: 'Address',
        ADD: 'ADD',
        SAVE: 'SAVE',
        CANCEL: 'CANCEL',
        Projects: 'Projects',
        Create: 'Create',
    },
    DEVICE: {
        ALARM: {
            AlarmDeleteTitle: 'Alert',
            AlarmDeleteMessage: 'This alarm has {{activeEvents}} active events. \nAre you sure you want to delete it?',
        },
    },
    DEVICES: {
        ActiveProject: 'Active Project',
        Devices: 'Devices',
        Name: 'Name',
        Manufacturer: 'Manufacturer',
        Model: 'Model',
        BasicInfo: 'Basic Info',
        SerialNumber: 'Serial Number',
        DataFrequency: 'Data Frequency (Seconds)',
        DataFrequencyDevice: 'Device Data Frequency (Seconds): ',
        DataFrequencyCustom: 'Custom Data Frequency (Seconds): ',
        SimOrImei: 'SIM or IMEI',
        LastCalibrationDate: 'Last Calibration Date',
        DeviceType: 'Device Type',
        Protocol: 'Protocol',
        Picture: 'Picture',
        Comments: 'Comments',
        Location: 'Location',
        Active: 'Active',
        Device: 'Device',
        Activate: 'Activate',
        Deactivate: 'Deactivate',
        Remove: 'Remove',
        Create: 'Create',
        DeviceInfo: 'Device Information',
        FolderFTP: 'FTP Folder',
        Address: 'Address',
        Latitude: 'Latitude',
        Longitude: 'Longitude',
        NewDevice: 'New Device',
        ADD: 'ADD',
        SAVE: 'SAVE',
        CANCEL: 'CANCEL',
        Projects: 'Projects',
        ConfirmActivate: 'This device is already activate in project {{project}} with name {{deviceName}}.\nAre you sure you want to activate it?',
        Image: 'Image',
        DropFiles: 'Drop Image Here',
        RemoveImage: 'Remove Image',
    },
    PROJECTS: {
        Projects: 'Projects',
        Project: 'Project',
        BasicInfo: 'Basic Info',
        Name: 'Name',
        Timezone: 'Timezone',
        Create: 'Create',
        ProjectName: 'Project Name',
        Devices: 'Devices',
        Alarms: 'Alarms',
        ADD: 'ADD',
        SAVE: 'SAVE',
        CANCEL: 'CANCEL',
        Periods: 'Periods',
        ProjectPeriodName: 'Name',
        ProjectPeriodStart: 'Start Time',
        ProjectPeriodEnd: 'End Time',
        ProjectPeriodHours: 'Hours',
        ProjectPeriodMinutes: 'Minutes',
        ProjectPeriodNew: 'New Period',
        ProjectPeriodError: 'WARNING: End hour is before start hour. You are creating a period that span multiple days',
        Dateformat: 'Date Format',
        Example: 'Example',
        Archive: 'Archive',
        Unarchive: 'Unarchive',
    },
};
export const locale = {
    lang: 'en',
    data: ɵ0,
};
export { ɵ0 };
