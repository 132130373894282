import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService } from '../../../services/authentication.service';
import { LoginModel } from 'app/models/login.model';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { locale as catalan } from '../i18n/ca';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	model: LoginModel;
	error: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} fuseConfigService
     * @param {FormBuilder} formBuilder
     */
	constructor(
		private fuseConfigService: FuseConfigService,
		private formBuilder: FormBuilder,
		private authenticationService: AuthenticationService,
		private translate: TranslateService,
		private fuseTranslationLoaderService: FuseTranslationLoaderService,
		private router: Router,
	) {
		if (this.authenticationService.currentUser) {
			this.router.navigate(['/']);
		}
		
		this.fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);

		// Configure the layout
		this.fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	ngOnInit(): void {
		this.model = new LoginModel();
		this.model.remember = true;
		this.error = null;

		this.loginForm = this.formBuilder.group({
			email: [this.model.email, [Validators.required, Validators.email]],
			password: [this.model.password, Validators.required],
			remember: [this.model.remember],
		});
	}

	signin() {
		this.loading = true;
		this.error = null;
		this.authenticationService.login(this.loginForm.value).subscribe(
			(data) => {
				this.authenticationService.me().subscribe(
					(data) => {
						this.router.navigate(['/dashboard']);
						this.loading = false;
					},
					(error) => {
						this.error = this.translate.instant('login.signInError.genericMessage');
						if (error.status === 401) {
							this.error = this.translate.instant('login.signInError.invalidMessage');
						} else if (error._body && error._body.length) {
							let responseBody = JSON.parse(error._body);
							if (responseBody.message) {
								this.error = responseBody.message;
							}
						}

						this.loading = false;
					}
				)
			},
			(error) => {
				this.error = this.translate.instant('login.signInError.genericMessage');
				if (error.status === 401) {
					this.error = this.translate.instant('login.signInError.invalidMessage');
				} else if (error._body && error._body.length) {
					let responseBody = JSON.parse(error._body);
					if (responseBody.message) {
						this.error = responseBody.message;
					}
				}

				this.loading = false;
			});
	}
}
