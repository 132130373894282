import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DeviceEventComponent } from './device-event/device-event.component';
import { ProjectModel } from 'app/models/project.model';

@Component({
    selector: 'device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss'],
    animations: fuseAnimations,
})
export class DeviceComponent implements OnInit {
    device: ProjectDeviceModel;
    project: ProjectModel;
    @ViewChild("tabEvents", { static: true }) events: DeviceEventComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.device = this.route.snapshot.data.data;
    }

    onTabChange(event: MatTabChangeEvent) {
        if (event.index == 3) {
            this.events.loadData();
        }
        if (event.index == 1) {
            //This is a hack as sometimes the graph is not resized right
            setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 100);
        }
    }
}
