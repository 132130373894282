/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emma-check.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./emma-check.component";
var styles_EmmaCheckComponent = [i0.styles];
var RenderType_EmmaCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmmaCheckComponent, data: {} });
export { RenderType_EmmaCheckComponent as RenderType_EmmaCheckComponent };
function View_EmmaCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "done green-fg s-20 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EmmaCheckComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "clear red-fg s-20 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_EmmaCheckComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmmaCheckComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmmaCheckComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.active; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EmmaCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "emma-check", [], null, null, null, View_EmmaCheckComponent_0, RenderType_EmmaCheckComponent)), i1.ɵdid(1, 114688, null, 0, i5.EmmaCheckComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmmaCheckComponentNgFactory = i1.ɵccf("emma-check", i5.EmmaCheckComponent, View_EmmaCheckComponent_Host_0, { active: "active" }, {}, []);
export { EmmaCheckComponentNgFactory as EmmaCheckComponentNgFactory };
