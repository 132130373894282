import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectModel, ProjectPeriodModel } from 'app/models/project.model';
import { ProjectService } from 'app/services/api/project.service';
import { ToastrService } from 'ngx-toastr';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { ProjectPeriodAddEditComponent } from '../project-period-add-edit/project-period-add-edit.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-project-periods',
    templateUrl: './project-periods.component.html',
    styleUrls: ['./project-periods.component.scss'],
    animations: fuseAnimations,
})
export class ProjectPeriodsComponent implements OnInit {
    @Input()
    project: ProjectModel;
    dialogRef: any;

    savedChangesString: string;
    creationSuccessString: string;

    public displayedColumns = ['name', 'startTime', 'endTime'];

    constructor(
        private translation: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);

        translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
        translation.translateService.get('GLOBAL', { value: 'CreatedSuccess' }).subscribe(res => {
            this.creationSuccessString = res.CreatedSuccess;
        });
    }

    ngOnInit() {}

    addEditPeriod(period?: ProjectPeriodModel) {
        const data = period ? { period } : {};
        this.dialogRef = this._matDialog.open(ProjectPeriodAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe(response => {
            if (!response || !response.period) {
                return;
            }
            const responsePeriod = response.period;

            if (!period) {
                //Created from new, simply add
                if (!this.project.periods) {
                    this.project.periods = [];
                }
                this.project.periods.push(responsePeriod);
            } else {
                const index = this.project.periods.indexOf(period);
                this.project.periods[index] = responsePeriod;
            }
            this.save();
        });
    }

    save() {
        this.projectService.edit(this.project).subscribe(
            data => {
                this.project = data;
                this.toastr.success(this.savedChangesString);
            },
            error => {
                console.log(error);
                this.toastr.error('Error');
                // In case of resource deleted from another part, return to list
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/projects']);
                }
            },
        );
    }
}
