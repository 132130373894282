import { AuthGuard } from 'app/guards/auth.guard';
import { ProjectDeviceResolver } from 'app/resolvers/projectDevice.resolver';
import { DeviceComponent } from './device/device.component';
import { DevicesComponent } from './devices/devices.component';
const ɵ0 = ProjectDeviceResolver;
const routes = [
    {
        path: 'devices',
        component: DevicesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'device/:id',
        component: DeviceComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ɵ0,
        },
    },
];
export class DevicesModule {
}
export { ɵ0 };
