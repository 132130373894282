import { Component, Input, OnInit } from '@angular/core';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';

@Component({
    selector: 'app-device-info',
    templateUrl: './device-info.component.html',
    styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent implements OnInit {
    @Input()
    device: ProjectDeviceModel;

    constructor() { }

    ngOnInit() {
        console.log(this.device);
    }

    get validCoordinates() {
        return this.latitudeValid && this.longitudeValid;
    }

    get latitude() {
        return this.latitudeValid ? this.device.location.latitude : 0;
    }

    get longitude() {
        return this.longitudeValid ? this.device.location.longitude : 0;
    }

    get zoom() {
        return this.validCoordinates ? 14 : 0;
    }

    get showPicture() {
        return (this.device.picture && this.device.picture.length !== 0) || (this.device.baseDeviceData.picture && this.device.baseDeviceData.picture.length !== 0);
    }

    get picture() {
        if (this.device.picture && this.device.picture.length !== 0)
            return this.device.picture;
        else
            return this.device.baseDeviceData.picture;
    }

    private get latitudeValid() {
        return (
            this.device.location.latitude &&
            this.device.location.latitude >= -90 &&
            this.device.location.latitude <= 90
        );
    }

    private get longitudeValid() {
        return (
            this.device.location.longitude &&
            this.device.location.longitude >= -90 &&
            this.device.location.longitude <= 90
        );
    }
}
