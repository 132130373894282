import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'app/models/user.model';
import { AuthenticationService } from 'app/services/authentication.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectService } from 'app/services/api/project.service';
import { ProjectModel } from 'app/models/project.model';
import { ContextService } from 'app/services/context.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: UserModel;
    userProjects: ProjectModel[];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private projectService: ProjectService,
        public sessionService: ContextService,
        private router: Router,
    ) {
        this.user = authenticationService.currentUser;

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'es',
                title: 'Spanish',
                flag: 'es',
            },
            {
                id: 'ca',
                title: 'Català',
                flag: 'ca',
            },
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        // Set the selected language from default languages
        if (this.sessionService.preferredLanguage) {
            this._translateService.use(this.sessionService.preferredLanguage);
        }
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

        // Update user projects if the user is correctly logged in
        if (this.user) {
            this.updateToolBarProjects();
            this.authenticationService.me().subscribe();
        }

        // Subscribe to changes in the user and update users projects accordingly
        this.authenticationService.onCurrentUserChange.subscribe(currentUser => {
            this.user = currentUser;
            if (currentUser) {
                this.updateToolBarProjects();
            }
        });

        // In case of a forced reload, simply reload all the user data and the rest will 
        // be handled by the already in place callbacks
        this.sessionService.onForceDataRefresh.subscribe(() => {
            this.authenticationService.me().subscribe();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Save as preference
        this.sessionService.preferredLanguage = lang.id;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    selectedProject() {
        // force content re-rendering
    }

    private updateToolBarProjects() {
        const currentProject = this.sessionService.currentProject;

        if (this.user.isAdmin) {
            this.projectService.getAll().subscribe(projects => {
                this.userProjects = projects.filter(p => !p.archived);

                // If no project is selected, or current selection does not exists
                // just choose the first one
                if (!currentProject || !this.userProjects.some(p => p.id === currentProject)) {
                    this.sessionService.currentProject = this.userProjects[0].id;
                }
            });
        } else {
            this.userProjects = this.user.projectsData;

            // If no project is selected, or current selection does not exists
            // just choose the first one
            if (!currentProject || !this.userProjects.some(p => p.id === currentProject)) {
                this.sessionService.currentProject = this.userProjects[0].id;
            }
        }
    }
}
