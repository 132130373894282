import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { CompanyModel } from 'app/models/company.model';

@Injectable()
export class CompanyService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'company';
    }

    get(id: string): Observable<CompanyModel> {
        return this.http.get<CompanyModel>(this.getUrl(id));
    }

    getAll(company?: object): Observable<CompanyModel[]> {
        return this.http.get<CompanyModel[]>(this.getUrl(''), company);
    }

    create(company: CompanyModel): Observable<CompanyModel> {
        return this.http.post<CompanyModel>(this.getUrl(''), company);
    }

    edit(company: CompanyModel): Observable<CompanyModel> {
        return this.http.put<CompanyModel>(this.getUrl(''), company);
    }

    delete(id: string): Observable<CompanyModel> {
        return this.http.delete<CompanyModel>(this.getUrl(String(id)));
    }
}
