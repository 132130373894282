export const locale = {
    lang: 'es',
    data: {
        TAB: {
            Status: 'Estado',
            Data: 'Datos',
            Alarms: 'Alarmas',
            Events: 'Eventos',
            Info: 'Info',
            Report: 'Informe',
            Files: 'Archivos',
        },
        DEVICES: {
            ID: 'ID',
            Name: 'Nombre',
            Active: 'Activo',
            LastData: 'Últimos datos',
            Address: 'Dirección',
            Devices: 'Dispositivos',
        },
        DEVICE: {
            NoiseLevel: 'Nivel Ruido',
            Limit: 'Limite:',
            Info: 'Información',
            Name: 'Nombre',
            SerialNumber: 'Número de Série',
            Location: 'Localización',
            Latitude: 'Latitud',
            Longitude: 'Longitud',
            NoGPSData: 'Datos GPS inváldios',
            Comments: 'Comentarios',
            Last15Minutes: 'Últimos 15 Minutos',
            LastHour: 'Última Hora',
            Last12Hours: 'Últimas 12 Horas',
            Last24Hours: 'Últimas 24 Horas',
            StartDate: 'Fecha de Inicio',
            EndDate: 'Fecha Final',
            Data: 'Datos',
            DateRange: 'Intervalo',
            DataAggregation: 'Media',
            NoAggregation: 'Valors Puntuales',
            Minutes5: '5 Minutos',
            LiveUpdate: 'Actualización en Vivo',
            ShowL10: 'Mostrar L10',
            ShowL90: 'Mostrar L90',
            Custom: 'Custom',
            Download: 'Descargar Datos CSV',
            Export: 'Exportar CSV',
            StartTime: 'Hora Inicio',
            EndTime: 'Hora Fin',
            Image: 'Imagen',
            DropFiles: 'Arrastre la imagen aquí',
            RemoveImage: 'Borra Imagen',
            Picture: 'Imagen',
            BaseName: 'Dispositivo',
            UploadFile: 'Subir Archivo',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Nombre',
            Description: 'Descripción',
            TimeWindow: 'Ventana de tiempo',
            Samples: 'Muestras',
            Threshold: 'Umbral',
            Limit: 'Límite',
            Severity: 'Severidad',
            Field: 'Campo',
            Remove: 'Eliminar',
            time_window_instant: 'Instant',
            time_window_rolling_5: 'Promedio continuo de 5 minutos',
            time_window_rolling_30: 'Promedio continuo de 30 minutos',
            time_window_bucket_5: 'Bucket 5 minutos',
            threshold_cross_over: 'Cruzar ENCIMA',
            threshold_cross_below: 'Cruzar ABAJO',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCELAR',
            AlarmDetail: 'Detalle de alarma',
            NewAlarm: 'Nueva Alarma',
            Notify: 'Notificar',
            Silent: 'Silenciosa',
            NewNotificationItem: 'email/teléfono',
            SimOrImei: 'SIM o IMEI',
            LastCalibrationDate: 'Fecha de última calibración',
            ProjectAlarm: 'Alarmas de proyecto',
            AlarmDeleteTitle: 'Alerta',
            AlarmDeleteMessage: 'Esta alarma tiene {{activeEvents}} eventos activos. \nSeguro que la quieres eliminar?',
        },
        SEVERITY: {
            alarm: 'Alarma',
            info: 'Información',
            success: 'Éxito',
            warning: 'Advertencia',
        },
        FIELD: {
            sound_pressure_level: 'Nivel Ruido',
            battery: 'Batería',
            power_supply_status: 'Fuente Alimentación',
            spectrum_13_octave: 'Espectro 1/3 Octava',
            temperature: 'Temperatura Ext.',
            overload: 'Sobrecarga',
            underrange: 'Underrange',
            wifi_signal_level: 'Señal WiFi',
            cellullar_signal_level: 'Señal Cellular',
            free_storage: 'Espacio Libre',
        },
        REPORT: {
            General: 'Datos Generales',
            Device: 'Equipo / Número de Serie',
            Address: 'Dirección',
            Comments: 'Comentarios',
            Register: 'Registro',
            GenerateReport: 'Generar Informe',
            Dates: 'Fechas',
            GPS: 'Coordenadas GPS',
            Photo: 'Foto',
            RegisteredParameters: 'Parámetros Registrados',
            Graph: 'Gráfica',
            Observations: 'Observaciones',
            CustomAlarm: 'Límite Personalizado',
            Limits: 'Límites',
            Fields: 'Campos',
            Map: 'Mapa',
        },
        FILE: {
            Name: 'Nombre',
            UploadDate: 'Fecha de Subida',
            Status: 'Estado',
            DropFile: 'Arrastre el archivo aquí',
            uploaded: 'Subido',
            processing: 'Procesando',
            processed: 'Procesado',
        }
    },
};
