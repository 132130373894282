<div id="company" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/admin/companies'" *ngIf="pageType == 'edit'"><mat-icon>arrow_back</mat-icon></button>

        <h1 *ngIf="pageType == 'new'">{{ "COMPANIES.Company" | translate }} - {{ "COMPANIES.Create" | translate }}</h1>
        <h1 *ngIf="pageType == 'edit'">{{ "COMPANIES.Company" | translate }} - {{ company.name }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-tab-group [backgroundColor]="'header'">
                <mat-tab label="{{ 'COMPANIES.BasicInfo' | translate }}">
                    <div class="content">
                        <form name="productForm" [formGroup]="companyForm" class="company">
                            <div class="section">
                                <h2 class="section__title">
                                    <mat-icon>business</mat-icon>
                                    <div>{{ "COMPANIES.Company" | translate }}</div>
                                </h2>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "COMPANIES.CompanyName" | translate }}</mat-label>
                                    <input matInput name="name" formControlName="name" placeholder="{{ 'COMPANIES.CompanyName' | translate }}" required />
                                </mat-form-field>
                            </div>

                            <div class="section">
                                <h2 class="section__title">
                                    <mat-icon>mail_circle</mat-icon>
                                    <div>{{ "COMPANIES.Address" | translate }}</div>
                                </h2>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "COMPANIES.Street" | translate }}</mat-label>
                                    <input matInput name="street" formControlName="street" placeholder="{{ 'COMPANIES.Street' | translate }}" required />
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "COMPANIES.City" | translate }}</mat-label>
                                    <input matInput name="city" formControlName="city" placeholder="{{ 'COMPANIES.City' | translate }}" required />
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "COMPANIES.ZipCode" | translate }}</mat-label>
                                    <input matInput name="zipCode" formControlName="zipCode" placeholder="{{ 'COMPANIES.ZipCode' | translate }}" required />
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "COMPANIES.Country" | translate }}</mat-label>
                                    <input matInput name="country" formControlName="country" placeholder="{{ 'COMPANIES.Country' | translate }}" required />
                                </mat-form-field>
                            </div>

                            <div class="form-action">
                                <button mat-raised-button class="button-save" color="primary" [disabled]="companyForm.invalid" *ngIf="pageType == 'new'" (click)="add()">
                                    {{ "COMPANIES.ADD" | translate }}
                                </button>
                                <button mat-raised-button (click)="cancel()" *ngIf="pageType == 'new'">{{ "COMPANIES.CANCEL" | translate }}</button>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="pageType == 'edit'" label="{{ 'COMPANIES.Projects' | translate }}">
                    <div class="project-content" fusePerfectScrollbar><app-project-list (rowSelect)="onProjectCheck($event)"></app-project-list></div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
