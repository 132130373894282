/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./device-type-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./device-type-icon.component";
var styles_DeviceTypeIconComponent = [i0.styles];
var RenderType_DeviceTypeIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeviceTypeIconComponent, data: {} });
export { RenderType_DeviceTypeIconComponent as RenderType_DeviceTypeIconComponent };
function View_DeviceTypeIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["settings_input_antenna"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DeviceTypeIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"], ["size", "lg"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.limiterIcon; var currVal_3 = "lg"; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DeviceTypeIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceTypeIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeviceTypeIconComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.deviceType == "noise_sensor"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.deviceType == "acoustic_limiter"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DeviceTypeIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "emma-device-type-icon", [], null, null, null, View_DeviceTypeIconComponent_0, RenderType_DeviceTypeIconComponent)), i1.ɵdid(1, 114688, null, 0, i8.DeviceTypeIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeviceTypeIconComponentNgFactory = i1.ɵccf("emma-device-type-icon", i8.DeviceTypeIconComponent, View_DeviceTypeIconComponent_Host_0, { deviceType: "deviceType" }, {}, []);
export { DeviceTypeIconComponentNgFactory as DeviceTypeIconComponentNgFactory };
