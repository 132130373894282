import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectModel } from 'app/models/project.model';
import { ProjectService } from 'app/services/api/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    animations: fuseAnimations,
})
export class ProjectListComponent implements OnInit {
    public projects: ProjectModel[] = [];
    public displayedColumns = ['checkbox', 'name', 'archive'];
    private checkboxes: any;
    private _selected: string[] = [];
    savedChangesString: string;

    set selectedProjects(projects: string[]) {
        if (projects) {
            this._selected = projects;
        }
    }

    @Output()
    rowClick = new EventEmitter<ProjectModel>();

    @Output()
    rowSelect = new EventEmitter<ProjectModel>();

    constructor(
        private projectService: ProjectService,
        private toastr: ToastrService,
        private translation: FuseTranslationLoaderService,
    ) {
        translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.projectService.getAll().subscribe(
            data => {
                this.checkboxes = [];
                this.projects = data;

                data.map(project => {
                    this.checkboxes[project.id] = false;
                });

                // If thre are pre selected project, save that
                this._selected.forEach(projectId => {
                    this.checkboxes[projectId] = true;
                });
            },
            error => {
                console.log(error);
            },
        );
    }

    public checkedProjects(): ProjectModel[] {
        const objects = [];
        this.projects.forEach(project => {
            if (this.checkboxes[project.id]) {
                objects.push(project);
            }
        });
        return objects;
    }

    public refresh() {
        this.loadData();
    }

    onSelectedChange(project: ProjectModel) {
        this.rowSelect.emit(Object.assign({}, project));
    }

    onClick(project: ProjectModel) {
        this.rowClick.emit(Object.assign({}, project));
    }

    onArchiveToggle(project: ProjectModel) {
        project.archived = !project.archived;
        this.projectService.edit(project).subscribe(
            data => {
                // Show a success toast
                this.toastr.success(this.savedChangesString);
            },
            error => {
                this.toastr.error(error);
                console.log(error);
            }
        );
    }
}
