import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectPeriodModel, TimeModel } from 'app/models/project.model';
import * as moment from 'moment';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { ProjectDeviceAddEditComponent } from '../project-device/project-device-add-edit/project-device-add-edit.component';

enum PeriodAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
}

@Component({
    selector: 'app-project-period-add-edit',
    templateUrl: './project-period-add-edit.component.html',
    styleUrls: ['./project-period-add-edit.component.scss'],
    providers: [DecimalPipe],
})
export class ProjectPeriodAddEditComponent implements OnInit {
    public periodForm: FormGroup;
    public timeIntervals: string[];
    action: PeriodAction;
    private period: ProjectPeriodModel;
    warnUser = false;

    constructor(
        private formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public matDialogRef: MatDialogRef<ProjectDeviceAddEditComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private numberPipe: DecimalPipe,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);

        if (_data.period) {
            this.action = PeriodAction.EDIT;
            this.period = _data.period;
        } else {
            this.action = PeriodAction.NEW;
        }
    }

    // Validate that a value is before another
    ngOnInit() {
        this.generateIntervals();
        this.createForm();
    }

    get formPeriod() {
        if (!this.periodForm.valid) {
            return null;
        }

        const startTime = moment(this.periodForm.get('startTime').value, 'HH:mm');
        const endTime = moment(this.periodForm.get('endTime').value, 'HH:mm');

        const period = new ProjectPeriodModel();
        period.name = this.periodForm.get('name').value;
        period.startTime = { hour: startTime.hour(), minute: startTime.minute() };
        period.endTime = { hour: endTime.hour(), minute: endTime.minute() };

        return period;
    }

    public save() {
        this.matDialogRef.close({
            period: this.formPeriod,
        });
    }

    public cancel() {
        this.matDialogRef.close();
    }

    private createForm() {
        this.periodForm = this.formBuilder.group({
            name: [this.period ? this.period.name : null, [Validators.required]],
            startTime: [
                this.period ? this.timeString(this.period.startTime) : null,
                [Validators.required],
            ],
            endTime: [
                this.period ? this.timeString(this.period.endTime) : null,
                [Validators.required],
            ],
        });
    }

    private generateIntervals() {
        const minutesInterval = 5;
        const date = new Date(1970, 0, 1, 0, 0, 0, 0);
        const series = [];
        while (date.getDate() == 1) {
            series.push(moment(date).format('HH:mm'));
            date.setMinutes(date.getMinutes() + minutesInterval);
        }
        this.timeIntervals = series;
    }

    private timeString(time: TimeModel) {
        return `${this.numberPipe.transform(time.hour, '2.0-0')}:${this.numberPipe.transform(
            time.minute,
            '2.0-0',
        )}`;
    }

    public checkPeriods() {
        const startTime = this.periodForm.get('startTime');
        const endTime = this.periodForm.get('endTime');

        if (!startTime || !endTime) {
            return;
        }
        if (!startTime.value || !endTime.value) {
            return;
        }

        let endTimeDate = moment(endTime.value, 'HH:mm');
        let startTimeDate = moment(startTime.value, 'HH:mm');

        const isBefore = endTimeDate.isBefore(startTimeDate);
        this.warnUser = isBefore;
    }
}
