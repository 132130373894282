export const locale = {
    lang: 'ca',
	data: {
		"login": {
			"title": "Iniciar sesión en Admin",
			"email": {
				"label": "Email",
				"validation": {
					"required": "Email es un campo obligatorio",
					"emailFormat": "Formato inválido"
				}
			},
			"password": {
				"label": "Contraseña",
				"validation": {
					"required": "Contraseña es un campo obligatorio"
				}
			},
			"staySignedId": "Permanecer conectado",
			"forgotPassword": "¿Has olvidado tu contraseña ?",
			"enterEmailToReset": "Ingresa tu correo para restablecer contraseña",
			"send": "Enviar",
			"cancel": "Cancelar",
			"signInButton": "Ingresar",
			"contentTitle": "Bienvenido al Sitio de Industrias JLC",
			"contentMessage": "Sitio Web de Industrias JLC con más de 65 años ofreciéndote los mejores productos para la limpieza de tu hogar. Conoce nuestra historia, productos y tips.",
			"passwordRecoveryConfirmation": {
				"message": "La instrucción de recuperación de contraseña ha sido enviada a su correo electrónico."
			},
			"passwordRecoveryError": {
				"message": "Error en la petición de recuperación de contraseña."
			},
			"signInError": {
				"title": "Error al  ingresar",
				"genericMessage": "Prueba otra vez más tarde",
				"invalidMessage": "Usuario o contraseña no son validos"
			}
		},
    }
};
