const ɵ0 = {
    EVENTS: {
        Events: 'Esdeveniments',
        Severity: 'Severitat',
        Type: 'Tipus',
        Device: 'Dispositiu',
        StartTime: 'Hora d\'inici',
        EndTime: 'Hora final',
        Description: 'Descripció',
        Remove: 'Eliminar',
        ADD: 'Afegeix',
        SAVE: 'GUARDAR',
        CANCEL: 'cancel·lar',
        EventType: 'Tipus d\'esdeveniment: ',
        NewEvent: 'Nuevo Evento',
        EventDetail: 'Detall de l’esdeveniment',
        EventTimeError: 'La data de finalització ha de ser després de la data d\'inici'
    },
    SEVERITY: {
        alarm: 'Alarma',
        info: 'Informació',
        success: 'Èxit',
        warning: 'Atenció',
    },
    TYPE: {
        device_alarm_off: 'Alarma Desactivada',
        device_alarm_on: 'Alarma Activada',
        device_added: 'Dispositiu Afegit',
        device_offline: 'Dispositiu fora de línia',
        device_online: 'Dispositiu en línia',
        device_removed: 'Dispositiu eliminat',
        user_defined: 'Usuari definit',
        device_warning_off: 'Advertencia Desactivada',
        device_warning_on: 'Advertencia Activada',
    },
};
export const locale = {
    lang: 'ca',
    data: ɵ0,
};
export { ɵ0 };
