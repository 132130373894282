import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EventDataModel, EventModelSeverity, EventModelType } from 'app/models/event.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ContextService } from 'app/services/context.service';
import * as moment from 'moment';
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';

enum EventAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
    READ = 'READ',
}

const dateTimeOrderValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const startDateControl = control.get('startDate');
    const startTimeControl = control.get('startTime');
    const endDateControl = control.get('endDate');
    const endTimeControl = control.get('endTime');

    if (
        !startTimeControl.value ||
        !endTimeControl.value ||
        !startDateControl.value ||
        !endDateControl.value
    ) {
        return null;
    }

    const startDate = combineDateTime(startDateControl.value, startTimeControl.value);
    const endDate = combineDateTime(endDateControl.value, endTimeControl.value);

    const isAfter = endDate.isSameOrAfter(startDate);
    if (!isAfter) {
        return { timeOrderError: true };
    }
    return null;
};

function combineDateTime(date: Date, timeString: string): moment.Moment {
    let time = moment(timeString, 'HH:mm');
    let result = moment(date);
    return result.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
    });
}

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
    public eventForm: FormGroup;
    public availableDevices: ProjectDeviceModel[];
    action: EventAction;
    event: EventDataModel;
    severityValues = [
        EventModelSeverity.EventModelSeverityInfo,
        EventModelSeverity.EventModelSeveritySuccess,
        EventModelSeverity.EventModelSeverityWarning,
        EventModelSeverity.EventModelSeverityAlarm,
    ];

    constructor(
        private formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<EventComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private deviceService: ProjectDeviceService,
        private contextService: ContextService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
        if (_data && _data.event) {
            this.event = _data.event;
            this.action =
                this.event.type === EventModelType.EventModelTypeUserDefined
                    ? EventAction.EDIT
                    : EventAction.READ;
        } else {
            this.action = EventAction.NEW;
            this.event = new EventDataModel();
            this.event.type = EventModelType.EventModelTypeUserDefined;
        }

        this.createForm();
    }
    ngOnInit() {
        this.loadDevices();
    }

    private createForm(): any {
        this.eventForm = this.formBuilder.group(
            {
                severity: [this.event.severity, [Validators.required]],
                startDate: [
                    this.event.startTime ? this.event.startTime : new Date(),
                    [Validators.required],
                ],
                startTime: [moment(this.event.startTime).format('HH:mm'), [Validators.required]],
                endDate: [
                    this.event.endTime ? this.event.endTime : new Date(),
                    [Validators.required],
                ],
                endTime: [moment(this.event.endTime).format('HH:mm'), [Validators.required]],
                deviceId: [this.event.deviceId],
                description: [this.event.description],
            },
            { validators: [dateTimeOrderValidator] },
        );
    }

    private loadDevices(): any {
        const projectId = this.contextService.currentProject;
        this.deviceService.getAll(projectId).subscribe(
            data => {
                this.availableDevices = data;
            },
            error => {
                console.log(error);
            },
        );
    }

    private updateFormEvent() {
        const formValues = this.eventForm.value;
        this.event.severity = formValues.severity;
        this.event.deviceId = formValues.deviceId;
        this.event.startTime = combineDateTime(formValues.startDate, formValues.startTime).toDate();
        this.event.endTime = combineDateTime(formValues.endDate, formValues.endTime).toDate();
        this.event.description = formValues.description;
    }

    public save() {
        this.updateFormEvent();
        this.matDialogRef.close({
            event: this.event,
        });
    }

    public cancel() {
        this.matDialogRef.close();
    }
}
