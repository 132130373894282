/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-alarm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/alarm-list/alarm-list.component.ngfactory";
import * as i3 from "../../../../components/alarm-list/alarm-list.component";
import * as i4 from "../../../../../@fuse/services/translation-loader.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "ngx-toastr";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./project-alarm.component";
import * as i15 from "../../../../services/api/projectAlarm.service";
import * as i16 from "../../../../services/api/alarm.service";
var styles_ProjectAlarmComponent = [i0.styles];
var RenderType_ProjectAlarmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectAlarmComponent, data: {} });
export { RenderType_ProjectAlarmComponent as RenderType_ProjectAlarmComponent };
export function View_ProjectAlarmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-list", [], null, [[null, "rowClick"], [null, "deleteClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowClick" === en)) {
        var pd_0 = (_co.onAlarmClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteClick" === en)) {
        var pd_1 = (_co.onAlarmDelete($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AlarmListComponent_0, i2.RenderType_AlarmListComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlarmListComponent, [i4.FuseTranslationLoaderService, i5.MatDialog, i6.ToastrService, i7.Router], { alarms: [0, "alarms"] }, { rowClick: "rowClick", deleteClick: "deleteClick" }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "add-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "button", [["aria-label", "add alarm"], ["id", "add-alarm-button"], ["mat-fab", ""]], [[24, "@animate", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAlarm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), i1.ɵpod(5, { delay: 0, scale: 1 }), i1.ɵpod(6, { value: 0, params: 1 }), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle_outline"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alarms; _ck(_v, 1, 0, currVal_0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, "*", _ck(_v, 5, 0, "300ms", ".2")); var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 8).inline; var currVal_5 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_4, currVal_5); }); }
export function View_ProjectAlarmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-alarm", [], null, null, null, View_ProjectAlarmComponent_0, RenderType_ProjectAlarmComponent)), i1.ɵdid(1, 114688, null, 0, i14.ProjectAlarmComponent, [i15.ProjectAlarmService, i16.AlarmService, i4.FuseTranslationLoaderService, i5.MatDialog, i6.ToastrService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectAlarmComponentNgFactory = i1.ɵccf("app-project-alarm", i14.ProjectAlarmComponent, View_ProjectAlarmComponent_Host_0, { project: "project" }, {}, []);
export { ProjectAlarmComponentNgFactory as ProjectAlarmComponentNgFactory };
