import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ManufacturerModel } from 'app/models/manufacturer.model';
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable()
export class ManufacturerService extends ApiService {

	constructor(protected http: HttpClient) {
		super(http);
		this.apiEndpoint = 'manufacturer';
	}

	get(id: string): Observable<ManufacturerModel> {
		return this.http.get<ManufacturerModel>(this.getUrl(id));
	}

	getAll(parameters?: object): Observable<ManufacturerModel[]> {
		return this.http.get<ManufacturerModel[]>(this.getUrl(''), parameters);
	}
}
