import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectViewModel } from 'app/models/projectView.model';
import { ProjectViewService } from 'app/services/api/projectView.service';
import { ContextService } from 'app/services/context.service';
import { ProjectViewPreferenceService } from 'app/services/projectViewPreference.service';
import { Subscription } from 'rxjs';
import { locale as catalan } from './../i18n/ca';
import { locale as english } from './../i18n/en';
import { locale as spanish } from './../i18n/es';

@Component({
    selector: 'app-dashboard-sidebar',
    templateUrl: './dashboard-sidebar.component.html',
    styleUrls: ['./dashboard-sidebar.component.scss'],
})
export class DashboardSidebarComponent implements OnInit, OnDestroy {
    public views: ProjectViewModel[];
    private subscription: Subscription;
    private currentProject: string = this.contextService.currentProject;

    selectedView: string;
    editingView: ProjectViewModel = null;
    editViewName: string;

    newViewName: string;
    addingView: boolean;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    confirmString: string;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matDialog: MatDialog,
        private projectViewPreference: ProjectViewPreferenceService,
        private projectViewService: ProjectViewService,
        private contextService: ContextService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
        _fuseTranslationLoaderService.translateService
            .get('SIDEBAR', { value: 'Delete' })
            .subscribe(res => {
                this.confirmString = res.Delete;
            });
    }

    ngOnInit() {
        this.loadData();

        this.selectedView = this.projectViewPreference.savedView(this.currentProject);
        this.subscription = this.projectViewPreference.onPreferredProjectViewChange.subscribe(
            () => (this.selectedView = this.projectViewPreference.savedView(this.currentProject)),
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private loadData() {
        this.projectViewService.getAll(this.currentProject).subscribe(
            views => (this.views = views),
            err => console.log(err),
        );
    }

    /**
     * Editing section and management
     */
    isEditView(view: ProjectViewModel): boolean {
        if (!this.editingView) {
            return false;
        }
        return this.editingView.name === view.name;
    }

    deleteView(view: ProjectViewModel, event: any) {
        event.stopPropagation();

        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.confirmString;

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.projectViewService.delete(this.currentProject, view.id).subscribe(
                    () => this.loadData(),
                    err => console.log(err),
                );
            }
            this.confirmDialogRef = null;
        });
    }

    editView(view: ProjectViewModel, event: any) {
        event.stopPropagation();
        this.editingView = view;
        this.editViewName = view.name;
    }

    confirmEdit(view: ProjectViewModel, event: any) {
        event.stopPropagation();

        this.editingView.name = this.editViewName;
        this.projectViewService.edit(this.currentProject, this.editingView).subscribe(
            () => this.loadData(),
            err => console.log(err),
        );
        this.editingView = null;
        this.editViewName = null;
    }

    cancelEdit(view: ProjectViewModel, event: any) {
        event.stopPropagation();
        this.editingView = null;
        this.editViewName = null;
    }

    /**
     * Add management
     */

    addView() {
        this.addingView = true;
        this.newViewName = '';
    }

    confirmAdd() {
        const newView = new ProjectViewModel();
        newView.name = this.newViewName;
        this.projectViewService.create(this.currentProject, newView).subscribe(
            () => this.loadData(),
            err => console.log(err),
        );
        this.addingView = false;
        this.newViewName = null;
    }

    cancelAdd() {
        this.addingView = false;
        this.newViewName = null;
    }

    /**
     * Selection management
     */

    selectView(view: ProjectViewModel) {
        this.projectViewPreference.saveView(this.currentProject, view ? view.id : null);
    }

    isSelectedView(view: ProjectViewModel) {
        return view.id === this.selectedView;
    }
}
