<div class="emma-card__header">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <a *ngIf="!contextService.isKioskModeEnabled" [routerLink]="'/device/' + device.id">{{ device?.deviceName }}</a> 
            <span *ngIf="contextService.isKioskModeEnabled">{{ device?.deviceName }}</span> 
            - {{'COMPONENT.NoiseLevel' | translate}}
        </div>
        <app-device-view-list *ngIf="editGraphViews" [device]="device" [dataType]="dataType"></app-device-view-list>
    </div>
</div>

<div class="emma-card__content" *ngIf="device.lastData">
    <small>{{ device?.lastData?.soundPressureLevel?.timestamp | date: "medium" }}</small>
    <h2 [ngClass]="{'orange-fg': statusValue === 'warning', 'red-fg': statusValue==='alarm'}">
        {{ device?.lastData?.soundPressureLevel?.value | number: "1.1-1" }} dBA</h2>
    <div *ngIf="showAvg5">
        <span
            [ngClass]="{'text-semibold': true, 'orange-fg': statusAvg5 === 'warning', 'red-fg': statusAvg5==='alarm'}">
            {{ device?.dataAggregation?.soundPressureLevel?.average | number: "1.1-1" }} dBA </span>
        {{'COMPONENT.Average5' | translate}}

    </div>
    <div *ngIf="showAvg30">
        <span
            [ngClass]="{'text-semibold': true, 'orange-fg': statusAvg30 === 'warning', 'red-fg': statusAvg30==='alarm'}">
            {{ device?.dataAggregation30?.soundPressureLevel?.average | number: "1.1-1" }} dBA
        </span>
        {{'COMPONENT.Average30' | translate}}
    </div>
</div>

<div class="emma-card__content emma-card__content--center" *ngIf="!device.lastData">
    <small>No data yet</small>
    <div>
        <b>
            {{'COMPONENT.DeviceDisconnected' | translate}}
        </b>
    </div>
</div>

<div class="emma-card__footer-full-width" *ngIf="device.lastData">
    <highcharts-chart *ngIf="chart.visible" [Highcharts]="Highcharts" [constructorType]="chart.constructor" [options]="chart.options"
    [(update)]="chart.update" style="width: 100%; display: block;"></highcharts-chart>
</div>

<div *ngIf="!device.connected && device.lastData" class="emma-card__offline">
    {{ 'COMPONENT.DeviceOffline' | translate }}
</div>