import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveDeviceModel } from 'app/models/activeDevice.model';
import { DeviceModel } from 'app/models/device.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class DeviceService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'device';
    }

    get(id: string): Observable<DeviceModel> {
        return this.http.get<DeviceModel>(this.getUrl(id));
    }

    getAll(parameters?: object): Observable<DeviceModel[]> {
        return this.http.get<DeviceModel[]>(this.getUrl(''), parameters);
    }

    geActivated(id: string): Observable<ActiveDeviceModel> {
        const url = `${id}/activated`;
        return this.http.get<ActiveDeviceModel>(this.getUrl(url));
    }

    create(parameters: DeviceModel): Observable<DeviceModel> {
        return this.http.post<DeviceModel>(this.getUrl(''), parameters);
    }

    edit(parameters: DeviceModel): Observable<DeviceModel> {
        return this.http.put<DeviceModel>(this.getUrl(''), parameters);
    }

    delete(id: string): Observable<DeviceModel> {
        return this.http.delete<DeviceModel>(this.getUrl(String(id)));
    }

    addImage(id: string, image: any) {
        const url = `${id}/image`;
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        return this.http.post(this.getUrl(url), formData);
    }

    removeImage(id: string) {
        const url = `${id}/image`;
        return this.http.delete(this.getUrl(url));
    }
}
