import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceAggregatedData, DeviceDataModel, DeviceDataType } from 'app/models/deviceData.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DataService } from 'app/services/api/data.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, timer } from 'rxjs';
import * as Highcharts from 'highcharts';
import { chartBandSpectraCard } from 'app/chart-config/chart-band-spectra-card';
import { DeviceMqttService } from 'app/services/api/device.mqtt.service';
import { IMqttMessage } from "ngx-mqtt";
import { ContextService } from 'app/services/context.service';
import { chartBandSpectraSimple } from 'app/chart-config/chart-band-spectra-simple';

@Component({
    selector: 'device-band-spectra',
    templateUrl: './device-band-spectra.component.html',
    styleUrls: ['./device-band-spectra.component.scss'],
})
export class DeviceBandSpectra implements OnInit, OnDestroy {
    @Input() device: ProjectDeviceModel;
    @Input() editGraphViews = false;

    dataType = DataTypeModelKey.spectrum13Octave;
    loadingData = false;
    deviceData: DeviceDataModel[];
    sensorGraphData: any = null;
    Highcharts: typeof Highcharts = Highcharts;
    chart = JSON.parse(JSON.stringify(chartBandSpectraCard));
    chartFull = JSON.parse(JSON.stringify(chartBandSpectraCard));
    chartSimple = JSON.parse(JSON.stringify(chartBandSpectraSimple));
    isKioskMode = false;
    chartRef;

    subsInstant: Subscription;
    subsBucket: Subscription;

    constructor(
        private readonly dataService: DataService,
        private readonly projectDeviceService: ProjectDeviceService,
        private readonly deviceMqtt: DeviceMqttService,
        private toastr: ToastrService,
        public readonly contextService: ContextService,
    ) { }

    ngOnInit() {
        this.refreshData();
        this.subscribeToTopics();

        this.contextService.onKioskModeChange.subscribe(isKioskMode => {
            this.isKioskMode = isKioskMode;
            if (this.isKioskMode) {
                this.chart.options.chart.height = '85%';
            } else {
                this.chart.options.chart.height = 280;
            }
            this.chart.update = true;

            for (let i = 250; i <= 2500; i += 250) {
                setTimeout(() => {
                    this.chart.update = true;
                    window.dispatchEvent(new Event('resize'));
                }, i);
            }
        });
    }

    ngOnDestroy(): void {
        this.subsInstant.unsubscribe();
        this.subsBucket.unsubscribe();
    }

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chartRef = chart;
    };

    private subscribeToTopics() {
        this.subsInstant = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.instant, DataTypeModelKey.spectrum13Octave)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.lastData.spectrum13Octave.value = item.value;
                this.device.lastData.spectrum13Octave.timestamp = item.timestamp;
            });

        this.subsBucket = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.bucket, DataTypeModelKey.spectrum13Octave)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());

                this.deviceData[0] = item;
                this.createSensorData();
            })
    }

    private refreshData() {
        this.fetchDevice();
    }

    private fetchDevice() {
        if (this.loadingData) {
            return;
        }
        this.loadingData = true;

        // Fetch device
        this.projectDeviceService.get(this.device.id).subscribe(
            (data: ProjectDeviceModel) => {
                this.device = data;
                this.createSensorData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );

        // Fetch last bucket of data
        this.dataService
            .getDeviceDataSample(this.device.id, [DataTypeModelKey.spectrum13Octave], 1, true)
            .subscribe(
                (data: DeviceAggregatedData) => {
                    this.deviceData = data[DataTypeModelKey.spectrum13Octave];
                    this.createSensorData();
                    this.loadingData = false;
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                    this.loadingData = false;
                },
            );
    }

    private createSensorData() {
        if (!this.deviceData)
            return;

        //Process data to set it ready for drawing the graph
        //On some devices the average is not calculated, so we don't show it on the graph
        if (this.deviceData && this.deviceData[0].aggregation.average[0] > 0) {
            if (this.chart.options.series.length < 2) {
                this.chart.options = this.chartFull.options;
            }
            this.chart.options.series[0].data = [];
            this.chart.options.series[1].data = [];

            if (this.deviceData && this.deviceData[0].aggregation.average) {
                this.deviceData[0].aggregation.average.forEach((data, i) => {
                    if (i > 30) {
                        this.chart.options.series[0].data.push({ y: data, color: '#0C65E8' });
                    } else {
                        this.chart.options.series[0].data.push(data);
                    }
                });
            }
            if (this.device.lastData && this.device.lastData.spectrum13Octave) {
                this.device.lastData.spectrum13Octave.value.forEach((data, i) => {
                    if (i > 30) {
                        this.chart.options.series[1].data.push({ y: data, color: '#0C65E877' });
                    } else {
                        this.chart.options.series[1].data.push(data);
                    }
                });
            }
        } else {
            this.chart.options.series[0].data = [];
            if (this.chart.options.series.length > 1) {
                this.chart.options = this.chartSimple.options;
            }
            if (this.device.lastData && this.device.lastData.spectrum13Octave) {
                this.device.lastData.spectrum13Octave.value.forEach((data, i) => {
                    if (i <= 30) {
                        this.chart.options.series[0].data.push(data);
                    }
                });
            }
        }

        if (this.isKioskMode) {
            this.chart.options.chart.height = '85%';
        } else {
            this.chart.options.chart.height = 280;
        }

        this.chart.update = true;
        if (this.chart.visible == false)
            this.chart.visible = true;
    }
}
