import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'app/services/context.service';

@Component({
    selector: 'content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private router: Router, private contextService: ContextService) {}

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = function() {
            return false;
        };
        /**
         * Subscribe to the change of selected project. In this way each time a project is
         * selected we got the notification and reload the current content, only if we are not
         * in the ADMIN section, which is not affected by project changes
         */
        this.contextService.onCurrentProjectChange.subscribe(projectId => {
            const URL = this.router.url;
            // Avoid reload on Admins URL
            if (URL.split('/').some(path => path === 'admin')) {
                return;
            }

            this.router.navigate([URL]);
        });
    }
}
