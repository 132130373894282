<div id="user" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
		<button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/admin/users'" *ngIf="pageType == 'edit'">
			<mat-icon>arrow_back</mat-icon>
		</button>

        <h1 *ngIf="pageType == 'new'">{{ "USERS.User" | translate }} - {{ "USERS.Create" | translate }}</h1>
        <h1 *ngIf="pageType == 'edit'">{{ "USERS.User" | translate }} - {{ user.name }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <!-- MAT TAB GROUP -->
            <mat-tab-group [backgroundColor]="'header'">
                <!-- USER INFO TAB -->
                <mat-tab label="{{ 'USERS.BasicInfo' | translate }}">
                    <div class="content">
                        <form name="userForm" [formGroup]="userForm" class="user">
                            <div class="section">
                                <!-- Nanem Field -->
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "USERS.Name" | translate }}</mat-label>
                                    <input matInput name="name" formControlName="name" placeholder="{{ 'USERS.Name' | translate }}" required />
                                </mat-form-field>

                                <!-- Email Field -->
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ "USERS.Email" | translate }}</mat-label>
                                    <input matInput name="email" formControlName="email" placeholder="{{ 'USERS.Email' | translate }}" required />
                                </mat-form-field>

                                <!-- Password field (if present) -->
                                <mat-form-field *ngIf="pageType == 'new'" appearance="outline">
                                    <mat-label>{{ "USERS.Password" | translate }}</mat-label>
                                    <input matInput name="password" formControlName="password" placeholder="{{ 'USERS.Password' | translate }}" required />
                                </mat-form-field>

                                <!-- Administrator Field -->
                                <mat-checkbox name="isAdmin" formControlName="isAdmin">
                                    <mat-label>{{ "USERS.IsAdmin" | translate }}</mat-label>
                                </mat-checkbox>
                            </div>

                            <div class="form-action">
                                <button mat-raised-button class="button-save" color="primary" [disabled]="userForm.invalid" *ngIf="pageType == 'new'" (click)="add()">
                                    {{ "USERS.ADD" | translate }}
                                </button>
                                <button mat-raised-button *ngIf="pageType == 'new'" (click)="cancel()">{{ "USERS.CANCEL" | translate }}</button>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <!-- PROJECTS TAB -->
                <mat-tab *ngIf="pageType == 'edit'" label="{{ 'USERS.Projects' | translate }}">
                    <div class="project-content" fusePerfectScrollbar><app-project-list (rowSelect)="onProjectCheck($event)"></app-project-list></div>
                </mat-tab>
                <!-- COMPANIES TAB -->
                <mat-tab *ngIf="pageType == 'edit'" label="{{ 'USERS.Companies' | translate }}">
                    <div class="company-content" fusePerfectScrollbar><app-company-list (rowSelect)="onCompanyCheck($event)"></app-company-list></div>
                </mat-tab>
                <!-- / MAT TAB GROUP -->
            </mat-tab-group>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
