<div id="projects" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ 'PROJECTS.Projects' | translate }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <app-project-list (rowClick)="onProjectClick($event)"
                (rowSelect)="onProjectCheck($event)"></app-project-list>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<div class="action-buttons">
    <button *ngIf="editAvailable" mat-fab color="warn" id="delete-projects-button" (click)="deleteProjects()"
        aria-label="delete project" [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="editAvailable" mat-fab color="alert" id="archive-projects-button" (click)="archiveProjects()"
        aria-label="edit project" [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        matTooltip="{{ 'PROJECTS.Archive' | translate }}">
        <mat-icon>archive</mat-icon>
    </button>
    <button *ngIf="editAvailable" mat-fab id="unarchive-projects-button" (click)="unarchiveProjects()"
        aria-label="edit project" [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
        matTooltip="{{ 'PROJECTS.Unarchive' | translate }}">
        <mat-icon>unarchive</mat-icon>
    </button>
    <button mat-fab id="add-project-button" [routerLink]="'/admin/project/new'" aria-label="add project"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>note_add</mat-icon>
    </button>
</div>