export const locale = {
    lang: 'es',
    data: {
        GLOBAL: {
            SavedChanges: 'Cambios Guardados',
            CreatedSuccess: 'Creado con éxito',
        },
        USERS: {
            Users: 'Ususarios',
            Name: 'Nombre',
            Email: 'Email',
            Admin: 'Administrador',
            IsAdmin: 'Es Administrador?',
            Password: 'Password',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCELAR',
            Create: 'Crear',
            UserName: 'Nombre',
            BasicInfo: 'Información básica',
            Projects: 'Proyectos',
            Companies: 'Empresas',
        },
        COMPANIES: {
            Companies: 'Empresas',
            Name: 'Nombre',
            Street: 'Calle',
            City: 'Ciudad',
            ZipCode: 'Código Postal',
            Country: 'País',
            BasicInfo: 'Info Básica',
            Company: 'Empresa',
            CompanyName: 'Nombre Empresa',
            Address: 'Dirección',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCEL',
            Projects: 'Proyectos',
            Create: 'Crear',
        },
        DEVICES: {
            ActiveProject: 'Proyecto Activo',
            Devices: 'Dispositivos',
            Name: 'Nombre',
            Manufacturer: 'Fabricante',
            Model: 'Modelo',
            SerialNumber: 'Número Serie',
            DataFrequency: 'Frecuencia de Datos (Segundos)',
            DataFrequencyDevice: 'Frecuencia de Datos Dispositivo (Seconds): ',
            DataFrequencyCustom: 'Frecuencia de Datos Custom (Seconds): ',
            SimOrImei: 'SIM o IMEI',
            LastCalibrationDate: 'Fecha de última calibración',
            DeviceType: 'Tipo',
            Protocol: 'Protocolo',
            Picture: 'Fotografia',
            Comments: 'Comentarios',
            Location: 'Localización',
            Active: 'Activo',
            Device: 'Dispositivo',
            Activate: 'Activar',
            Deactivate: 'Desactivar',
            Remove: 'Eliminar',
            Create: 'Crear',
            DeviceInfo: 'Información',
            FolderFTP: 'FTP Folder',
            Address: 'Dirección',
            Latitude: 'Latitud',
            Longitude: 'Longitud',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            Projects: 'Proyectos',
            ConfirmActivate:
                'Este dispositivo ya está activado en el proyecto {{project}} con el nombre {{deviceName}}. \n¿Está seguro de que desea activarlo?',
            Image: 'Imagen',
            DropFiles: 'Arrastre la imagen aquí',
            RemoveImage: 'Borra Imagen',
        },
        DEVICE: {
            ALARM: {
                AlarmDeleteTitle: 'Alerta',
                AlarmDeleteMessage: 'Esta alarma tiene {{activeEvents}} eventos activos. \nSeguro que la quieres eliminar?',
            },
        },
        PROJECTS: {
            Projects: 'Proyectos',
            Project: 'Proyecto',
            BasicInfo: 'Info Básica',
            Name: 'Nombre',
            Timezone: 'Timezone',
            Create: 'Crear',
            Devices: 'Dispositivos',
            Alarms: 'Alarmas',
            ProjectName: 'Nombre del proyecto',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            ProjectPeriodName: 'Nombre',
            ProjectPeriodStart: 'Hora de Inicio',
            ProjectPeriodEnd: 'Hora Final',
            ProjectPeriodHours: 'Horas',
            ProjectPeriodMinutes: 'Minutos',
            ProjectPeriodNew: 'Nuevo Periodo',
            ProjectPeriodError:
                'ADVERTENCIA: La hora de finalización es anterior a la hora de inicio. Está creando un período que abarca varios días.',
            Dateformat: 'Formato Fecha',
            Example: 'Ejemplo',
            Archive: 'Archivar',
            Unarchive: 'Desarchivar',
        },
    },
};
