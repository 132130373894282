import { Inject, Injectable } from '@angular/core';
import { TokenModel } from 'app/models/token.model';
import { UserModel } from 'app/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { AuthService } from './api/auth.service';
import { UserService } from './api/user.service';
import { TokenService } from './token.service';

@Injectable()
export class AuthenticationService {
    public currentUser: UserModel;
    onCurrentUserChange = new BehaviorSubject(this.currentUser);

    constructor(
        @Inject(AuthService)
        protected auth: AuthService,
        @Inject(TokenService)
        protected tokenService: TokenService,
        protected userService: UserService,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    login(login: LoginModel) {
        return this.auth
            .login(login)
            .pipe(tap((token: TokenModel) => (this.tokenService.token = token)));
    }

    me() {
        return this.userService.get(this.tokenService.token.id).pipe(
            tap((user: UserModel) => {
                this.currentUser = user;
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.onCurrentUserChange.next(user);
            }),
        );
    }

    logout() {
        this.tokenService.token = null;
        this.currentUser = null;
        localStorage.clear();
    }
}
