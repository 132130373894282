export const locale = {
    lang: 'es',
    data: {
        COMPONENT: {
            NoiseLevel: 'Nivel de Ruido',
            Battery: 'Batería',
            Octave: 'Octave Spectra',
            Average5: '5 minutos promedio',
            Average30: '30 minutos promedio',
            NoData: 'Sin datos aún',
            DeviceDisconnected: 'Asegúrese de que el dispositivo esté conectado',
            DeviceOffline: 'Dispositivo sin conexión',
            CellularSignalLevel: 'Nivel Cobertura',
            FreeStorage: 'Espacio Libre',
        },
        EVENTS: {
            Events: 'Eventos',
            Severity: 'Gravedad',
            Type: 'Tipo',
            Device: 'Dispositivo',
            StartTime: 'Hora de inicio',
            EndTime: 'Hora final',
            Description: 'Descripción',
            Remove: 'Borrar',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCELAR',
            EventType: 'Tipo de evento: ',
            NewEvent: 'Nuevo Evento',
            EventDetail: 'Detalle del evento',
            EventTimeError: 'La fecha de finalización debe ser posterior a la fecha de inicio',
            Alarm: 'Alarma',
            AckUser: 'Aceptado por',
        },
        SEVERITY: {
            alarm: 'Alarma',
            info: 'información',
            success: 'Ėxito',
            warning: 'Advertencia',
        },
        TYPE: {
            device_alarm_off: 'Alarma Apagada',
            device_alarm_on: 'Alarma Activada',
            device_added: 'Dispositivo Añadido',
            device_offline: 'Dispositivo fuera de línea',
            device_online: 'Dispositivo en línea',
            device_removed: 'Dispositivo eliminado',
            user_defined: 'Definido por el usuario',
            device_warning_off: 'Advertencia desactivada',
            device_warning_on: 'Advertencia activada',
            device_first_data: 'Primer Dato',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Nombre',
            Description: 'Descripción',
            TimeWindow: 'Ventana de tiempo',
            Samples: 'Muestras',
            Threshold: 'Umbral',
            Limit: 'Límite',
            Severity: 'Severidad',
            Field: 'Campo',
            Remove: 'Eliminar',
            time_window_instant: 'Instant',
            time_window_rolling_5: 'Promedio continuo de 5 minutos',
            time_window_rolling_30: 'Promedio continuo de 30 minutos',
            time_window_bucket_5: 'Bucket 5 minutos',
            threshold_cross_over: 'Cruzar ENCIMA',
            threshold_cross_below: 'Cruzar ABAJO',
            ADD: 'AÑADIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCELAR',
            AlarmDetail: 'Detalle de alarma',
            NewAlarm: 'Nueva Alarma',
            Notify: 'Notificar',
            Silent: 'Silenciosa',
            NewNotificationItem: 'email/teléfono',
            SimOrImei: 'SIM o IMEI',
            LastCalibrationDate: 'Fecha de última calibración',
            IgnoredDevices: 'Dispositivos Ignorados',
        },
        FIELD: {
            sound_pressure_level: 'Nivel Ruido',
            battery: 'Batería',
            power_supply_status: 'Fuente Alimentación',
            spectrum_13_octave: 'Espectro 1/3 Octava',
            temperature: 'Temperatura Ext.',
            overload: 'Sobrecarga',
            underrange: 'Underrange',
            wifi_signal_level: 'Señal WiFi',
            cellullar_signal_level: 'Señal Cellular',
        },
    },
};
