import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'emma-check',
    templateUrl: './emma-check.component.html',
    styleUrls: ['./emma-check.component.scss'],
})
export class EmmaCheckComponent implements OnInit {
    @Input() active: boolean = false;

    constructor() {}

    ngOnInit() {}
}
