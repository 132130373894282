export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'SECTIONS': 'Secciones',
            'DASHBOARD': 'Control Center',
            'DEVICES': 'Dispositivos',
            'MAP': 'Mapa',
            'EVENTS': 'Eventos',
			'REPORTS': 'Informes',
			'COMPANIES': 'Empresas',
			'PROJECTS': 'Proyectos',
            'USERS': 'Usuarios',
            'ADMIN-DEVICES': 'Dispositivos',	
            'ADMIN-PROJECTS': 'Proyectos'	
        }
    }
};
