import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AlarmModel } from 'app/models/alarm.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { AlarmService } from 'app/services/api/alarm.service';
import { ToastrService } from 'ngx-toastr';
import { AlarmAddEditComponent } from '../../../../components/alarm-add-edit/alarm-add-edit.component';
import { ProjectModel } from "../../../../models/project.model";
import { ProjectAlarmModel } from "../../../../models/projectAlarm.model";
import { ProjectAlarmService } from "../../../../services/api/projectAlarm.service";
import { ContextService } from "../../../../services/context.service";
import { FuseConfirmDialogComponent } from "../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';

@Component({
    selector: 'app-device-alarms',
    templateUrl: './device-alarms.component.html',
    styleUrls: ['./device-alarms.component.scss'],
    animations: fuseAnimations,
})
export class DeviceAlarmsComponent implements OnInit {
    @Input() device: ProjectDeviceModel;
    private dialogRef: any;
    alarms: AlarmModel[] = [];
    projectAlarms: AlarmModel[] = [];

    constructor(
        private alarmService: AlarmService,
        private projectAlarmService: ProjectAlarmService,
        private contextService: ContextService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.alarmService.getAllDevice(this.device.id).subscribe(
            (alarms) => {
                this.alarms = alarms;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );

        const currentProject = this.contextService.currentProject;
        this.projectAlarmService.getAllProject(currentProject, this.device.id).subscribe(
            (alarms) => {
                this.projectAlarms = alarms;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public addAlarm() {
        const data = { device: this.device };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            const alarm: AlarmModel = result.alarm;
            this.alarmService.create(this.device.id, alarm).subscribe(
                () => {
                    this.loadData();
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    public onAlarmClick(alarm: AlarmModel) {
        const data = { alarm, device: this.device };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            const editedAlarm = result.alarm;
            this.alarmService.edit(this.device.id, editedAlarm).subscribe(
                () => {
                    this.loadData();
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    public onProjectAlarmClick(alarm: ProjectAlarmModel): void {
        const project = new ProjectModel();
        project.id = this.contextService.currentProject;
        const data = { alarm, project };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            const editedAlarm = result.alarm;
            this.projectAlarmService.edit(this.contextService.currentProject, editedAlarm).subscribe(
                () => {
                    this.loadData();
                },
                (error) => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    public onAlarmDelete(alarm: AlarmModel) {
        this.alarmService.getActiveEvents(this.device.id, alarm.id).subscribe(
            (result) => {
                if (result.activeEvents > 0) {
                    const message = this._fuseTranslationLoaderService.translateService.instant(
                        'ALARM.AlarmDeleteMessage',
                        {
                            activeEvents: result.activeEvents,
                        },
                    );
                    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                        disableClose: false,
                    });
                    confirmDialogRef.componentInstance.title = 'ALARM.AlarmDeleteTitle';
                    confirmDialogRef.componentInstance.confirmMessage = message;
                    confirmDialogRef.afterClosed().subscribe((confirm) => {
                        if (confirm) {
                            this.alarmDeleteAction(alarm);
                        }
                        confirmDialogRef = null;
                    });
                } else {
                    this.alarmDeleteAction(alarm);
                }
            }, (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public alarmDeleteAction(alarm) {
        this.alarmService.delete(this.device.id, alarm.id).subscribe(
            () => {
                this.loadData();
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public onProjectAlarmDelete(alarm: ProjectAlarmModel): void {
        this.projectAlarmService.delete(this.contextService.currentProject, alarm.id).subscribe(
            () => this.loadData(),
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public addProjectAlarm(): void {
        const project = new ProjectModel();
        project.id = this.contextService.currentProject;
        const data = { project };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            const alarm: ProjectAlarmModel = result.alarm;
            this.projectAlarmService.create(project.id, alarm).subscribe(
                () => this.loadData(),
                (error) => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }
}
