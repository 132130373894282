import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AlarmModel } from 'app/models/alarm.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-alarm-list',
    templateUrl: './alarm-list.component.html',
    styleUrls: ['./alarm-list.component.scss'],
    animations: fuseAnimations,
})
export class AlarmListComponent implements OnInit {
    @Input() alarms: AlarmModel[];
    private dialogRef: any;
    displayedColumns = [
        'name',
        'field',
        'window',
        'threshold',
        'limit',
        'samples',
        'severity',
        'buttons',
    ];

    @Output()
    rowClick = new EventEmitter<AlarmModel>();

    @Output()
    deleteClick = new EventEmitter<AlarmModel>();

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
    ) {}

    ngOnInit() {}

    // public editAlarm(alarm: AlarmModel) {
    //     const data = { alarm, device: this.device };
    //     this.dialogRef = this._matDialog.open(DeviceAlarmComponent, { data });
    //     this.dialogRef.afterClosed().subscribe(result => {
    //         if (!result) {
    //             return;
    //         }
    //         const alarm = result.alarm;
    //         this.alarmService.edit(this.device.id, alarm).subscribe(
    //             data => {
    //                 this.loadData();
    //             },
    //             error => {
    //                 console.log(error);
    //                 this.toastr.error('Error: ' + error.message);
    //             },
    //         );
    //     });
    // }

    // public addAlarm() {
    //     const data = { device: this.device };
    //     this.dialogRef = this._matDialog.open(DeviceAlarmComponent, { data });
    //     this.dialogRef.afterClosed().subscribe(result => {
    //         if (!result) {
    //             return;
    //         }
    //         const alarm: AlarmModel = result.alarm;
    //         this.alarmService.create(this.device.id, alarm).subscribe(
    //             data => {
    //                 this.loadData();
    //             },
    //             error => {
    //                 console.log(error);
    //                 this.toastr.error('Error: ' + error.message);
    //             },
    //         );
    //     });
    // }

    // public deleteAlarm(alarm: AlarmModel) {
    //     this.alarmService.delete(this.device.id, alarm.id).subscribe(
    //         success => {
    //             this.loadData();
    //         },
    //         error => {
    //             console.log(error);
    //             if (error.error.statusCode === 404) {
    //                 this.router.navigate(['/devices']);
    //             }
    //         },
    //     );
    // }

    onDeleteClick(alarm: AlarmModel) {
        this.deleteClick.emit(Object.assign(new AlarmModel(), alarm));
    }

    onClick(alarm: AlarmModel) {
        this.rowClick.emit(Object.assign(new AlarmModel(), alarm));
    }
}
