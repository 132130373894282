import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EventDataModel } from 'app/models/event.model';
import { EventRequest, EventService } from 'app/services/api/event.service';
import { ProjectViewService } from 'app/services/api/projectView.service';
import { ContextService } from 'app/services/context.service';
import { ProjectViewPreferenceService } from 'app/services/projectViewPreference.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { EventMqttService } from 'app/services/api/event.mqtt.service';
import { IMqttMessage } from "ngx-mqtt";

@Component({
    selector: 'event-stream',
    templateUrl: './event-stream.component.html',
    styleUrls: ['./event-stream.component.scss'],
})
export class EventStreamComponent implements OnInit {
    events: EventDataModel[];
    private currentProjectId: string;
    private loadingData = false;
    ackEnabled = EventDataModel.ackEnabled;
    subscription: Subscription;

    constructor(
        private projectViewService: ProjectViewService,
        private preferenceProjectViewService: ProjectViewPreferenceService,
        private contextService: ContextService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private eventService: EventService,
        private toastr: ToastrService,
        private readonly eventMqtt: EventMqttService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.currentProjectId = this.contextService.currentProject;
        this.loadData();
        this.subscribeToTopics();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private subscribeToTopics() {
        this.subscription = this.eventMqtt.topic(this.currentProjectId)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.events.unshift(item);
                this.events.pop();
            });
    }

    private loadData() {
        if (this.loadingData) {
            return;
        }
        this.loadingData = true;

        const savedView = this.preferenceProjectViewService.savedView(this.currentProjectId);
        let devices = undefined;
        const request: EventRequest = {};
        request.project = this.currentProjectId;
        request.size = 30;
        if (savedView) {
            const view = this.projectViewService.viewList.find(v => v.id === savedView);
            if (view) {
                const projectsDevice = new Set(view.entries.map(e => e.projectDeviceId));
                devices = Array.from(projectsDevice.values());
                request.devicesId = devices;
            }
            if (devices == null || devices.length == 0) {
                this.events = [];
                this.loadingData = false;
                return;
            }
        }

        this.eventService.getAll(request).subscribe(
            events => {
                this.events = events.data.slice(0, 11);
                this.loadingData = false;
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                this.loadingData = false;
            },
        );
    }

    acknowledgeEvent(event: EventDataModel) {
        this.eventService.acknowledge(event.id).subscribe(
            () => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                this.loadingData = false;
            },
        );
    }
}
