export const chartBandSpectraSimple = {
	constructor: 'chart',
	options: {
		chart: {
			type: "column",
			margin: [0, 0, 0, 0],
			height: 280,
			backgroundColor: 'transparent'
		},
		title: {
			text: ''
		},
		rangeSelector: {
			enabled: false
		},
		tooltip: {
			valueDecimals: 1,
			valueSuffix: "dB"
		},
		plotOptions: {
			line: {
				gapSize: 15 * 1000,
				gapUnit: "value",
				dataGrouping: {
					enabled: false,
				}
			},
			column: {
				pointPadding: 0,
				borderWidth: 0,
			},
			series: {
				pointPadding: 0,
				groupPadding: 0.1,
				animation: false,
			}
		},
		xAxis: {
			title: {
				text: null
			},
			categories: [
				'20 Hz',
				'25 Hz',
				'31.5 Hz',
				'40 Hz',
				'50 Hz',
				'63 Hz',
				'80 Hz',
				'100 Hz',
				'125 Hz',
				'160 Hz',
				'200 Hz',
				'250 Hz',
				'315 Hz',
				'400 Hz',
				'500 Hz',
				'630 Hz',
				'800 Hz',
				'1 kHz',
				'1.25 kHz',
				'1.6 kHz',
				'2 kHz',
				'2.5 kHz',
				'3.150 kHz',
				'4 kHz',
				'5 kHz',
				'6.3 kHz',
				'8 kHz',
				'10 kHz',
				'12.5 kHz',
				'16 kHz',
				'20 kHz'
			],
			visible: false,
		},
		yAxis: [{
			labels: {
				align: "left"
			},
			visible: false,
			floor: 10,
			ceiling: 130,
			max: 130,
			min: 10,
			plotLines: [],
		}],
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		series: [
			{
				data: [],
				name: "Inst",
				lineWidth: 0,
				color: '#00abff',
				states: {
					hover: {
						enabled: true,
					}
				},
				visible: true,
			}
		]
	},
	update: true,
	visible: false
};