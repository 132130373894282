export class UploadUtils {
    private static maxFileSize = 2000000;

    static isFileSizeAllowed(file: File) {
        console.log(file.size);
        console.log(file.size < this.maxFileSize);
        return file.size < this.maxFileSize;
    }

    static isFileAllowed(file: File) {
        let isFileAllowed = false;
        const allowedFiles = ['.jpg', '.jpeg', '.png'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(file.name);
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }

    static isDataFileAllowed(file: File) {
        let isFileAllowed = false;
        const allowedFiles = ['.xls', '.xlsx'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(file.name);
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }
}
