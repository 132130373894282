import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EventDataModel, EventModelSeverity } from 'app/models/event.model';
import { EventService, EventRequest } from 'app/services/api/event.service';
import { PaginationResponse } from 'app/services/api/interfaces/response.interface';
import { ContextService } from 'app/services/context.service';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from "../../../../@fuse/animations";
import { EventComponent } from '../event/event.component';
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    animations: fuseAnimations,
})
export class EventsComponent implements OnInit, AfterViewInit {
    private dialogRef: any;
    resolvedAckEventsPage: PaginationResponse<EventDataModel>;
    pendingAckEventsPage: PaginationResponse<EventDataModel>;
    currentProjectId: string;
    filters = [
        EventModelSeverity.EventModelSeverityInfo,
        EventModelSeverity.EventModelSeveritySuccess,
        EventModelSeverity.EventModelSeverityWarning,
        EventModelSeverity.EventModelSeverityAlarm,
    ];
    private selectedFilters: EventModelSeverity[] = [];

    @ViewChild('pendingAckPaginator', { static: true }) pendingAckPaginator: MatPaginator;
    @ViewChild('resolvedAckPaginator', { static: true }) resolvedAckPaginator: MatPaginator;

    constructor(
        private contextService: ContextService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private eventService: EventService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.currentProjectId = this.contextService.currentProject;
    }

    ngAfterViewInit() {
        this.pendingAckPaginator.page.subscribe(() => this.loadPendingAckEvents());
        this.resolvedAckPaginator.page.subscribe(() => this.loadResolvedAckEvents());
        this.loadData();
    }

    private loadData() {
        this.loadPendingAckEvents();
        this.loadResolvedAckEvents();
    }

    private loadPendingAckEvents() {
        const request: EventRequest = {
            project: this.currentProjectId,
            size: this.pendingAckPaginator.pageSize,
            offset: this.pendingAckPaginator.pageIndex * this.pendingAckPaginator.pageSize,
            pendingAck: true,
        };
        if (this.selectedFilters.length > 0) {
            request.severity = this.selectedFilters;
        }

        this.eventService.getAll(request).subscribe(
            (events) => {
                this.pendingAckEventsPage = events;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    private loadResolvedAckEvents() {
        const request: EventRequest = {
            project: this.currentProjectId,
            size: this.resolvedAckPaginator.pageSize,
            offset: this.resolvedAckPaginator.pageIndex * this.resolvedAckPaginator.pageSize,
            pendingAck: false,
        };

        if (this.selectedFilters.length > 0) {
            request.severity = this.selectedFilters;
        }

        this.eventService.getAll(request).subscribe(
            (events) => {
                this.resolvedAckEventsPage = events;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public editEvent(event: EventDataModel) {
        const data = { event };
        this.dialogRef = this._matDialog.open(EventComponent, { data });

        this.dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            const event = result.event;
            this.eventService.edit(event).subscribe(
                data => {
                    this.loadData();
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                    this.loadData();
                },
            );
        });
    }

    public addEvent() {
        this.dialogRef = this._matDialog.open(EventComponent);

        this.dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            const event: EventDataModel = result.event;
            event.projectId = this.currentProjectId;
            this.eventService.create(event).subscribe(
                data => {
                    this.loadData();
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    public deleteEvent(event: EventDataModel) {
        this.eventService.delete(event.id).subscribe(
            success => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public ackEvent(event: EventDataModel) {
        this.eventService.acknowledge(event.id).subscribe(
            success => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    onSelectFilter(filter: EventModelSeverity) {
        const filterIdx = this.selectedFilters.indexOf(filter);
        if (filterIdx > -1) {
            this.selectedFilters.splice(filterIdx, 1);
        } else {
            this.selectedFilters.push(filter);
        }
        this.pendingAckPaginator.pageIndex = 0;
        this.resolvedAckPaginator.pageIndex = 0;
        this.loadData();
    }
}
