export const locale = {
    lang: 'ca',
    data: {
        DASHBOARD: {
            Title: 'Control Center',
            SwitchViews: 'Canviar Vista',
            Done: 'Tancar',
            EventStream: 'Events',
            KioskMode: 'Modo Kiosko',
            KioskModeMessage: 'El mode Kiosk funciona millor en pantalla completa, vols canviar a pantalla completa?',
        },
        DEVICES: {
            ConnectedDevices: 'Dispositius Connectats',
            Connected: 'Connectats',
            Total: 'Total',
        },
        SIDEBAR: {
            Views: 'Vistes',
            Delete: 'Esteu segur que voleu suprimir ?',
            AddView: 'Afegir Vista',
        },
    },
};
