import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";
import { DeviceDataType } from "../../models/deviceData.model";
import { DataTypeModelKey } from 'app/models/dataType.model';
// import { Bugfender } from '@bugfender/sdk';

@Injectable()
export class DeviceMqttService {

  private endpoint: string;

  constructor(
    private _mqttService: MqttService,
  ) {
    this.endpoint = 'device';
  }

  deviceTopic(deviceId: string, type: DeviceDataType, field: DataTypeModelKey): Observable<IMqttMessage> {
    let topicName = `/${this.endpoint}/${deviceId}/${type}`;
    if (field)
      topicName += `/${field}`;

    // Bugfender.sendLog({ tag: "MQTT", text: "Subscribe to " + topicName });

    return this._mqttService.observe(topicName);
  }

}
