<div id="device" class="page-layout emma simple fullwidth">
    <div class="header accent">
        <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/devices'">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <div class="product-image mr-8 mr-sm-16" [@animate]="{ value: '*', params: { delay: '50ms', scale: '0.2' } }">
            <emma-device-type-icon [deviceType]="device.baseDeviceData?.deviceType"></emma-device-type-icon>
        </div>

        <div fxLayout="column" fxLayoutAlign="start start"
            [@animate]="{ value: '*', params: { delay: '100ms', x: '-25px' } }">
            <div class="h2">{{ device.deviceName }}</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-tab-group [backgroundColor]="'header'" (selectedTabChange)="onTabChange($event)">
                <mat-tab label="{{'TAB.Status' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-status #tabStatus [device]="device"></app-device-status>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Data' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-data #tabData [device]="device"></app-device-data>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Alarms' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-alarms #tabAlarms [device]="device"></app-device-alarms>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Events' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-event #tabEvents [device]="device"></app-device-event>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Info' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-info #tabInfo [device]="device"></app-device-info>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Report' | translate}}">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-report #tabReport [device]="device"></app-device-report>
                    </div>
                </mat-tab>

                <mat-tab label="{{'TAB.Files' | translate}}" *ngIf="device.baseDeviceData?.protocol.name=='File'">
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <app-device-file #taFile [device]="device"></app-device-file>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!-- / CONTENT -->
</div>