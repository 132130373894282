export const locale = {
    lang: 'en',
    data: {
        DASHBOARD: {
            Title: 'Control Center',
            SwitchViews: 'Cambiar Vista',
            Done: 'Done',
            EventStream: 'Eventos',
            KioskMode: 'Kiosk Mode',
            KioskModeMessage: 'Kiosk Mode works better on FullScreen, do you want to go FullScreen?',
        },
        DEVICES: {
            ConnectedDevices: 'Connected Devices',
            Connected: 'Connected',
            Total: 'Total',
        },
        SIDEBAR: {
            Views: 'Views',
            Delete: 'Are you sure you want to delete ?',
            AddView: 'Add View',
        },
    },
};
