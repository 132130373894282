<mat-table class="companies-table" #table [dataSource]="companies" [@animateStagger]="{value:'50'}"
    fusePerfectScrollbar>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
        <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-check" *matCellDef="let company">
            <mat-checkbox [(ngModel)]="checkboxes[company.id]" (ngModelChange)="onSelectedChange(company)" (click)="$event.stopPropagation()">
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{ 'COMPANIES.Name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let company">
            <p class="text-truncate">{{company.name}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="street">
        <mat-header-cell *matHeaderCellDef>{{ 'COMPANIES.Street' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let company">
            <p class="text-truncate">{{company.street}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef>{{ 'COMPANIES.City' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let company">
            <p class="text-truncate">{{company.city}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="zipCode">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>{{ 'COMPANIES.ZipCode' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let company" fxHide fxShow.gt-xs>
            <p class="text-truncate">{{company.zipCode}}</p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef>{{ 'COMPANIES.Country' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let company">
            <p class="text-truncate">{{company.country}}</p>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

    <mat-row *matRowDef="let company; columns: displayedColumns;" class="company row-pointer" matRipple (click)="onClick(company)">
    </mat-row>

</mat-table>
