import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { EmmaCheckComponent } from 'app/components/emma-check/emma-check.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AlarmListComponent } from './alarm-list/alarm-list.component';
import { AlarmTypeSpanComponent } from './alarm-type-span/alarm-type-span.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { DeviceBandSpectra } from './device-card-band-spectra/device-band-spectra.component';
import { DeviceCardBattery } from './device-card-battery/device-card-battery.component';
import { DeviceCardSoundPressure } from './device-card-soundpressure/device-card-soundpressure.component';
import { DeviceTypeIconComponent } from './device-type-icon/device-type-icon.component';
import { DeviceViewListComponent } from './device-view-list/device-view-list.component';
import { EventListComponent } from './event-list/event-list.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { DeviceCardCellular } from './device-card-cellular/device-card-cellular.component';
import { DeviceCardFreeStorage } from './device-card-free-storage/device-card-free-storage.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        AlarmTypeSpanComponent,
        AlarmListComponent,
        CompanyListComponent,
        DeviceBandSpectra,
        DeviceCardBattery,
        DeviceCardSoundPressure,
        DeviceCardCellular,
        DeviceCardFreeStorage,
        DeviceTypeIconComponent,
        EmmaCheckComponent,
        EventListComponent,
        ProjectListComponent,
        DeviceViewListComponent,
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        HighchartsChartModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        RouterModule,
        TranslateModule,
        FontAwesomeModule,
    ],
    exports: [
        AlarmTypeSpanComponent,
        AlarmListComponent,
        CompanyListComponent,
        DeviceBandSpectra,
        DeviceCardBattery,
        DeviceCardCellular,
        DeviceCardSoundPressure,
        DeviceCardFreeStorage,
        DeviceTypeIconComponent,
        EmmaCheckComponent,
        EventListComponent,
        ProjectListComponent,
    ],
})
export class ComponentsModule {}
