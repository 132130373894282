<div id="devices" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ "DEVICES.Devices" | translate }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table class="devices-table" #table [dataSource]="devices" [@animateStagger]="{ value: '50' }" fusePerfectScrollbar>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="cell-check" *matCellDef="let device">
                        <mat-checkbox [(ngModel)]="checkboxes[device.id]" (ngModelChange)="onSelectedChange(device.id)"
                            (click)="$event.stopPropagation()"> </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <!-- Icon Column -->
                <ng-container matColumnDef="icon">
                    <mat-header-cell class="cell-icon" *matHeaderCellDef fxHide fxShow.gt-xs></mat-header-cell>
                    <mat-cell class="cell-icon" *matCellDef="let device" fxHide fxShow.gt-xs>
                        <emma-device-type-icon [deviceType]="device.deviceType"></emma-device-type-icon>
                    </mat-cell>
                </ng-container>

                <!-- Picture Column -->
                <ng-container matColumnDef="picture">
                    <mat-header-cell class="cell-picture" *matHeaderCellDef fxHide fxShow.gt-xs></mat-header-cell>
                    <mat-cell class="cell-picture" *matCellDef="let device" fxHide fxShow.gt-xs>
                        <cl-image *ngIf="device.picture" public-id={{device.picture}} style="border-radius: 50%;">
                            <cl-transformation width="80" height="80" fetch-format="auto" crop="fill">
                            </cl-transformation>
                        </cl-image>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{ "DEVICES.Name" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.name }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Manufacturer Column -->
                <ng-container matColumnDef="manufacturer">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>{{ "DEVICES.Manufacturer" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{ manufacturerMap[device.manufacturer]?.name }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Model Column -->
                <ng-container matColumnDef="model">
                    <mat-header-cell *matHeaderCellDef>{{ "DEVICES.Model" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.model }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Serial Number Column -->
                <ng-container matColumnDef="serial-number">
                    <mat-header-cell *matHeaderCellDef>{{ "DEVICES.SerialNumber" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.serialNumber }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Device Type Column -->
                <ng-container matColumnDef="device-type">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>{{ "DEVICES.DeviceType" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{ device.deviceType | translate }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Device Type Column -->
                <ng-container matColumnDef="active-project">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>{{ "DEVICES.ActiveProject" | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let device" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{ device.activeInProject?.name }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Extra buttons column -->
                <ng-container matColumnDef="buttons">
                    <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="cell-check" *matCellDef="let device">
                        <div fxFlex="row" fxLayoutAlign="end center">
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" (click)="$event.stopPropagation()">
                                <mat-icon class="secondary-text">more_vert</mat-icon>
                            </button>
                            <!-- Dotted menu entries -->
                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item aria-label="remove" (click)="deleteDevice(device)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{ "DEVICES.Remove" | translate }}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let device; columns: displayedColumns" class="device row-pointer" matRipple
                    [routerLink]="'/admin/device/' + device.id"> </mat-row>
            </mat-table>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<div class="action-buttons">
    <button *ngIf="selectedDevices > 0" mat-fab color="warn" id="delete-devices-button" (click)="deleteDevices()" aria-label="delete user"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>delete</mat-icon>
    </button>
    <button mat-fab id="add-device-button" [routerLink]="'/admin/device/new'" aria-label="add device"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>add</mat-icon>
    </button>
</div>
