export const locale = {
    lang: 'en',
    data: {
        EVENTS: {
            Events: 'Events',
            Severity: 'Severity',
            Type: 'Type',
            Device: 'Device',
            StartTime: 'Start Time',
            EndTime: 'End Time',
            Description: 'Description',
            Remove: 'Remove',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            EventType: 'Event type: ',
            NewEvent: 'New Event',
            EventDetail: 'Event Detail',
            EventTimeError: 'End date must be after start date'
        },
        SEVERITY: {
            alarm: 'Alarm',
            info: 'Information',
            success: 'Success',
            warning: 'Warning',
        },
        TYPE: {
            device_alarm_off: 'Alarm Off',
            device_alarm_on: 'Alarm On',
            device_added: 'Device Added',
            device_offline: 'Device Offline',
            device_online: 'Device Online',
            device_removed: 'Device Removed',
            user_defined: 'User Defined',
            device_warning_off: 'Warning Off',
            device_warning_on: 'Warning On',
        },
    },
};
