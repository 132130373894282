<div class="dialog-content-wrapper">
    <!-- Header start -->
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span *ngIf="action == 'NEW'" class="title dialog-title">{{
                'DEVICES.NewDevice' | translate
            }}</span>
            <span *ngIf="action == 'EDIT'" class="title dialog-title">{{
                device.deviceName
            }}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- Header end -->

    <mat-dialog-content fusePerfectScrollbar>
        <form name="deviceForm" [formGroup]="deviceForm" class="device-form w-100-p" fxLayout="column" fxFlex>
            <div class="section">
                <h2 class="section__title">
                    <mat-icon>business</mat-icon>
                    <div>{{ 'DEVICES.DeviceInfo' | translate }}</div>
                </h2>

                <!-- Base Device -->
                <mat-form-field appearance="outline">
                    <mat-select formControlName="baseDevice" placeholder="Base Device" required (selectionChange)="updatedBaseDevice()">
                        <mat-option *ngFor="let device of devices" [value]="device.id">
                            {{ device.name }} -​ {{ device.model }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Device Name -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DEVICES.Name' | translate }}</mat-label>
                    <input required matInput name="name" formControlName="name" placeholder="{{ baseDevice?.name }}" />
                </mat-form-field>

                <!-- Comment -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DEVICES.Comments' | translate }}</mat-label>
                    <input matInput name="comments" formControlName="comments" placeholder="{{ 'DEVICES.Comments' | translate }}" />
                </mat-form-field>

                <!-- FTP Folder -->
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DEVICES.FolderFTP' | translate }}</mat-label>
                    <input matInput name="ftpFolder" formControlName="ftpFolder" placeholder="{{ 'DEVICES.FolderFTP' | translate }}" />
                </mat-form-field>

                <!-- Data Frequency -->
                <mat-label *ngIf="baseDevice">{{ 'DEVICES.DataFrequencyDevice' | translate }}
                    {{ baseDevice?.dataFrequency }}</mat-label>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'DEVICES.DataFrequencyCustom' | translate }}</mat-label>
                    <input matInput type="number" name="dataFrequency" formControlName="dataFrequency"
                        placeholder="{{ 'DEVICES.DataFrequencyCustom' | translate }}" />
                </mat-form-field>

                <!-- Photo -->
                <div class="section">
                    <h2 class="section__title">
                        <mat-icon>image</mat-icon>
                        <mat-label>{{ 'DEVICES.Picture' | translate }}</mat-label>
                    </h2>
                    <ngx-file-drop *ngIf="!imageUrl" dropZoneLabel="{{ 'DEVICES.DropFiles' | translate }}" (onFileDrop)="imageDropped($event)">
                    </ngx-file-drop>
                    <div *ngIf="imageUrl && !uploadedFile" class="picture" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                        <cl-image public-id={{imageUrl}}>
                            <cl-transformation width="800" height="600" fetch-format="auto" crop="limit">
                            </cl-transformation>
                        </cl-image>
                        <button mat-raised-button class="button-remove" color="primary" (click)="removeImage()">
                            <mat-icon>delete</mat-icon>
                            {{ 'DEVICES.RemoveImage' | translate }}
                        </button>
                    </div>
                    <div *ngIf="imageUrl && uploadedFile" class="picture" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                        <img src={{imageUrl}}>
                        <button mat-raised-button class="button-remove" color="primary" (click)="removeImage()">
                            <mat-icon>delete</mat-icon>
                            {{ 'DEVICES.RemoveImage' | translate }}
                        </button>
                    </div>
                </div>


                <!-- Location -->
                <div formGroupName="location" class="section">
                    <h2 class="section__title">
                        <mat-icon>location_on</mat-icon>
                        <mat-label>{{ 'DEVICES.Location' | translate }}</mat-label>
                    </h2>

                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'DEVICES.Address' | translate }}</mat-label>
                        <input matInput name="address" formControlName="address" placeholder="{{ 'DEVICES.Address' | translate }}" />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'DEVICES.Latitude' | translate }}</mat-label>
                        <input matInput type="number" name="latitude" formControlName="latitude" placeholder="{{ 'DEVICES.Latitude' | translate }}" />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'DEVICES.Longitude' | translate }}</mat-label>
                        <input matInput type="number" name="longitude" formControlName="longitude"
                            placeholder="{{ 'DEVICES.Longitude' | translate }}" />
                    </mat-form-field>
                </div>
            </div>
            <div class="form-action">
                <button *ngIf="action == 'NEW'" mat-raised-button class="button-save" color="primary" (click)="save()" [disabled]="deviceForm.invalid"
                    ngsw>
                    {{ 'PROJECTS.ADD' | translate }}
                </button>
                <button *ngIf="action == 'EDIT'" mat-raised-button class="button-save" color="primary" (click)="save()" ngsw
                    [disabled]="deviceForm.invalid">
                    {{ 'DEVICES.SAVE' | translate }}
                </button>
                <button mat-raised-button (click)="cancel()">
                    {{ 'PROJECTS.CANCEL' | translate }}
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>