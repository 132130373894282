import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ProjectDeviceResolver implements Resolve<Observable<ProjectDeviceModel>> {
    constructor(
        private projectDeviceService: ProjectDeviceService,
        private router: Router,
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') === 'new') {
            return of(new ProjectDeviceModel());
        }

        return this.projectDeviceService.get(route.paramMap.get('id')).pipe(
            mergeMap((device) => {
                if (device) {
                    return of(device);
                } else {
                    this.router.navigate(['/']);
                    return EMPTY;
                }
            }),
        );
    }
}
