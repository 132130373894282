export const environment = {
    production: true,
	hmr: false,
	http: {
		apiUrl: 'https://api.dev.emmadb.com',
		dataUrl: 'https://data.dev.emmadb.com',
	},
	mqtt: {
		server: 'app.dev.emmadb.com',
		protocol: "wss",
		port: 1883
	}

};
