<div id="project" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/admin/projects'" *ngIf="pageType == 'edit'">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <h1 *ngIf="pageType == 'new'">
            {{ 'PROJECTS.Project' | translate }} - {{ 'PROJECTS.Create' | translate }}
        </h1>
        <h1 *ngIf="pageType == 'edit'">
            {{ 'PROJECTS.Project' | translate }} - {{ project.name }}
        </h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-tab-group [backgroundColor]="'header'">
                <!-- Project Info Tab -->
                <mat-tab label="{{ 'PROJECTS.BasicInfo' | translate }}">
                    <div class="content">
                        <form name="projectForm" [formGroup]="projectForm" class="company">
                            <div class="section">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'PROJECTS.ProjectName' | translate }}</mat-label>
                                    <input matInput name="name" formControlName="name"
                                        placeholder="{{ 'PROJECTS.ProjectName' | translate }}" required />
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'PROJECTS.Timezone' | translate }}</mat-label>
                                    <mat-select formControlName="timezone"
                                        placeholder="{{ 'PROJECTS.Timezone' | translate }}" required>
                                        <mat-option *ngFor="let timezone of timeZones" [value]="timezone">
                                            {{ timezone }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'PROJECTS.Dateformat' | translate }}</mat-label>
                                    <mat-select formControlName="dateformat"
                                        placeholder="{{ 'PROJECTS.Dateformat' | translate }}" required>
                                        <mat-option *ngFor="let dateformat of dateformats" [value]="dateformat">
                                            {{ dateformat }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>{{ 'PROJECTS.Example' | translate }}: {{ date | date:project.dateformat:timezone}}
                                </div>
                            </div>
                            <!-- Form actions -->
                            <div class="form-action">
                                <button mat-raised-button class="button-save" color="primary"
                                    [disabled]="projectForm.invalid" *ngIf="pageType == 'new'" (click)="add()">
                                    {{ 'PROJECTS.ADD' | translate }}
                                </button>
                                <button mat-raised-button *ngIf="pageType == 'new'" (click)="cancel()">
                                    {{ 'PROJECTS.CANCEL' | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="pageType === 'edit'" label="{{ 'PROJECTS.Devices' | translate }}">
                    <div class="project-device-content">
                        <app-project-devices [project]="project"></app-project-devices>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="pageType === 'edit'" label="{{ 'PROJECTS.Periods' | translate }}">
                    <div class="app-project-periods">
                        <app-project-periods [project]="project"></app-project-periods>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="pageType === 'edit'" label="{{ 'PROJECTS.Alarms' | translate }}">
                    <div class="app-project-alarms">
                        <app-project-alarm [project]="project"></app-project-alarm>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>