<div id="devices" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ 'USERS.Users' | translate }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table
                class="users-table"
                #table
                [dataSource]="users"
                [@animateStagger]="{ value: '50' }"
                fusePerfectScrollbar
            >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="cell-check" *matCellDef="let user">
                        <mat-checkbox
                            [(ngModel)]="checkboxes[user.id]"
                            (ngModelChange)="onSelectedChange(user.id)"
                            (click)="$event.stopPropagation()"
                        >
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <!-- Name Columne -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{
                        'USERS.Name' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{ user.name }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Email column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>{{
                        'USERS.Email' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{ user.email }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Admin Column -->
                <ng-container matColumnDef="admin">
                    <mat-header-cell class="cell-center" *matHeaderCellDef>{{
                        'USERS.Admin' | translate
                    }}</mat-header-cell>
                    <mat-cell class="cell-center" *matCellDef="let user">
                        <emma-check [active]="user.isAdmin"></emma-check>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row
                    *matRowDef="let user; columns: displayedColumns"
                    class="user row-pointer"
                    matRipple
                    [routerLink]="'/admin/user/' + user.id"
                >
                </mat-row>
            </mat-table>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<div class="action-buttons">
    <button
        *ngIf="selectedUsers > 0"
        mat-fab
        color="warn"
        id="delete-users-button"
        (click)="deleteUsers()"
        aria-label="delete user"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
        <mat-icon>delete</mat-icon>
    </button>
    <button
        mat-fab
        id="add-user-button"
        [routerLink]="'/admin/user/new'"
        aria-label="add user"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }"
    >
        <mat-icon>person_add</mat-icon>
    </button>
</div>
