import { Component, OnInit, Input } from '@angular/core';

import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'app-connected-devices',
    templateUrl: './connected-devices.component.html',
    styleUrls: ['./connected-devices.component.scss'],
})
export class ConnectedDevicesComponent implements OnInit {
    @Input() devices: ProjectDeviceModel[] = [];

    constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {}

    get connectedDevices(): number {
        return this.devices.filter(device => device.connected).length;
    }

    get deviceNumber(): number {
        return this.devices.length;
    }
}
