import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceAggregatedData, DeviceDataModel, DeviceDataType } from 'app/models/deviceData.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DataService } from 'app/services/api/data.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, timer } from 'rxjs';
import { locale as catalan } from './../i18n/ca';
import { locale as english } from './../i18n/en';
import { locale as spanish } from './../i18n/es';
import { EventModelSeverity } from 'app/models/event.model';
import { AlarmModelTimeWindow } from 'app/models/alarm.model';
import { DeviceMqttService } from 'app/services/api/device.mqtt.service';
import * as Highcharts from 'highcharts/highcharts';
import { chartSoundPressure5MinutesCard } from 'app/chart-config/chart-sound-pressure-5-minutes-card';
import { IMqttMessage } from "ngx-mqtt";
import { ContextService } from 'app/services/context.service';

@Component({
    selector: 'device-card-soundpressure',
    templateUrl: './device-card-soundpressure.component.html',
    styleUrls: ['./device-card-soundpressure.component.scss'],
})
export class DeviceCardSoundPressure implements OnInit, OnDestroy {
    @Input() device: ProjectDeviceModel;
    @Input() showAvg5 = true;
    @Input() showAvg30 = true;
    @Input() editGraphViews = false;

    dataType = DataTypeModelKey.soundPressureLevel;
    loadingData = false;
    deviceData: DeviceDataModel[];
    sensorGraphData: any = null;

    statusValue: EventModelSeverity;
    statusAvg5: EventModelSeverity;
    statusAvg30: EventModelSeverity;

    subsInstant: Subscription;
    subsStatus: Subscription;
    subsAvg5: Subscription;
    subsAvg30: Subscription;
    subsBucket: Subscription;

    Highcharts = Highcharts; // required
    chart = JSON.parse(JSON.stringify(chartSoundPressure5MinutesCard));

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly dataService: DataService,
        private readonly projectDeviceService: ProjectDeviceService,
        private readonly deviceMqtt: DeviceMqttService,
        private toastr: ToastrService,
        public contextService: ContextService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.fetchData();
        this.subscribeToTopics();
    }

    ngOnDestroy(): void {
        this.subsInstant.unsubscribe();
        this.subsStatus.unsubscribe();
        this.subsAvg30.unsubscribe();
        this.subsAvg5.unsubscribe();
        this.subsBucket.unsubscribe();
    }

    private subscribeToTopics() {
        this.subsInstant = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.instant, DataTypeModelKey.soundPressureLevel)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.lastData.soundPressureLevel.value = item.value;
                this.device.lastData.soundPressureLevel.timestamp = item.timestamp;
            });

        this.subsStatus = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.status, null)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.connected = item.connected;
                this.device.activeEvents = item.activeEvents;
                this.refreshAlarms();
            })

        this.subsAvg30 = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.thirtyMinutes, DataTypeModelKey.soundPressureLevel)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.dataAggregation30.soundPressureLevel.average = item.average;
            })

        this.subsAvg5 = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.fiveMinutes, DataTypeModelKey.soundPressureLevel)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());
                this.device.dataAggregation.soundPressureLevel.average = item.average;
            })

        this.subsBucket = this.deviceMqtt.deviceTopic(this.device.id, DeviceDataType.bucket, DataTypeModelKey.soundPressureLevel)
            .subscribe((data: IMqttMessage) => {
                let item = JSON.parse(data.payload.toString());

                let last = this.deviceData[this.deviceData.length - 1];


                if (moment(last.date).format('hh:mm') == moment(item.date).format('hh:mm')) {
                    this.deviceData[this.deviceData.length - 1].aggregation = item.aggregation;
                } else {
                    this.deviceData.shift();
                    this.deviceData.push(item);
                }
                this.createSensorData();
            })
    }

    private refreshData() {
        if (this.loadingData) {
            return;
        }
        this.loadingData = true;

        this.fetchDevice();
        this.fetchData();
    }

    private refreshAlarms() {
        this.statusAvg5 = this.device.getAlarmStatus(DataTypeModelKey.soundPressureLevel, AlarmModelTimeWindow.AlarmModelTimeWindowRolling5);
        this.statusAvg30 = this.device.getAlarmStatus(DataTypeModelKey.soundPressureLevel, AlarmModelTimeWindow.AlarmModelTimeWindowRolling30);
        this.statusValue = this.device.getAlarmStatus(DataTypeModelKey.soundPressureLevel, AlarmModelTimeWindow.AlarmModelTimeWindowInstant);
    }

    private fetchDevice() {
        // Fetch bucket of data of last hour (12 buckets)
        this.projectDeviceService.get(this.device.id).subscribe(
            (data: ProjectDeviceModel) => {
                this.device = Object.assign(new ProjectDeviceModel(), data);
                this.refreshAlarms();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    private fetchData() {
        // Fetch bucket of data of last hour (12 buckets)
        this.dataService
            .getDeviceDataSample(this.device.id, [DataTypeModelKey.soundPressureLevel], 12, true)
            .subscribe(
                (data: DeviceAggregatedData) => {
                    this.deviceData = data[DataTypeModelKey.soundPressureLevel];
                    // From API data are coming from the most recent to the latest, reverting the order.
                    this.deviceData.sort(
                        (d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime(),
                    );
                    this.createSensorData();
                    this.loadingData = false;
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                    this.loadingData = false;
                },
            );
    }

    private createSensorData() {
        if (!this.deviceData) {
            return;
        }

        this.chart.options.series[0].data = [];

        this.deviceData.forEach(data => {
            let value = Math.round(data.aggregation.average * 10) / 10;
            let date = moment(data.date).format('HH:mm');
            this.chart.options.series[0].data.push([date, value]);
        });

        this.chart.update = true;
        this.chart.visible = true;
    }
}
