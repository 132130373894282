import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ProjectModel } from 'app/models/project.model';
import { ProjectService } from 'app/services/api/project.service';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ProjectResolver implements Resolve<Observable<ProjectModel>> {
    constructor(
        private projectService: ProjectService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') === "new") {
            return of(new ProjectModel());
        }

        return this.projectService.get(route.paramMap.get('id')).pipe(mergeMap(device => {
            if (device) { 
                return of(device) 
            } else {
                this.router.navigate(['/'])
                return EMPTY;
            }
        }));
    }
}
