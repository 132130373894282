<div class="emma-card__header">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <a [routerLink]="'/device/' + device.id">{{ device?.deviceName }}</a> -
            {{'COMPONENT.CellularSignalLevel' | translate}}
        </div>
        <app-device-view-list *ngIf="editGraphViews" [device]="device" [dataType]="dataType"></app-device-view-list>
    </div>
</div>

<div class="emma-card__content" *ngIf="device.lastData && device.lastData.cellullarSignalLevel">
    <small>{{ device?.lastData?.cellullarSignalLevel?.timestamp | date:'medium' }}</small>
    <h2 [ngClass]="{'orange-fg': statusValue === 'warning', 'red-fg': statusValue==='alarm'}">
        {{ device?.lastData?.cellullarSignalLevel?.value | number: '1.0-1' }}%</h2>
    <div>
        <span
            [ngClass]="{'text-semibold': true, 'orange-fg': statusAvg30 === 'warning', 'red-fg': statusAvg30==='alarm'}">
            {{ device?.dataAggregation30?.cellullarSignalLevel.average | number: '1.0-1' }}%
        </span>
        {{'COMPONENT.Average30' | translate}}
    </div>
</div>

<div class="emma-card__content emma-card__content--center" *ngIf="!device.lastData">
    <small>{{'COMPONENT.NoData' | translate}}</small>
    <div>
        <b>
            {{'COMPONENT.DeviceDisconnected' | translate}}
        </b>
    </div>
</div>


<div class="emma-card__footer-full-width" *ngIf="device.lastData">
    <highcharts-chart *ngIf="chart.visible" [Highcharts]="Highcharts" [constructorType]="chart.constructor" [options]="chart.options"
    [(update)]="chart.update" style="width: 100%; display: block;"></highcharts-chart>
</div>

<div *ngIf="!device.connected && device.lastData" class="emma-card__offline">
	{{ 'COMPONENT.DeviceOffline' | translate }}
</div>