import { DataTypeModelKey } from './dataType.model';

export class ProjectViewModelEntry {
    projectDeviceId: string;
    dataType: DataTypeModelKey;
    cols: number;
    rows: number;
    x: number;
    y: number;
}

export class ProjectViewModel {
    id: string;
    name: string;
    entries: ProjectViewModelEntry[];
}
