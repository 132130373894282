const ɵ0 = [];
export const chartFreeStorage5MinutesCard = {
    constructor: "chart",
    options: {
        chart: {
            type: "areaspline",
            height: 170,
            margin: [12, -4, 0, -4],
            backgroundColor: 'transparent'
        },
        rangeSelector: {
            enabled: false
        },
        tooltip: {
            valueSuffix: "%"
        },
        plotOptions: {
            line: {
                gapSize: 0,
                gapUnit: "value",
                dataGrouping: {
                    enabled: false,
                }
            },
            series: {
                marker: {
                    enabled: true,
                    states: {
                        hover: {
                            enabled: true,
                            radiusPlus: 2,
                            lineWidthPlus: 2
                        }
                    }
                },
                states: {
                    hover: {
                        lineWidth: 2
                    }
                }
            }
        },
        xAxis: {
            visible: false
        },
        yAxis: [{
                visible: false,
                floor: 0,
                ceiling: 105,
                max: 100,
                min: 0,
            }],
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false,
        },
        title: {
            text: '',
        },
        series: [
            {
                data: ɵ0,
                name: "Free Storage",
                lineWidth: 3,
                color: '#0C65E8',
                states: {
                    hover: {
                        enabled: true,
                    }
                },
                visible: true,
            },
        ]
    },
    update: true,
    visible: false
};
export { ɵ0 };
