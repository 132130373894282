import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { chartReport } from 'app/chart-config/chart-report';
import { AlarmModel } from 'app/models/alarm.model';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceDataModel } from 'app/models/deviceData.model';
import { ProjectModel } from 'app/models/project.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { AlarmService } from 'app/services/api/alarm.service';
import { DataService } from 'app/services/api/data.service';
import { ManufacturerService } from 'app/services/api/manufacturer.service';
import { ProjectService } from 'app/services/api/project.service';
import { ProjectAlarmService } from 'app/services/api/projectAlarm.service';
import { EmmaDateAdapter } from 'app/shared/date.adapter';
import * as Highcharts from 'highcharts/highcharts';
import { forEach } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-device-report',
    templateUrl: './device-report.component.html',
    styleUrls: ['./device-report.component.scss'],
})

export class DeviceReportComponent implements OnInit {
    @Input()
    device: ProjectDeviceModel;
    project: ProjectModel;

    generated: boolean = false;

    startDate = moment().subtract(1, 'days');
    startTime = "07:00";
    endDate = moment();
    endTime = "07:00";
    dataField: DataTypeModelKey = DataTypeModelKey.soundPressureLevel;
    showL10 = true;
    showL90 = true;
    brand: string = '';
    customAlarm: number = 0;
    showLEQ30 = true;
    showLEQ5 = false;
    showComments = true;
    observation: string = '';

    loadingData = false;
    deviceData: DeviceDataModel[];
    alarms: AlarmModel[];

    Highcharts = Highcharts; // required
    chart = JSON.parse(JSON.stringify(chartReport));


    constructor(
        private dataService: DataService,
        private manufacturerService: ManufacturerService,
        private alarmsService: AlarmService,
        private readonly projectService: ProjectService,
        private toastr: ToastrService,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,

    ) { }

    ngOnInit() {
        console.log(this.device);

        this.projectService.get(this.device.project).subscribe(val => {
            this.project = val;
            let format = this.project.dateformat;
            if (format) {
                format = format.toUpperCase();
                let formats = format.split(" ");
                EmmaDateAdapter.dateFormat = formats[0];
            }
        });

        this.manufacturerService.get(this.device.manufacturer).subscribe(val => {
            this.brand = val.name;
        });

        this.alarmsService.getAllDevice(this.device.id).subscribe(val => {
            forEach(val, (alarm) => {
                alarm.selected = false;
            });
            this.alarms = val;
        });
    }

    public generateReport() {
        this.generated = false;
        this.loadingData = true;

        let start = this.startTime.split(":");
        if (start.length != 2) {
            this.loadingData = false;
            return;
        }
        if (!this.startDate) {
            this.startDate = moment();
        }
        this.startDate.set('hour', parseInt(start[0]));
        this.startDate.set('minute', parseInt(start[1]));

        let end = this.endTime.split(":");
        if (end.length != 2) {
            this.loadingData = false;
            return;
        }
        if (!this.endDate) {
            this.endDate = moment();
        }
        this.endDate.set('hour', parseInt(end[0]));
        this.endDate.set('minute', parseInt(end[1]));


        this.dataService
            .getDeviceDataRange(
                this.device.id,
                [this.dataField],
                this.startDate.toDate(),
                this.endDate.toDate(),
                true,
            )
            .subscribe(
                data => {
                    this.deviceData = data[this.dataField];
                    // From API data are coming from the most recent to the latest, reverting the order.
                    this.deviceData.sort(
                        (d1, d2) => new Date(d1.date).getTime() - new Date(d2.date).getTime(),
                    );

                    //Draw the graph
                    this.createSensorData();
                    this.loadingData = false;
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                    if (error.error.statusCode === 404) {
                        this.router.navigate(['/devices']);
                    }
                    this.loadingData = false;
                },
            );
    }


    createSensorData() {
        if (!this.deviceData) {
            return;
        }

        //Create 30 minutes aggregation
        const groupedData = this.deviceData.reduce((groups, data) => {
            const date = new Date(data.date);
            const minutes = date.getMinutes();
            const roundedMinutes = Math.floor(minutes / 30) * 30; // Round minutes down to nearest 30
            const roundedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), roundedMinutes);
            const key = roundedDate.toISOString();
            if (!groups[key]) {
                groups[key] = { date: roundedDate, values: [] };
            }
            groups[key].values.push(data.aggregation.average);
            return groups;
        }, {});
        //Calculate average for each group
        const aggregatedData = Object.values(groupedData).map((group: any) => {
            const sum = group.values.reduce((total: number, value: number) => total + Math.pow(10, value / 10), 0);
            const average = Math.log10(sum / group.values.length) * 10;
            return { date: group.date, value: average };
        });

        //Clean data array
        this.chart.options.series[0].data = [];
        this.chart.options.series[1].data = [];

        if (this.showLEQ30 == true) {
            aggregatedData.forEach(data => {
                let value = Math.round(data.value * 10) / 10;
                let date = new Date(data.date).getTime();
                this.chart.options.series[0].data.push([date, value]);
            });
        }

        if (this.showLEQ5 == true) {
            this.deviceData.forEach(data => {
                let value = Math.round(data.aggregation.average * 10) / 10;
                let date = new Date(data.date).getTime();
                this.chart.options.series[1].data.push({ x: date, y: value });
            });
        }


        let plotLines = [];
        this.alarms.forEach(alarm => {
            if (alarm.selected) {
                plotLines.push({
                    value: alarm.limit,
                    color: 'red',
                    dashStyle: 'shortdash',
                    width: 1,
                    zIndex: 10,
                });
            }
        })
        if (this.customAlarm > 0) {
            plotLines.push({
                value: this.customAlarm,
                color: 'red',
                dashStyle: 'shortdash',
                width: 1,
                zIndex: 10,
            });
        }
        this.chart.options.yAxis[0].plotLines = plotLines;


        this.chart.update = true;
        this.chart.visible = true;
        this.generated = true;
    }

    get validCoordinates() {
        return this.latitudeValid && this.longitudeValid;
    }

    get latitude() {
        return this.latitudeValid ? this.device.location.latitude : 0;
    }

    get longitude() {
        return this.longitudeValid ? this.device.location.longitude : 0;
    }

    get zoom() {
        return this.validCoordinates ? 12 : 0;
    }

    get showPicture() {
        return (this.device.picture && this.device.picture.length !== 0) || (this.device.baseDeviceData.picture && this.device.baseDeviceData.picture.length !== 0);
    }

    get picture() {
        if (this.device.picture && this.device.picture.length !== 0)
            return this.device.picture;
        else
            return this.device.baseDeviceData.picture;
    }

    private get latitudeValid() {
        return (
            this.device.location.latitude &&
            this.device.location.latitude >= -90 &&
            this.device.location.latitude <= 90
        );
    }

    private get longitudeValid() {
        return (
            this.device.location.longitude &&
            this.device.location.longitude >= -90 &&
            this.device.location.longitude <= 90
        );
    }
}
