import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ContextService {
    private _onForceDataRefresh = new Subject();
    
    public onCurrentProjectChange = new BehaviorSubject(this.currentProject);
    public onKioskModeChange = new BehaviorSubject(this.isKioskModeEnabled);
    public onForceDataRefresh = this._onForceDataRefresh.asObservable();

    forceRefresh() {
        this._onForceDataRefresh.next();
    }

    set currentProject(project: string) {
        localStorage.setItem('project', project);
        this.onCurrentProjectChange.next(project);
    }

    get currentProject(): string {
        const project = localStorage.getItem('project');
        return project ? project : null;
    }

    set preferredLanguage(langId: string) {
        localStorage.setItem('lang', langId);
    }

    get preferredLanguage(): string {
        return localStorage.getItem('lang');
    }

    set isKioskModeEnabled(isEnabled: boolean) {
        localStorage.setItem('kioskMode', isEnabled.toString());
        this.onKioskModeChange.next(isEnabled);
    }

    get isKioskModeEnabled(): boolean {
        const isKioskMode = localStorage.getItem('kioskMode') === 'true';
        return isKioskMode;
    }
}
