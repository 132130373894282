import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router, private authenticationService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // this will be passed from the route config on the data property
        const adminRoute = route.url[0].path === 'admin';

        if (this.authenticationService.currentUser) {
            if (adminRoute && !this.authenticationService.currentUser.isAdmin) {
                // not admin, so we go back to home
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
