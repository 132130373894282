<div class="dialog-content-wrapper">
    <!-- Header start -->
    <mat-toolbar class="mat-accent">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span *ngIf="action == 'NEW'" class="title dialog-title">{{
                'EVENTS.NewEvent' | translate
            }}</span>
            <span *ngIf="action == 'EDIT' || action == 'READ'" class="title dialog-title">{{
                'EVENTS.EventDetail' | translate
            }}</span>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- Header end -->

    <mat-dialog-content fusePerfectScrollbar>
        <form name="eventForm" [formGroup]="eventForm" class="event-form w-100-p" fxLayout="column" fxFlex>
            <!-- Event type -->
            <h3>{{ 'EVENTS.EventType' | translate }} {{ 'TYPE.' + event.type | translate }}</h3>

            <!-- Severity select -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'EVENTS.Severity' | translate }}</mat-label>
                <mat-select formControlName="severity" placeholder="Severity" required [disabled]="action == 'READ'">
                    <mat-option *ngFor="let severity of severityValues" [value]="severity">
                        {{ 'SEVERITY.' + severity | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Device Select (empty option) -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'EVENTS.Device' | translate }}</mat-label>
                <mat-select formControlName="deviceId" placeholder="Select device"
                    [disabled]="action == 'READ' || action == 'EDIT'">
                    <mat-option value="" selected hidden></mat-option>
                    <mat-option *ngFor="let device of availableDevices" [value]="device.id">
                        {{ device.deviceName }} -​ {{ device.baseDeviceData?.model }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Event Start -->
            <div class="row">
                <!-- Start Date -->
                <mat-form-field>
                    <input matInput formControlName="startDate" [matDatepicker]="start" placeholder="Start Date"
                        [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="start" [disabled]="action == 'READ'">
                    </mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                </mat-form-field>

                <!-- Start Time -->
                <mat-form-field>
                    <input matInput formControlName="startTime" placeholder="End Time" type="time"
                        [readonly]="action == 'READ'" />
                </mat-form-field>
            </div>

            <!-- Event End -->
            <div class="row">
                <!-- End Date -->
                <mat-form-field>
                    <input matInput formControlName="endDate" [matDatepicker]="end" placeholder="End Date"
                        [readonly]="true" />
                    <mat-datepicker-toggle matSuffix [for]="end" [disabled]="action == 'READ'"></mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                </mat-form-field>

                <!-- End Time -->
                <mat-form-field>
                    <input matInput formControlName="endTime" placeholder="End Time" type="time"
                        [readonly]="action == 'READ'" />
                </mat-form-field>
            </div>

            <mat-label color="warn" *ngIf="eventForm.errors?.timeOrderError">{{
                'EVENTS.EventTimeError' | translate
            }}</mat-label>

            <!-- Description -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'EVENTS.Description' | translate }}</mat-label>
                <textarea matInput name="description" formControlName="description"
                    placeholder="{{ 'EVENTS.Description' | translate }}" [readonly]="action == 'READ'">
                </textarea>
            </mat-form-field>

            <!-- Actions -->
            <div class="form-action">
                <button *ngIf="action == 'NEW'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    [disabled]="eventForm.invalid" ngsw>
                    {{ 'EVENTS.ADD' | translate }}
                </button>
                <button *ngIf="action == 'EDIT'" mat-raised-button class="button-save" color="primary" (click)="save()"
                    [disabled]="eventForm.invalid" ngsw>
                    {{ 'EVENTS.SAVE' | translate }}
                </button>
                <button *ngIf="action != 'READ'" mat-raised-button (click)="cancel()">
                    {{ 'EVENTS.CANCEL' | translate }}
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>