import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DeviceModel, DeviceType } from 'app/models/device.model';
import { ManufacturerModel } from 'app/models/manufacturer.model';
import { DeviceService } from 'app/services/api/device.service';
import { ManufacturerService } from 'app/services/api/manufacturer.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { UploadUtils } from 'app/shared/upload.utils';

@Component({
    selector: 'app-device-admin',
    templateUrl: './deviceAdmin.component.html',
    styleUrls: ['./deviceAdmin.component.scss'],
    animations: fuseAnimations,
})
export class DeviceAdminComponent implements OnInit {
    device: DeviceModel;
    deviceForm: FormGroup;
    error: string;
    pageType: string;
    manufacturers: ManufacturerModel[];
    selectedManufacturer: ManufacturerModel;
    updatingModel: boolean;
    savedChangesString: string;
    avaliableDeviceType = [DeviceType.NoiseSensor, DeviceType.AcousticLimiter];
    editImage: boolean;

    public uploadedFile: File = undefined;
    public imageUrl: any = undefined;

    constructor(
        private deviceService: DeviceService,
        private manufacturerService: ManufacturerService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private translation: FuseTranslationLoaderService,
        private toastr: ToastrService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.pageType = 'new';

        translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
    }

    ngOnInit() {
        this.device = this.route.snapshot.data.data;
        if (this.device.id) {
            this.pageType = 'edit';
        }

        this.createForm();
        this.updateModel();
        this.updateManufacturerData();
        this.updateForm();
    }

    add() {
        const deviceSave = this.createSaveDevice();

        console.log(deviceSave);

        this.deviceService.create(deviceSave).subscribe(
            data => {
                this.toastr.success();
                this.device = data;
                this.updateModel();
                this.updateForm();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    save() {
        const deviceEdit = this.createEditDevice();

        this.deviceService.edit(deviceEdit).subscribe(
            data => {
                this.toastr.success(this.savedChangesString);
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/devices']);
                }
            },
        );
    }

    cancel() {
        this.router.navigate(['/admin/devices']);
    }

    removeImage() {
        this.uploadedFile = undefined;
        this.imageUrl = undefined;

        this.editImage = true;

        this.deviceService.removeImage(this.device.id).subscribe(
            () => {
                this.toastr.success(this.savedChangesString);
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/devices']);
                }
            },
        );
    }

    imageDropped(files: NgxFileDropEntry[]) {
        this.editImage = true;

        const uploadedImage = files[0];
        // Check image
        // Is it a file?
        if (uploadedImage.fileEntry.isFile) {
            let fileEntry = uploadedImage.fileEntry as FileSystemFileEntry;
            fileEntry.file(file => {
                if (!UploadUtils.isFileAllowed(file)) {
                    alert('File is not a supported image');
                    return;
                }
                if (!UploadUtils.isFileSizeAllowed(file)) {
                    alert('File Size is larger than 2 MB');
                    return;
                }
                this.uploadedFile = file;

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.imageUrl = reader.result;
                };

                this.deviceService.addImage(this.device.id, this.uploadedFile).subscribe(
                    () => {
                        this.toastr.success(this.savedChangesString);
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Error: ' + error.message);
                        if (error.error.statusCode === 404) {
                            this.router.navigate(['/admin/devices']);
                        }
                    },
                );
            });
        } else {
            // It was a directory (nothing to be done)
        }
    }

    /**
     * Private methods
     */
    private createEditDevice() {
        const deviceEdit = Object.assign(new DeviceModel(), this.deviceForm.value);
        delete deviceEdit.manufacturer;
        delete deviceEdit.model;
        delete deviceEdit.serialNumber;
        delete deviceEdit.protocol;
        delete deviceEdit.picture;
        return deviceEdit;
    }

    private createSaveDevice() {
        const deviceSave = Object.assign(this.device, this.deviceForm.value);
        deviceSave.manufacturer = deviceSave.manufacturer.id;
        return deviceSave;
    }

    private createForm() {
        this.deviceForm = this.formBuilder.group({
            id: [this.device.id],
            name: [this.device.name, [Validators.required]],
            model: [this.device.model, [Validators.required]],
            serialNumber: [this.device.serialNumber, [Validators.required]],
            dataFrequency: [this.device.dataFrequency, [Validators.required, Validators.min(0)]],
            deviceType: [this.device.deviceType, [Validators.required]],
            manufacturer: [[Validators.required]],
            protocol: [{ disabled: false }, [Validators.required]],
            simOrImei: [this.device.simOrImei],
            lastCalibrationDate: [this.device.lastCalibrationDate],
        });
    }

    private updateForm() {
        if (this.pageType === 'edit') {
            this.deviceForm.get('id').setValue(this.device.id);
            this.deviceForm.get('serialNumber').disable();
            this.deviceForm.get('model').disable();
            this.deviceForm.get('manufacturer').disable();
            this.deviceForm.get('protocol').disable();

            this.deviceForm.valueChanges
                .pipe(debounce(() => timer(this.updatingModel ? 0 : 1000)))
                .subscribe(val => {
                    if (this.updatingModel) return;
                    this.save();
                });
        }
    }

    private updateModel() {
        this.updatingModel = true;
        if (this.device.id) {
            this.pageType = 'edit';
            this.imageUrl = this.device.picture;
        }
        this.error = null;
        this.updatingModel = false;
    }

    private updateManufacturerData() {
        this.manufacturerService.getAll().subscribe(
            data => {
                this.updatingModel = true;
                this.manufacturers = data;

                if (this.pageType === 'edit') {
                    const manufacturer = this.manufacturers.find(
                        obj => obj.id === this.device.manufacturer,
                    );
                    const protocol = manufacturer.protocols.find(obj => {
                        return (
                            obj.name === this.device.protocol.name &&
                            obj.endpoint === this.device.protocol.endpoint
                        );
                    });
                    this.selectedManufacturer = manufacturer;
                    this.deviceForm.get('manufacturer').setValue(manufacturer);
                    this.deviceForm.get('protocol').setValue(protocol);
                }
                setTimeout(() => {
                    this.updatingModel = false;
                }, 10);
            },
            error => {
                this.toastr.error('Error: ' + error.message);
                console.log(error);
            },
        );
    }
}
