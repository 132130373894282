import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectModel } from 'app/models/project.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DeviceService } from 'app/services/api/device.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ToastrService } from 'ngx-toastr';
import { locale as catalan } from '../../../i18n/ca';
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { ProjectDeviceAddEditComponent } from '../project-device-add-edit/project-device-add-edit.component';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-project-devices',
    templateUrl: './project-devices.component.html',
    styleUrls: ['./project-devices.component.scss'],
    animations: fuseAnimations,
})
export class ProjectDevicesComponent implements OnInit {
    @Input()
    project: ProjectModel;
    private dialogRef: any;
    public devices: ProjectDeviceModel[];

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    confirmString: string;

    public displayedColumns = ['name', 'image', 'address', 'comment', 'active', 'buttons'];

    constructor(
        private readonly projectDeviceService: ProjectDeviceService,
        private readonly deviceService: DeviceService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.projectDeviceService.getAll(this.project.id).subscribe(
            devices => {
                this.devices = devices;
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    activateDevice(device: ProjectDeviceModel) {
        this.deviceService.geActivated(device.baseDevice).subscribe(activeDevice => {
            if (activeDevice && activeDevice.projectDeviceId) {
                const message = this._fuseTranslationLoaderService.translateService.instant(
                    'DEVICES.ConfirmActivate',
                    {
                        project: activeDevice.projectName,
                        deviceName: activeDevice.projectDevicename
                            ? activeDevice.projectDevicename
                            : activeDevice.deviceName,
                    },
                );
                this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                    disableClose: false,
                });
                this.confirmDialogRef.componentInstance.confirmMessage = message;
                this.confirmDialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.activateDeviceAction(device);
                    }
                    this.confirmDialogRef = null;
                });
            } else {
                this.activateDeviceAction(device);
            }
        });
    }

    private activateDeviceAction(device: ProjectDeviceModel) {
        this.projectDeviceService.activate(device.id).subscribe(
            () => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    deactivateDevice(device: ProjectDeviceModel) {
        this.projectDeviceService.deactivate(device.id).subscribe(
            () => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    deleteDevice(device: ProjectDeviceModel) {
        this.projectDeviceService.delete(device.id).subscribe(
            () => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    addEditDevice(device?: ProjectDeviceModel): void {
        const data = device
            ? { action: 'edit', device: device }
            : { action: 'new', devices: this.devices, project: this.project };
        this.dialogRef = this._matDialog.open(ProjectDeviceAddEditComponent, {
            data,
        });

        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }

            const addEditDevice = response.device;
            const file = response.file;
            const { editImage } = response;
            const success = () => {
                this.loadData();
            };

            const fail: (error: any) => void = error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/projects']);
                }
            };
            if (response.action === 'new') {
                if (!file) {
                    this.projectDeviceService
                        .create(this.project.id, addEditDevice)
                        .subscribe(success, fail);
                    return;
                }
                this.projectDeviceService
                    .create(this.project.id, addEditDevice)
                    .subscribe((projectDevice: ProjectDeviceModel) => {
                        if (file) {
                            this.projectDeviceService
                                .addImage(projectDevice.id, file)
                                .subscribe(success, fail);
                        } else {
                            success();
                        }
                    }, fail);
            } else if (response.action === 'edit') {
                const op = [];
                // Edit the device anyway
                op.push(this.projectDeviceService.edit(this.project.id, addEditDevice));

                if (editImage) {
                    if (file) {
                        // Image must be replaced
                        op.push(
                            this.projectDeviceService.addImage(
                                addEditDevice.id,
                                file,
                            ),
                        );
                    } else {
                        // Image must be removed
                        op.push(
                            this.projectDeviceService.removeImage(
                                addEditDevice.id,
                            ),
                        );
                    }
                }

                forkJoin(op).subscribe(success, fail);
            }
        });
    }
}
