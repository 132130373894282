export const locale = {
    lang: 'en',
    data: {
        COMPONENT: {
            NoiseLevel: 'Noise Level',
            Battery: 'Battery',
            Octave: 'Octave Spectra',
            Average5: '5 minutes average',
            Average30: '30 minutes average',
            NoData: 'No data yet',
            DeviceDisconnected: 'Make sure device is connected',
            DeviceOffline: 'This device is offline',
            CellularSignalLevel: 'Cellular Signal Level',
            FreeStorage: 'Free Storage',
        },
        EVENTS: {
            Events: 'Events',
            Severity: 'Severity',
            Type: 'Type',
            Device: 'Device',
            StartTime: 'Start Time',
            EndTime: 'End Time',
            Description: 'Description',
            Remove: 'Remove',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            EventType: 'Event type: ',
            NewEvent: 'New Event',
            EventDetail: 'Event Detail',
            EventTimeError: 'End date must be after start date',
            Alarm: 'Alarm',
            AckUser: 'Ack by',
        },
        SEVERITY: {
            alarm: 'Alarm',
            info: 'Information',
            success: 'Success',
            warning: 'Warning',
        },
        TYPE: {
            device_alarm_off: 'Alarm Off',
            device_alarm_on: 'Alarm On',
            device_added: 'Device Added',
            device_offline: 'Device Offline',
            device_online: 'Device Online',
            device_removed: 'Device Removed',
            user_defined: 'User Defined',
            device_warning_off: 'Warning Off',
            device_warning_on: 'Warning On',
            device_first_data: 'First Device Data',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Name',
            Description: 'Description',
            TimeWindow: 'Time Window',
            Samples: 'Samples',
            Threshold: 'Threshold',
            Limit: 'Limit',
            Severity: 'Severity',
            Field: 'Field',
            Remove: 'Remove',
            time_window_instant: 'Instant',
            time_window_rolling_5: '5 minutes rolling average',
            time_window_rolling_30: '30 minutes rolling average',
            time_window_bucket_5: '5 minutes bucket',
            threshold_cross_over: 'Cross OVER',
            threshold_cross_below: 'Cross BELOW',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            AlarmDetail: 'Alarm Detail',
            NewAlarm: 'New Alarm',
            Notify: 'Notify',
            Silent: 'Silent',
            NeedACK: 'Need ACK',
            NewNotificationItem: 'email/phone',
            IgnoredDevices: 'Ignored Devices',
        },
        FIELD: {
            sound_pressure_level: 'Sound Pressure',
            battery: 'Battery',
            power_supply_status: 'Power Supply',
            spectrum_13_octave: '1/3 Octave Spectrum',
            temperature: 'Ext. Temperature',
            overload: 'Overload',
            underrange: 'Underrange',
            wifi_signal_level: 'WiFi Signal',
            cellullar_signal_level: 'Cellular Signal',
        },
    },
};
