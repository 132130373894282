import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { TokenService } from '../../services/token.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the auth token from the service.
        if (this.tokenService.token) {
            const authToken = this.tokenService.token.token;

            // Clone the request and set the new header in one step.
            if (authToken) {
                const authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + authToken } });

                // send cloned request with header to the next handler.
                return next.handle(authReq);
            }
        }

        const newReq = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
        return next.handle(newReq);
    }
}
