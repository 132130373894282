<mat-table class="project-device-table" #table [dataSource]="devices" [@animateStagger]="{ value: '50' }"
    fusePerfectScrollbar>
    <!-- Name Col -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{ 'DEVICES.Name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let device">
            <p class="text-truncate">{{ device.deviceName }}</p>
        </mat-cell>
    </ng-container>

    <!-- Image Column -->
    <ng-container matColumnDef="image">
        <mat-header-cell class="cell-icon" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-icon" *matCellDef="let device">
            <emma-device-type-icon [deviceType]="device?.baseDeviceData?.deviceType"></emma-device-type-icon>
        </mat-cell>
    </ng-container>

    <!-- Address col -->
    <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef>{{ 'DEVICES.Address' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let device">
            <p class="text-truncate">{{ device.location?.address }}</p>
        </mat-cell>
    </ng-container>

    <!-- Comment Col -->
    <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef>{{ 'DEVICES.Comments' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let device">
            <p class="text-truncate">{{ device.comments }}</p>
        </mat-cell>
    </ng-container>

    <!-- Active Col -->
    <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
            {{ 'DEVICES.Active' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let device" fxHide fxShow.gt-xs>
            <emma-check [active]="device?.active"></emma-check>
        </mat-cell>
    </ng-container>

    <!-- Extra buttons column -->
    <ng-container matColumnDef="buttons">
        <mat-header-cell class="cell-check" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="cell-check" *matCellDef="let device">
            <div fxFlex="row" fxLayoutAlign="end center">
                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                    (click)="$event.stopPropagation()">
                    <mat-icon class="secondary-text">more_vert</mat-icon>
                </button>
                <!-- Dotted menu entries -->
                <mat-menu #moreMenu="matMenu">
                    <button *ngIf="device.active" mat-menu-item aria-label="deactivate"
                        (click)="deactivateDevice(device)">
                        <mat-icon>cancel</mat-icon>
                        <span>{{ "DEVICES.Deactivate" | translate }}</span>
                    </button>
                    <button *ngIf="!device.active" mat-menu-item aria-label="activate" (click)="activateDevice(device)">
                        <mat-icon>check_circle</mat-icon>
                        <span>{{ "DEVICES.Activate" | translate }}</span>
                    </button>
                    <button mat-menu-item aria-label="remove" (click)="deleteDevice(device)" [disabled]="device.active">
                        <mat-icon>delete</mat-icon>
                        <span>{{ "DEVICES.Remove" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let device; columns: displayedColumns" class="device row-pointer" matRipple
        (click)="addEditDevice(device)"></mat-row>
</mat-table>
<div class="add-button">
    <button mat-fab id="add-device-button" aria-label="add device" (click)="addEditDevice()"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</div>