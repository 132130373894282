import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DeviceService } from 'app/services/api/device.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { UploadUtils } from 'app/shared/upload.utils';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-device-file',
    templateUrl: './device-file.component.html',
    styleUrls: ['./device-file.component.scss'],
})
export class DeviceFileComponent implements OnInit {
    @Input() device: ProjectDeviceModel;
    public uploadedFile: File = undefined;
    public imageUrl: any = undefined;
    savedChangesString: string;
    displayedColumns = [
        'name',
        'status',
        'uploadDate',
    ];

    constructor(
        private deviceService: DeviceService,
        private deviceProjectService: ProjectDeviceService,
        private toastr: ToastrService,
        private translation: FuseTranslationLoaderService,
        private router: Router,
    ) {
        this.translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
    }

    ngOnInit() {
        console.log(this.device);
    }

    getDevice() {
        this.deviceProjectService.get(this.device.id).subscribe(
            device => {
                this.device = device;
                console.log(this.device);
            },
            error => {
                console.log(error);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/devices']);
                }
            },
        );
    }

    fileDropped(files: NgxFileDropEntry[]) {
        const uploadedImage = files[0];
        // Is it a file?
        if (uploadedImage.fileEntry.isFile) {
            let fileEntry = uploadedImage.fileEntry as FileSystemFileEntry;
            fileEntry.file(file => {
                if (!UploadUtils.isDataFileAllowed(file)) {
                    alert('File is not a supported excel file');
                    return;
                }
                if (!UploadUtils.isFileSizeAllowed(file)) {
                    alert('File Size is larger than 2 MB');
                    return;
                }
                this.uploadedFile = file;

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.imageUrl = reader.result;
                };

                this.deviceProjectService.addFile(this.device.id, this.uploadedFile).subscribe(
                    () => {
                        this.toastr.success(this.savedChangesString);
                        this.getDevice();
                    },
                    error => {
                        console.log(error);
                        this.toastr.error('Error: ' + error.message);
                        if (error.error.statusCode === 404) {
                            this.router.navigate(['/admin/devices']);
                        }
                    },
                );
            });
        } else {
            // It was a directory (nothing to be done)
        }
    }
}
