<div class="emma-card auto-width">
    <!-- CENTER -->
    <div class="center" fxLayout="column">
        <div class="filter-container" fxLayout="row" fxLayoutAlign="start" fxFlex="1 0 auto">
            <!-- TODO: translate -->
            <p>
                View:
            </p>
            <div fxLayout="row">
                <div *ngFor="let filter of filters" fxLayout="row" fxLayoutAlign="start center" class="filter-item">
                    <mat-checkbox (change)="onSelectFilter(filter)"></mat-checkbox>
                    <app-alarm-type-span [severity]="filter"></app-alarm-type-span>
                    <!-- <p class="text-truncate">{{ 'SEVERITY.' + event.severity | translate }}</p> -->
                    <p class="text-truncate">{{ filter }}</p>
                </div>
            </div>
        </div>

        <!-- CONTENT CARDS -->
        <div class="content-card" [hidden]="pendingAckEventsPage?.total<=0">
            <app-event-list [events]="pendingAckEventsPage?.data" [showDevice]="false" [allowsEdit]="false"
                            (onEventACK)="ackEvent($event)">
            </app-event-list>
            <mat-paginator #pendingAckPaginator [length]="pendingAckEventsPage?.total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>

        <div class="content-card">
            <app-event-list [events]="resolvedAckEventsPage?.data" [showDevice]="false" [allowsEdit]="false"
                (onEventACK)="ackEvent($event)">
            </app-event-list>
            <mat-paginator #resolvedAckPaginator [length]="resolvedAckEventsPage?.total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>
        <!-- / CONTENT CARDS -->
    </div>
    <!-- / CENTER -->
</div>
