<div class="emma-card auto-width">
    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="h1 pr-16">{{ "DEVICE.Info" | translate }}</div>
    </div>

    <table class="simple clickable">
        <tbody>
            <tr>
                <td>{{ "DEVICE.Name" | translate }}</td>
                <td class="text-right">{{ device.deviceName }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.BaseName" | translate }}</td>
                <td class="text-right">{{ device.baseDeviceData?.name }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.SerialNumber" | translate }}</td>
                <td class="text-right">{{ device.baseDeviceData?.serialNumber }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.SimOrImei" | translate }}</td>
                <td class="text-right">{{ device.baseDeviceData?.simOrImei }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.LastCalibrationDate" | translate }}</td>
                <td class="text-right">{{ device.baseDeviceData?.lastCalibrationDate | date: 'mediumDate' }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.Latitude" | translate }}</td>
                <td class="text-right">{{ device.location?.latitude }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.Longitude" | translate }}</td>
                <td class="text-right">{{ device.location?.longitude }}</td>
            </tr>
            <tr>
                <td>{{ "DEVICE.Comments" | translate }}</td>
                <td class="text-right">{{ device.comments }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="showPicture" class="emma-card auto-width" fxLayout="column  " fxLayoutAlign="space-between center">
    <div class="p-16">
        <div class="h1 pr-16">{{ "DEVICE.Picture" | translate }}</div>
    </div>

    <cl-image public-id={{picture}}>
        <cl-transformation width="800" height="600" fetch-format="auto" crop="limit">
        </cl-transformation>
    </cl-image>

</div>

<div *ngIf="showPicture" class="break"></div>

<div class="emma-card auto-width" [ngClass]="{'last': showPicture}">
    <div fxLayout="row" fxLayoutAlign="space-between center last">
        <div class="h1">{{ "DEVICE.Location" | translate }}</div>
    </div>
    <agm-map [fitBounds]="true" [zoom]="zoom" [maxZoom]="16" [mapTypeControl]="false">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [agmFitBounds]="true"></agm-marker>
    </agm-map>
    <div class="address">
        {{ device.location?.address}}
    </div>
</div>