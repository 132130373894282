import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectModel } from 'app/models/project.model';
import { ProjectAlarmModel } from 'app/models/projectAlarm.model';
import { ProjectAlarmService } from 'app/services/api/projectAlarm.service';
import { ToastrService } from 'ngx-toastr';
import { FuseConfirmDialogComponent } from "../../../../../@fuse/components/confirm-dialog/confirm-dialog.component";
import { AlarmService } from "../../../../services/api/alarm.service";
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { AlarmAddEditComponent } from '../../../../components/alarm-add-edit/alarm-add-edit.component';

@Component({
    selector: 'app-project-alarm',
    templateUrl: './project-alarm.component.html',
    styleUrls: ['./project-alarm.component.scss'],
})
export class ProjectAlarmComponent implements OnInit {
    @Input() project: ProjectModel;
    alarms: ProjectAlarmModel[] = [];
    private dialogRef: any;

    constructor(
        private projectAlarmService: ProjectAlarmService,
        private alarmService: AlarmService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _matDialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.projectAlarmService.getAllProject(this.project.id).subscribe(
            alarms => {
                this.alarms = alarms;
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    onAlarmClick(alarm: ProjectAlarmModel) {
        const data = { alarm, project: this.project };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            const alarm = result.alarm;
            this.projectAlarmService.edit(this.project.id, alarm).subscribe(
                data => {
                    this.loadData();
                },
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    addAlarm() {
        const data = { project: this.project };
        this.dialogRef = this._matDialog.open(AlarmAddEditComponent, { data });
        this.dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            const alarm: ProjectAlarmModel = result.alarm;
            this.projectAlarmService.create(this.project.id, alarm).subscribe(
                () => this.loadData(),
                error => {
                    console.log(error);
                    this.toastr.error('Error: ' + error.message);
                },
            );
        });
    }

    public onAlarmDelete(alarm: ProjectAlarmModel) {
        this.projectAlarmService.getActiveEvents(this.project.id, alarm.id).subscribe(
            (result) => {
                if (result.activeEvents > 0) {
                    const message = this._fuseTranslationLoaderService.translateService.instant(
                        'DEVICE.ALARM.AlarmDeleteMessage',
                        {
                            activeEvents: result.activeEvents,
                        },
                    );
                    let confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                        disableClose: false,
                    });
                    confirmDialogRef.componentInstance.title = 'DEVICE.ALARM.AlarmDeleteTitle';
                    confirmDialogRef.componentInstance.confirmMessage = message;
                    confirmDialogRef.afterClosed().subscribe((confirm) => {
                        if (confirm) {
                            this.alarmDeleteAction(alarm);
                        }
                        confirmDialogRef = null;
                    });
                } else {
                    this.alarmDeleteAction(alarm);
                }
            }, (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public alarmDeleteAction(alarm) {
        this.projectAlarmService.delete(this.project.id, alarm.id).subscribe(
            () => this.loadData(),
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }
}
