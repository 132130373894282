import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DeviceModel } from 'app/models/device.model';
import { DeviceService } from 'app/services/api/device.service';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class DeviceResolver implements Resolve<Observable<DeviceModel>> {
    constructor(
        private deviceService: DeviceService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('id') === "new") {
            return of(new DeviceModel());
        }

        return this.deviceService.get(route.paramMap.get('id')).pipe(mergeMap(device => {
            if (device) { 
                return of(device) 
            } else {
                this.router.navigate(['/'])
                return EMPTY;
            }
        }));
    }
}