import { Component, Input, OnInit } from '@angular/core';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { ProjectViewModel } from 'app/models/projectView.model';
import { ProjectViewService } from 'app/services/api/projectView.service';

@Component({
    selector: 'app-device-view-list',
    templateUrl: './device-view-list.component.html',
    styleUrls: ['./device-view-list.component.scss'],
})
export class DeviceViewListComponent implements OnInit {
    @Input()
    device: ProjectDeviceModel;
    @Input()
    dataType: DataTypeModelKey;

    projectViews: ProjectViewModel[];

    constructor(private projectViewService: ProjectViewService) { }

    ngOnInit() {
        // First time loaded from the web
        this.loadData();

        this.projectViewService.onEditProjectView.subscribe(
            // At each modification, load it from the current cached list
            () => {
                this.projectViews = this.projectViewService.viewList;
            },
        );
    }

    private loadData() {
        this.projectViewService.getAll(this.device.project).subscribe(
            data => {
                this.projectViews = data;
            },
            err => console.log(err),
        );
    }

    private getView(id: string): ProjectViewModel {
        return this.projectViews.find(v => v.id === id);
    }

    deviceInView(view: ProjectViewModel): boolean {
        return view.entries.some(
            e => e.projectDeviceId === this.device.id && e.dataType === this.dataType,
        );
    }

    clickView(viewId: string, event: any) {
        // Inhibit menu closing
        event.stopPropagation();

        const view = this.getView(viewId);
        if (this.deviceInView(view)) {
            // remove
            const index = view.entries.findIndex(
                e => e.projectDeviceId === this.device.id && e.dataType === this.dataType,
            );
            if (index > -1) {
                view.entries.splice(index, 1);
            }
        } else {
            // add
            view.entries.push({ projectDeviceId: this.device.id, dataType: this.dataType, cols: 1, rows: 1, x: 0, y: 0 });
        }

        this.projectViewService
            .edit(this.device.project, view)
            .subscribe(null, err => console.log(err));
    }
}
