<div id="devices" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ 'DEVICES.Devices' | translate }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table class="devices-table" #table [dataSource]="projectDevices" [@animateStagger]="{ value: '50' }" fusePerfectScrollbar>
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'DEVICES.ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.id }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Image Column -->
                <ng-container matColumnDef="image">
                    <mat-header-cell class="cell-icon" *matHeaderCellDef fxHide fxShow.gt-xs></mat-header-cell>
                    <mat-cell class="cell-icon" *matCellDef="let device" fxHide fxShow.gt-xs>
                        <emma-device-type-icon [deviceType]="device.baseDeviceData?.deviceType"></emma-device-type-icon>
                    </mat-cell>
                </ng-container>

                <!-- Picture Column -->
                <ng-container matColumnDef="picture">
                    <mat-header-cell class="cell-picture" *matHeaderCellDef fxHide fxShow.gt-xs></mat-header-cell>
                    <mat-cell class="cell-picture" *matCellDef="let device" fxHide fxShow.gt-xs>
                        <cl-image *ngIf="getDevicePicture(device)" public-id={{getDevicePicture(device)}} style="border-radius:50%;">
                            <cl-transformation width="60" height="60" fetch-format="auto" crop="fill">
                            </cl-transformation>
                        </cl-image>
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'DEVICES.Name' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.deviceName }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Active Column -->
                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
                        <span>{{ 'DEVICES.Active' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let device" fxHide fxShow.gt-xs>
                        <emma-check [active]="device.active"></emma-check>
                    </mat-cell>
                </ng-container>

                <!-- Last Data Column -->
                <ng-container matColumnDef="last_data">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'DEVICES.LastData' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate" fxHide fxShow.gt-xs>{{ getLastDate(device) | date: 'medium' }}</p>
                        <p class="text-truncate" fxHide fxShow.xs>{{ getLastDate(device) | date: 'd/MM/yy' }}</p>
                    </mat-cell>
                </ng-container>

                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'DEVICES.Address' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let device">
                        <p class="text-truncate">{{ device.location?.address }}</p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let device; columns: displayedColumns" class="device" matRipple [routerLink]="'/device/' + device.id">
                </mat-row>
            </mat-table>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>