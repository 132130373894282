<div class="report-config print-hide">
    <div class="row">
        <div class="section">
            {{'REPORT.Dates' | translate}}
        </div>
        <div class="controls">
            <mat-form-field>
                <input matInput [matDatepicker]="start" placeholder="{{'DEVICE.StartDate' | translate}}"
                    [(ngModel)]="startDate" />
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="time" [(ngModel)]="startTime" placeholder="{{'DEVICE.StartTime' | translate}}"
                    step="300" />
            </mat-form-field>
            <mat-form-field>
                <input matInput [matDatepicker]="end" placeholder="{{'DEVICE.EndDate' | translate}}" [(ngModel)]="endDate" />
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="time" [(ngModel)]="endTime" placeholder="{{'DEVICE.EndTime' | translate}}"
                    step="300" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="section">
            {{'REPORT.Limits' | translate}}
        </div>
        <div class="controls">
            <mat-form-field style="margin-right: 32px;">
                <input matInput type="number" [(ngModel)]="customAlarm"
                    placeholder="{{'REPORT.CustomAlarm' | translate}}" step="1" />
            </mat-form-field>
            <mat-checkbox *ngFor="let alarm of alarms" [(ngModel)]="alarm.selected">{{ alarm.name }} ({{alarm.limit}}
                dB)</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="section">
            {{'REPORT.Fields' | translate}}
        </div>
        <div class="controls">
            <mat-checkbox [(ngModel)]="showLEQ30">LAeq 30min</mat-checkbox>
            <mat-checkbox [(ngModel)]="showLEQ5">LAeq 5min</mat-checkbox>
            <mat-checkbox [(ngModel)]="showComments">{{'REPORT.Comments' | translate}}</mat-checkbox>
        </div>
    </div>
    <div class="observation-row row">
        <div class="section">
            {{'REPORT.Observations' | translate}}
        </div>
        <div class="controls">
            <mat-form-field>
                <textarea matInput [(ngModel)]="observation"
                    placeholder="{{'REPORT.Observations' | translate}}"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="section">
            <button mat-raised-button color="primary" (click)="generateReport()">
                {{'REPORT.GenerateReport' | translate}}
            </button>
        </div>
    </div>
</div>
<div class="loading" *ngIf="loadingData">
    <mat-spinner diameter="32" color="primary" style="margin: auto;"></mat-spinner>
</div>
<div class="report" *ngIf="generated">
    <img class="logo" src="assets/images/logos/emma.svg">
    <table>
        <tr>
            <td colspan="2" class="header">
                {{'REPORT.General' | translate}}
            </td>
        </tr>
        <tr>
            <td class="field">{{'REPORT.Device' | translate}}</td>
            <td class="value">{{brand}} {{device.baseDeviceData?.model}} / {{device.baseDeviceData?.serialNumber}}</td>
        </tr>
        <tr>
            <td class="field">{{'REPORT.Dates' | translate}}</td>
            <td class="value">{{startDate | date: 'shortDate'}} {{startTime}} - {{endDate | date: 'shortDate'}}
                {{endTime}}</td>
        </tr>
        <tr>
            <td class="field">{{'REPORT.GPS' | translate}}</td>
            <td class="value">{{device.location.latitude}} - {{device.location.longitude}}</td>
        </tr>
        <tr>
            <td class="field">{{'REPORT.Address' | translate}}</td>
            <td class="value">{{device.location.address}}</td>
        </tr>
        <tr *ngIf="showComments">
            <td class="field">{{'REPORT.Comments' | translate}}</td>
            <td class="value">{{device.comments}}</td>
        </tr>
        <tr>
            <td colspan="2" class="header">
                {{'REPORT.Register' | translate}}
            </td>
        </tr>
        <tr>
            <td class="field">{{'REPORT.RegisteredParameters' | translate}}</td>
            <td class="value"><span *ngIf="showLEQ30">LAeq 30min</span> <span *ngIf="showLEQ5"><span *ngIf="showLEQ30">,
                    </span>LAeq 5min</span></td>
        </tr>
        <tr *ngIf="observation">
            <td colspan="2" class="header">
                {{'REPORT.Observations' | translate}}
            </td>
        </tr>
        <tr *ngIf="observation">
            <td colspan="2" class="value breaks">
                {{observation}}
            </td>
        </tr>
        <tr>
            <td class="header">
                {{'REPORT.Map' | translate}}
            </td>
            <td class="header">
                {{'REPORT.Photo' | translate}}
            </td>
        </tr>
        <tr>
            <td class="map">
                <agm-map [fitBounds]="true" [zoom]="zoom" [maxZoom]="16" [disableDefaultUI]="true">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [agmFitBounds]="true"></agm-marker>
                </agm-map>

            </td>
            <td class="image">
                <cl-image public-id={{picture}}>
                    <cl-transformation height="250" fetch-format="auto" crop="limit">
                    </cl-transformation>
                </cl-image>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="header">
                {{'REPORT.Graph' | translate}}
            </td>
        </tr>

    </table>
    <highcharts-chart *ngIf="deviceData" [Highcharts]="Highcharts" [constructorType]="chart.constructor"
        [options]="chart.options" [(update)]="chart.update" style="width: 100%; display: block;"></highcharts-chart>

</div>