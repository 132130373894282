<div id="events" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ 'EVENTS.Events' | translate }}</h1>
    </div>
    <!-- CENTER -->
    <div class="center">
        <div class="filter-container" fxLayout="row">
            <!-- TODO: translate -->
            <p>
                View:
            </p>
            <div fxLayout="row">
                <div *ngFor="let filter of filters" fxLayout="row" fxLayoutAlign="start center" class="filter-item">
                    <mat-checkbox (change)="onSelectFilter(filter)"></mat-checkbox>
                    <app-alarm-type-span [severity]="filter"></app-alarm-type-span>
                    <p class="text-truncate">{{ filter }}</p>
                </div>
            </div>
        </div>

        <!-- CONTENT CARDS -->
        <div class="content-card" [hidden]="pendingAckEventsPage?.total<=0">
            <app-event-list [events]="pendingAckEventsPage?.data" (onEventClick)="editEvent($event)"
                            (onEventDelete)="deleteEvent($event)" (onEventACK)="ackEvent($event)">
            </app-event-list>
            <mat-paginator #pendingAckPaginator [length]="pendingAckEventsPage?.total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>

        <div class="content-card">
            <app-event-list [events]="resolvedAckEventsPage?.data" (onEventClick)="editEvent($event)"
                (onEventDelete)="deleteEvent($event)" (onEventACK)="ackEvent($event)">
            </app-event-list>
            <mat-paginator #resolvedAckPaginator [length]="resolvedAckEventsPage?.total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- Add event button -->
            <div class="add-button">
                <button mat-fab id="add-event-button" aria-label="add event" (click)="addEvent()"
                    [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
        </div>
        <!-- / CONTENT CARDS -->
    </div>
    <!-- / CENTER -->
</div>
