import { Component, OnInit, Input } from '@angular/core';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'emma-device-type-icon',
    templateUrl: './device-type-icon.component.html',
    styleUrls: ['./device-type-icon.component.scss'],
})
export class DeviceTypeIconComponent implements OnInit {
    limiterIcon = faWaveSquare;
    @Input() deviceType: string = '';

    constructor() {}

    ngOnInit() {}
}
