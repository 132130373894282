export const chartSoundPressure5Minutes = {
	constructor: "stockChart",
	options: {
		chart: {
			type: "spline",
			height: 600,
			zoomType: 'y',
		},
		rangeSelector: {
			enabled: false
		},
		tooltip: {
			valueDecimals: 1,
			valueSuffix: "dBA"
		},
		plotOptions: {
			line: {
				gapSize: 0,
				gapUnit: "value",
				dataGrouping: {
					enabled: false,
				}
			},
			series: {
				marker: {
					enabled: false,
					states: {
						hover: {
							enabled: true,
							radiusPlus: 2,
							lineWidthPlus: 2
						}
					}
				},
				states: {
					hover: {
						lineWidth: 2
					}
				}
			}
		},
		xAxis: {
			type: "datetime",
			title: {
				text: null
			},
			ordinal: false,
			labels: {
				style: {
					fontSize: '1.1em',
				}
			},
		},
		yAxis: [{
			labels: {
				align: "left",
				style: {
					fontSize: '1.1em',
				}
			},
			floor: 0,
			ceiling: 120,
			max: 120,
			min: 0,
			plotLines: [
			],
			minorTickInterval: 5,
			tickInterval: 10,
		}],
		legend: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		time: {
			useUTC: false,
		},
		series: [
			{
				data: [],
				name: "Sound Pressure",
				lineWidth: 2,
				color: '#0C65E8',
				states: {
					hover: {
						enabled: true,
					}
				},
				visible: true,
			},
			{
				data: [],
				name: "Sound Pressure (L10)",
				lineWidth: 2,
				color: '#4CAF50',
				states: {
					hover: {
						enabled: true,
					}
				},
				visible: true,
			},
			{
				data: [],
				name: "Sound Pressure (L90)",
				lineWidth: 2,
				color: '#FF9800',
				states: {
					hover: {
						enabled: true,
					}
				},
				visible: true,
			},

		]
	},
	update: true,
	visible: false
};