import { NgModule } from '@angular/core';
import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';

@NgModule({
    imports: [
        CloudinaryModule.forRoot({ Cloudinary }, {
            cloud_name: 'emmadb',
            api_key: '641638489479677',
            api_secret: 'NhZy1Jm3RcPTBB6myU3Sd2QszX4'
        } as CloudinaryConfiguration),
    ],
    exports: [CloudinaryModule],
})
export class EmmaCloudinaryModule { }
