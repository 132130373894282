export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'SECTIONS': 'Sections',
            'DASHBOARD': 'Control Center',
            'DEVICES': 'Devices',
            'MAP': 'Map',
            'EVENTS': 'Events',
			'REPORTS': 'Reports',
			'COMPANIES': 'Companies',
			'PROJECTS': 'Projects',
            'USERS': 'Users',		
            'ADMIN-DEVICES': 'Devices',	
            'ADMIN-PROJECTS': 'Projects'	
        }
    }
};
