import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectModel, DateFormatValues } from 'app/models/project.model';
import { ProjectService } from 'app/services/api/project.service';
import * as moment from 'moment';
import 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';

@Component({
    selector: 'app-project-admin',
    templateUrl: './project-admin.component.html',
    styleUrls: ['./project-admin.component.scss'],
    animations: fuseAnimations,
})
export class ProjectAdminComponent implements OnInit {
    project: any;
    public projectForm: FormGroup;
    pageType: string;
    savedChangesString: string;
    creationSuccessString: string;
    date: number;
    timezone: string;

    constructor(
        private translation: FuseTranslationLoaderService,
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private toastr: ToastrService,
    ) {
        this.translation.loadTranslations(english, spanish, catalan);
        this.pageType = 'new';

        translation.translateService.get('GLOBAL', { value: 'SavedChanges' }).subscribe(res => {
            this.savedChangesString = res.SavedChanges;
        });
        translation.translateService.get('GLOBAL', { value: 'CreatedSuccess' }).subscribe(res => {
            this.creationSuccessString = res.CreatedSuccess;
        });
    }

    ngOnInit() {
        this.project = this.route.snapshot.data.data;
        if (this.project.id) {
            this.pageType = 'edit';
        } else {
            this.project = new ProjectModel();
        }

        const guessedTimezone = moment.tz.guess();
        this.projectForm = this.formBuilder.group({
            id: [this.project.id],
            name: [this.project.name, [Validators.required]],
            timezone: [
                this.pageType === 'edit' ? this.project.timezone : guessedTimezone,
                [Validators.required],
            ],
            dateformat: [
                this.pageType === 'edit' ? this.project.dateformat : DateFormatValues[0],
                [Validators.required],
            ],
        });

        if (this.pageType === 'edit') {
            this.projectForm.valueChanges.pipe(debounce(() => timer(1000))).subscribe(val => {
                this.save();
            });
        }

        this.timezone = moment.tz(this.project.timezone).format('Z').replace(":","");

        this.date = Date.now();
        setInterval(() => {         //replaced function() by ()=>
            this.date = Date.now();
        }, 1000);
    }

    get timeZones() {
        return moment.tz.names();
    }

    get dateformats() {
        return DateFormatValues;
    }

    add() {
        const objectCopy = { ...this.project };
        Object.assign(objectCopy, this.projectForm.value);
        this.projectService.create(objectCopy).subscribe(
            data => {
                this.toastr.success(this.creationSuccessString);
                this.project = data;
                this.pageType = 'edit';
            },
            error => {
                console.log(error);
                this.toastr.error('Error');
            },
        );
    }

    save() {
        const objectCopy = { ...this.project };
        Object.assign(objectCopy, this.projectForm.value);
        this.projectService.edit(objectCopy).subscribe(
            data => {
                this.project = data;
                this.timezone = moment.tz(this.project.timezone).format('Z').replace(":","");
                this.toastr.success(this.savedChangesString);
            },
            error => {
                console.log(error);
                this.toastr.error('Error');
                // In case of resource deleted from another part, return to list
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/admin/projects']);
                }
            },
        );
    }

    cancel() {
        this.router.navigate(['/admin/projects']);
    }
}
