<div id="companies" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <h1>{{ 'COMPANIES.Companies' | translate }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <app-company-list (rowClick)="onCompanyClick($event)" (rowSelect)="onCompanyCheck($event)">
            </app-company-list>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<div class="action-buttons">
    <button *ngIf="editAvailable" mat-fab color="warn" id="delete-button" (click)="deleteCompanies()"
        aria-label="delete" [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>delete</mat-icon>
    </button>
    <button mat-fab id="add-user-button" [routerLink]="'/admin/company/new'" aria-label="add user"
        [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
        <mat-icon>add</mat-icon>
    </button>
</div>