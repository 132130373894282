import { Injectable } from "@angular/core";
import { TokenModel } from "../models/token.model";

@Injectable()
export class TokenService {

	set token(token: TokenModel) {
		this.storeToken(token, 'token');
	}

	get token(): TokenModel {
		const item = JSON.parse(localStorage.getItem('token'));
		if (item)
			return item.token ? item : null;
		return null
	}

	storeToken(token: TokenModel, key: string) {
		if (token) {
			let item = JSON.parse(localStorage.getItem(key));

			if (item) {
				// if local storage item exists, update it
				item = token;
				localStorage.setItem(key, JSON.stringify(item));
			} else {
				// otherwise, create new item with just a token
				localStorage.setItem(key, JSON.stringify(token));
			}
		} else {
			// clear token from local storage
			localStorage.removeItem(key);
		}
	}
}
