import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { AuthGuard } from 'app/guards/auth.guard';
import { ProjectDeviceResolver } from 'app/resolvers/projectDevice.resolver';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxFileDropModule } from 'ngx-file-drop';

import { DeviceDataComponent } from './device/device-data/device-data.component';
import { DeviceInfoComponent } from './device/device-info/device-info.component';
import { DeviceStatusComponent } from './device/device-status/device-status.component';
import { DeviceComponent } from './device/device.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceAlarmsComponent } from './device/device-alarms/device-alarms.component';
import { AlarmAddEditComponent } from '../../components/alarm-add-edit/alarm-add-edit.component';
import { DeviceEventComponent } from './device/device-event/device-event.component';
import { EmmaCloudinaryModule } from 'app/components/emma-cloudinary.module';
import { DeviceReportComponent } from './device/device-report/device-report.component';
import { DeviceFileComponent } from './device/device-file/device-file.component';

const routes = [
    {
        path: 'devices',
        component: DevicesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'device/:id',
        component: DeviceComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ProjectDeviceResolver,
        },
    },
];

@NgModule({
    declarations: [
        DevicesComponent,
        DeviceComponent,
        DeviceInfoComponent,
        DeviceDataComponent,
        DeviceStatusComponent,
        DeviceAlarmsComponent,
        AlarmAddEditComponent,
        DeviceEventComponent,
        DeviceReportComponent,
        DeviceFileComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,
        FuseSharedModule,
        FuseWidgetModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyC2ez9tqzGdSZEv3Sk5etWSEDCNf1uUHYs',
        }),

        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatToolbarModule,
        MatTabsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,

        FuseSharedModule,
        FuseWidgetModule,

        NgxFileDropModule,

        HighchartsChartModule,

        EmmaCloudinaryModule,

        ComponentsModule,
    ],
    providers: [

    ],
    entryComponents: [AlarmAddEditComponent],
    exports: [DevicesComponent, DeviceComponent],
})

export class DevicesModule { }
