import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from 'app/guards/auth.guard';
import { EventComponent } from './event/event.component';
import { EventsComponent } from './events/events.component';
import { ComponentsModule } from 'app/components/components.module';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
    {
        path: 'events',
        component: EventsComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [EventsComponent, EventComponent],
    imports: [
        RouterModule.forChild(routes),

        CommonModule,
        ComponentsModule,

        TranslateModule,

        FuseSharedModule,
        FuseWidgetModule,
        MatButtonModule,
        MatMenuModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
		MatToolbarModule,
        MatCheckboxModule,
        MatDialogModule,
    ],
    entryComponents: [EventComponent],
})
export class EventsModule {}
