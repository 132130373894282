import { AlarmModel } from './alarm.model';
import { DeviceModel } from './device.model';
import { UserModel } from "./user.model";

/**
 * TO BE MAINTAINED IN SYNC WITH event.go files from Data Server Project
 */
export enum EventModelSeverity {
    EventModelSeverityAlarm = 'alarm',
    EventModelSeverityInfo = 'info',
    EventModelSeveritySuccess = 'success',
    EventModelSeverityWarning = 'warning',
}

export enum EventModelType {
    EventModelTypeAlarmOff = 'device_alarm_off',
    EventModelTypeAlarmOn = 'device_alarm_on',
    EventModelTypeDeviceAdded = 'device_added',
    EventModelTypeDeviceOffline = 'device_offline',
    EventModelTypeDeviceOnline = 'device_online',
    EventModelTypeDeviceRemoved = 'device_removed',
    EventModelTypeUserDefined = 'user_defined',
    EventModelTypeWarningOff = 'device_warning_off',
    EventModelTypeWarningOn = 'device_warning_on',
}

export class EventDataModel {
    id: string;
    severity: EventModelSeverity;
    type: EventModelType;
    deviceId: string;
    deviceData?: DeviceModel;
    projectId: string;
    startTime: Date;
    endTime: Date;
    description: string;
    ACK?: boolean;
    data: any;
    alarm?: AlarmModel;
    ackUser: UserModel;

    static ackEnabled(event: EventDataModel): boolean {
        return (event.alarm && event.alarm.needACK);
    }
}
