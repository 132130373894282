export const locale = {
    lang: 'en',
    data: {
        TAB: {
            Status: 'Status',
            Data: 'Data',
            Alarms: 'Alarms',
            Events: 'Events',
            Info: 'Info',
            Report: 'Report',
            Files: 'Files',
        },
        DEVICES: {
            ID: 'ID',
            Name: 'Name',
            Active: 'Active',
            LastData: 'Last Data',
            Address: 'Address',
            Devices: 'Devices',
        },
        DEVICE: {
            NoiseLevel: 'Noise Level',
            Limit: 'Limit:',
            Info: 'Info',
            Name: 'Name',
            SerialNumber: 'Serial Number',
            Location: 'Location',
            Latitude: 'Latitude',
            Longitude: 'Longitude',
            NoGPSData: 'No valid GPS data',
            Comments: 'Comments',
            Last15Minutes: 'Last 15 Minutes',
            LastHour: 'Last Hour',
            Last12Hours: 'Last 12 Hours',
            Last24Hours: 'Last 24 Hours',
            StartDate: 'Start Date',
            EndDate: 'End Date',
            Data: 'Data',
            DateRange: 'Date Range',
            DataAggregation: 'Averaging',
            NoAggregation: 'No Averaging',
            Minutes5: '5 Minutes',
            LiveUpdate: 'Live Update',
            ShowL10: 'Show L10',
            ShowL90: 'Show L90',
            Custom: 'Custom',
            Download: 'Download CSV Data',
            Export: 'Export CSV',
            StartTime: 'Start Time',
            EndTime: 'End Time',
            Image: 'Image',
            DropFiles: 'Drop Image Here',
            RemoveImage: 'Remove Image',
            Picture: 'Picture',
            SimOrImei: 'SIM or IMEI',
            LastCalibrationDate: 'Last Calibration Date',
            BaseName: 'Device',
            UploadFile: 'Upload File',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Name',
            Description: 'Description',
            TimeWindow: 'Time Window',
            Samples: 'Samples',
            Threshold: 'Threshold',
            Limit: 'Limit',
            Severity: 'Severity',
            Field: 'Field',
            Remove: 'Remove',
            time_window_instant: 'Instant',
            time_window_rolling_5: '5 minutes rolling average',
            time_window_rolling_30: '30 minutes rolling average',
            time_window_bucket_5: '5 minutes bucket',
            threshold_cross_over: 'Cross OVER',
            threshold_cross_below: 'Cross BELOW',
            ADD: 'ADD',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            AlarmDetail: 'Alarm Detail',
            NewAlarm: 'New Alarm',
            Notify: 'Notify',
            Silent: 'Silent',
            NeedACK: 'Need ACK',
            NewNotificationItem: 'email/phone',
            ProjectAlarm: 'Project Alarms',
            AlarmDeleteTitle: 'Alert',
            AlarmDeleteMessage: 'This alarm has {{activeEvents}} active events. \nAre you sure you want to delete it?',
        },
        SEVERITY: {
            alarm: 'Alarm',
            info: 'Information',
            success: 'Success',
            warning: 'Warning',
        },
        FIELD: {
            sound_pressure_level: 'Sound Pressure',
            battery: 'Battery',
            power_supply_status: 'Power Supply',
            spectrum_13_octave: '1/3 Octave Spectrum',
            temperature: 'Ext. Temperature',
            overload: 'Overload',
            underrange: 'Underrange',
            wifi_signal_level: 'WiFi Signal',
            cellullar_signal_level: 'Cellular Signal',
            free_storage: 'Free Storage',
        },
        REPORT: {
            General: 'General Data',
            Device: 'Device / Serial Number',
            Address: 'Address',
            Comments: 'Comments',
            Register: 'Register',
            GenerateReport: 'Generate Report',
            Dates: 'Dates',
            GPS: 'GPS Coordinates',
            Photo: 'Photo',
            RegisteredParameters: 'Registered Parameters',
            Graph: 'Graph',
            Observations: 'Observations',
            CustomAlarm: 'Custom Limit',
            Limits: 'Limits',
            Fields: 'Fields',
            Map: 'Map',
        },
        FILE: {
            Name: 'Name',
            UploadDate: 'Upload Date',
            Status: 'Status',
            DropFile: 'Drop File Here',
            uploaded: 'Uploaded',
            processing: 'Processing',
            processed: 'Processed',
        }
    },
};
