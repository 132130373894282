import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProjectViewService } from './api/projectView.service';
import { ProjectViewModel } from 'app/models/projectView.model';

interface IProjectView {
    [projectId: string]: string;
}

@Injectable()
export class ProjectViewPreferenceService {
    private _onPreferredViewChange = new Subject();
    public readonly onPreferredProjectViewChange = this._onPreferredViewChange.asObservable();

    constructor(private readonly projectViewService: ProjectViewService) {
        this.projectViewService.onDeleteProjectView.subscribe(view => this.removeView(view));
    }

    /**
     * Dashboard View - Project
     */
    public savedView(project: string): string | undefined {
        const views = this.loadViews();
        return views[project];
    }

    public saveView(project: string, view: string | undefined) {
        const storageViews = this.loadViews();
        const views = storageViews ? storageViews : {};
        views[project] = view;
        localStorage.setItem('projectView', JSON.stringify(views));
        this._onPreferredViewChange.next();
    }

    private loadViews(): IProjectView {
        const saved = JSON.parse(localStorage.getItem('projectView'));
        return saved ? saved : {};
    }

    /**
     * When a view is deleted from API operations, keep the local preferred view archive in sync
     */
    private removeView(projectView: ProjectViewModel) {
        const saved = this.loadViews();
        for (const project in saved) {
            if (saved.hasOwnProperty(project)) {
                const view = saved[project];
                if (view === projectView.id) {
                    saved[project] = null;
                }
            }
        }
        localStorage.setItem('projectView', JSON.stringify(saved));
        this._onPreferredViewChange.next();
    }
}
