import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EventDataModel, EventModelSeverity } from 'app/models/event.model';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { EventRequest, EventService } from 'app/services/api/event.service';
import { ToastrService } from 'ngx-toastr';
import { locale as catalan } from '../../i18n/ca';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { PaginationResponse } from 'app/services/api/interfaces/response.interface';
import { EventMqttService } from 'app/services/api/event.mqtt.service';
import { IMqttMessage } from "ngx-mqtt";
import { Subscription } from 'rxjs';
import { ContextService } from 'app/services/context.service';

@Component({
    selector: 'app-device-event',
    templateUrl: './device-event.component.html',
    styleUrls: ['./device-event.component.scss'],
})
export class DeviceEventComponent implements OnInit, AfterViewInit {
    @Input() device: ProjectDeviceModel;

    @ViewChild('pendingAckPaginator', { static: true }) pendingAckPaginator: MatPaginator;
    @ViewChild('resolvedAckPaginator', { static: true }) resolvedAckPaginator: MatPaginator;

    resolvedAckEventsPage: PaginationResponse<EventDataModel>;
    pendingAckEventsPage: PaginationResponse<EventDataModel>;
    filters = [
        EventModelSeverity.EventModelSeverityInfo,
        EventModelSeverity.EventModelSeveritySuccess,
        EventModelSeverity.EventModelSeverityWarning,
        EventModelSeverity.EventModelSeverityAlarm,
    ];
    private selectedFilters: EventModelSeverity[] = [];

    private subscription: Subscription;
    private currentProjectId: string;
    private loading: boolean;

    constructor(
        private readonly eventService: EventService,
        private readonly eventMqtt: EventMqttService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    ngOnInit() {
        this.currentProjectId = this.contextService.currentProject;
        this.loading = false;
     }

     ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.pendingAckPaginator.page.subscribe(() => this.loadPendingAckEvents());
        this.resolvedAckPaginator.page.subscribe(() => this.loadResolvedAckEvents());
        this.loadData();
        this.subscribeToTopics();
    }

    private subscribeToTopics() {
        this.subscription = this.eventMqtt.topic(this.currentProjectId)
            .subscribe((data: IMqttMessage) => {
                //As results are paginated, we reload data to make it easier
                let item = JSON.parse(data.payload.toString());
                if (item.deviceId==this.device.id) {
                    this.loadData();
                }
            });
    }


    onSelectFilter(filter: EventModelSeverity) {
        const filterIdx = this.selectedFilters.indexOf(filter);
        if (filterIdx > -1) {
            this.selectedFilters.splice(filterIdx, 1);
        } else {
            this.selectedFilters.push(filter);
        }
        this.pendingAckPaginator.pageIndex = 0;
        this.resolvedAckPaginator.pageIndex = 0;
        this.loadData();
    }

    public ackEvent(event: EventDataModel) {
        this.eventService.acknowledge(event.id).subscribe(
            success => {
                this.loadData();
            },
            error => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
            },
        );
    }

    public loadData(): void {
        if (this.loading) {
            return;
        }

        this.loading = true;

        this.loadPendingAckEvents();
        this.loadResolvedAckEvents();
    }

    public loadPendingAckEvents(): void {
        const request: EventRequest = {
            devicesId: [this.device.id],
            size: this.pendingAckPaginator.pageSize,
            offset: this.pendingAckPaginator.pageIndex * this.pendingAckPaginator.pageSize,
            pendingAck: true,
        };

        if (this.selectedFilters.length > 0) {
            request.severity = this.selectedFilters;
        }
        this.eventService.getAll(request).subscribe(
            (events) => {
                this.pendingAckEventsPage = events;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/devices']);
                }
            },
        );
    }

    public loadResolvedAckEvents(): void {
        const request: EventRequest = {
            devicesId: [this.device.id],
            size: this.resolvedAckPaginator.pageSize,
            offset: this.resolvedAckPaginator.pageIndex * this.resolvedAckPaginator.pageSize,
            pendingAck: false,
        };

        if (this.selectedFilters.length > 0) {
            request.severity = this.selectedFilters;
        }
        this.eventService.getAll(request).subscribe(
            (events) => {
                this.resolvedAckEventsPage = events;
                this.loading = false;
            },
            (error) => {
                console.log(error);
                this.toastr.error('Error: ' + error.message);
                if (error.error.statusCode === 404) {
                    this.router.navigate(['/devices']);
                }
            },
        );
    }
}
