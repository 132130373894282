export const locale = {
    lang: 'ca',
    data: {
        'NAV': {
            'SECTIONS': 'Seccions',
            'DASHBOARD': 'Control Center',
            'DEVICES': 'Dispositius',
            'MAP': 'Mapa',
            'EVENTS': 'Esdeveniments',
			'REPORTS': 'Informes',
			'COMPANIES': 'Empreses',
			'PROJECTS': 'Projectes',
            'USERS': 'Usuaris',
            'ADMIN-DEVICES': 'Dispositius',	
            'ADMIN-PROJECTS': 'Projectes',
        }
    }
};
