import { ProjectModel } from './project.model';

export class UserModel {
    id: string;
    name: string;
    email: string;
    password: string;
    isAdmin: boolean;
    projects: string[];
    projectsData: ProjectModel[];
	companies: string[];
	
	constructor() {
		this.isAdmin = false;
	}
}
