<mat-table [dataSource]="project.periods" fusePerfectScrollbar>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
            {{ 'PROJECTS.ProjectPeriodName' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let period">
            {{ period.name }}
        </mat-cell>
    </ng-container>

    <!-- Start Time Column -->
    <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef>
            {{ 'PROJECTS.ProjectPeriodStart' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let period">
            {{ period.startTime.hour | number: '2.0-0' }} :
            {{ period.startTime.minute | number: '2.0-0' }}
        </mat-cell>
    </ng-container>

    <!-- End Time Column -->
    <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef>
            {{ 'PROJECTS.ProjectPeriodEnd' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let period">
            {{ period.endTime.hour | number: '2.0-0' }} :
            {{ period.endTime.minute | number: '2.0-0' }}
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let period; columns: displayedColumns" class="period row-pointer" matRipple
        (click)="addEditPeriod(period)"></mat-row>
</mat-table>
<!-- Add period button -->
<div class="add-button">
    <button mat-fab id="add-period-button" aria-label="add device" (click)="addEditPeriod()" [@animate]="{
            value: '*',
            params: { delay: '300ms', scale: '.2' }
        }">
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</div>