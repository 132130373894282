import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { CompanyModel } from 'app/models/company.model';
import { CompanyService } from 'app/services/api/company.service';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.scss'],
    animations: fuseAnimations,
})
export class CompanyListComponent implements OnInit {
    public companies: CompanyModel[] = [];
    public displayedColumns = ['checkbox', 'name', 'street', 'city', 'zipCode', 'country'];
    private isLoading: boolean;
    private checkboxes: any;
    private _selected: string[] = [];

    set selectedCompanies(companies: string[]) {
        if (companies) {
            this._selected = companies;
        }
    }

    @Output()
    rowClick = new EventEmitter<CompanyModel>();

    @Output()
    rowSelect = new EventEmitter<CompanyModel>();

    constructor(private companyService: CompanyService) {
        this.isLoading = false;
    }

    ngOnInit() {
        this.isLoading = true;
        this.loadData();
    }

    private loadData() {
        this.companyService.getAll().subscribe(
            data => {
                this.isLoading = false;
                this.checkboxes = [];
                this.companies = data;

                data.map(company => {
                    this.checkboxes[company.id] = false;
                });

                // If thre are pre selected project, save that
                this._selected.forEach(companyId => {
                    this.checkboxes[companyId] = true;
                });
            },
            error => {
                console.log(error);
                this.isLoading = false;
            },
        );
    }

    public checkedCompanies(): CompanyModel[] {
        const objects = [];
        this.companies.forEach(company => {
            if (this.checkboxes[company.id]) {
                objects.push(company);
            }
        });
        return objects;
    }

    public refresh() {
        this.loadData();
    }

    onSelectedChange(company: CompanyModel) {
        this.rowSelect.emit(Object.assign({}, company));
    }

    onClick(company: CompanyModel) {
        this.rowClick.emit(Object.assign({}, company));
    }
}
