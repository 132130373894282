import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ContextService } from 'app/services/context.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceModel } from "../../../models/device.model";
import { DeviceService } from "../../../services/api/device.service";
import { locale as catalan } from '../i18n/ca';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';

@Component({
    selector: 'devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class DevicesComponent implements OnInit {
    projectDevices: ProjectDeviceModel[];
    devices: DeviceModel[];
    displayedColumns = ['image', 'picture', 'name', 'active', 'last_data', 'address'];

    constructor(
        private projectDeviceService: ProjectDeviceService,
        private deviceService: DeviceService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private contextService: ContextService,
        private toastr: ToastrService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);
    }

    public ngOnInit(): void {
        this.loadData();
    }

    private loadData(): void {
        const currentProject = this.contextService.currentProject;
        this.projectDeviceService.getAll(currentProject).subscribe(
          (devices: ProjectDeviceModel[]) => {
              this.projectDevices = devices;
          },
          (error) => {
              console.log(error);
              this.toastr.error('Error: ' + error.message);
          },
        );

        this.deviceService.getAll().subscribe(
          (devices: DeviceModel[]) => {
              this.devices = devices;
          },
          (error) => {
              console.log(error);
              this.toastr.error('Error: ' + error.message);
          },
        );
    }

    public getLastDate(projectDevice: ProjectDeviceModel): Date {
        return ProjectDeviceModel.lastDataTime(projectDevice.lastData);
    }

    public getDevicePicture(projectDevice: ProjectDeviceModel): string {
        if (projectDevice.picture) {
            return projectDevice.picture;
        }

        if (this.devices) {
            let device = this.devices.find((d) => projectDevice.baseDevice === d.id);
            return device ? device.picture : null;
        }

        return null;
    }
}
