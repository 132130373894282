import { AuthGuard } from 'app/guards/auth.guard';
import { CompanyResolver } from 'app/resolvers/company.resolver';
import { DeviceResolver } from 'app/resolvers/device.resolver';
import { ProjectResolver } from 'app/resolvers/project.resolver';
import { UserResolver } from 'app/resolvers/user.resolver';
import { CompaniesComponent } from './company-admin/companies/companies.component';
import { CompanyComponent } from './company-admin/company/company.component';
import { DeviceAdminComponent } from './devices-admin/device/deviceAdmin.component';
import { DevicesAdminComponent } from './devices-admin/devices/devicesAdmin.component';
import { ProjectAdminComponent } from './projects-admin/project/project-admin.component';
import { ProjectsAdminComponent } from './projects-admin/projects/projects-admin.component';
import { UserComponent } from './users-admin/user/user.component';
import { UsersComponent } from './users-admin/users/users.component';
const ɵ0 = UserResolver, ɵ1 = CompanyResolver, ɵ2 = DeviceResolver, ɵ3 = ProjectResolver;
const routes = [
    {
        path: 'admin/users',
        component: UsersComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/user/:id',
        component: UserComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ɵ0,
        },
    },
    {
        path: 'admin/companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/company/:id',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ɵ1,
        },
    },
    {
        path: 'admin/devices',
        component: DevicesAdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/device/:id',
        component: DeviceAdminComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ɵ2,
        },
    },
    {
        path: 'admin/projects',
        component: ProjectsAdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin/project/:id',
        component: ProjectAdminComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: ɵ3,
        },
    },
];
export class AdminModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
