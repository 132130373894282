
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	private lastUnauthorizedResponseTime: number;

	constructor(private router: Router, private authentication: AuthenticationService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(tap(event => {
			return event;
		},
		error => {
			if (error instanceof HttpErrorResponse) {
				if (error.status === 401) {
					// Unauthorized response from the server, likely our token has expired

					const now = new Date().getTime();

					// check that 5 seconds have elapsed since last detection as a means of backoff in the case where a
					// section of the site might have initiated multiple network calls in quick success
					// this prevents a series of repeated triggers of this logic

					if (!this.lastUnauthorizedResponseTime || (now - this.lastUnauthorizedResponseTime) > 5000) {
						this.lastUnauthorizedResponseTime = now;

						//Make sure we logout the user
						this.authentication.logout();

						//Go to login
						this.router.navigate(['/login']);
					}
				}
			}
			return error;
		}));

	}
}
