<div class="emma-card__header">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <a *ngIf="!contextService.isKioskModeEnabled" [routerLink]="'/device/' + device.id">{{ device?.deviceName
                }}</a>
            <span *ngIf="contextService.isKioskModeEnabled">{{ device?.deviceName }}</span>
            - 1/3 {{'COMPONENT.Octave' | translate}}
        </div>
        <app-device-view-list *ngIf="editGraphViews" [device]="device" [dataType]="dataType"></app-device-view-list>
    </div>
</div>

<div class="emma-card__content" *ngIf="device.lastData">
    <small>{{ device?.lastData?.spectrum13Octave?.timestamp | date:'medium' }}</small>
</div>

<div class="emma-card__content emma-card__content--center" *ngIf="!device.lastData">
    <small>{{'COMPONENT.NoData' | translate}}</small>
    <div>
        <b>
            {{'COMPONENT.DeviceDisconnected' | translate}}
        </b>
    </div>
</div>


<div class="emma-card__footer-full-width" *ngIf="device.lastData">
    <highcharts-chart *ngIf="chart.visible" [Highcharts]="Highcharts" [constructorType]="chart.constructor"
        [options]="chart.options" [(update)]="chart.update" style="width: 100%; display: block;"
        [callbackFunction]="chartCallback"></highcharts-chart>
</div>

<div *ngIf="!device.connected && device.lastData" class="emma-card__offline">
    {{ 'COMPONENT.DeviceOffline' | translate }}
</div>