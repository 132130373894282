export const locale = {
    lang: 'ca',
    data: {
        COMPONENT: {
            NoiseLevel: 'Nivell de Soroll',
            Battery: 'Bateria',
            Octave: 'Octave Spectra',
            Average5: '5 minuts mitjans',
            Average30: '30 minuts mitjans',
            NoData: 'Sense dades encara',
            DeviceDisconnected: 'Assegureu-vos que el dispositiu està connectat',
            DeviceOffline: 'Dispositiu sense connexió',
            CellularSignalLevel: 'Nivell Cobertura',
            FreeStorage: 'Espai Lliure',
        },
        EVENTS: {
            Events: 'Esdeveniments',
            Severity: 'Severitat',
            Type: 'Tipus',
            Device: 'Dispositiu',
            StartTime: "Hora d'inici",
            EndTime: 'Hora final',
            Description: 'Descripció',
            Remove: 'Eliminar',
            ADD: 'Afegeix',
            SAVE: 'GUARDAR',
            CANCEL: 'cancel·lar',
            EventType: "Tipus d'esdeveniment: ",
            NewEvent: 'Nuevo Evento',
            EventDetail: 'Detall de l’esdeveniment',
            EventTimeError: "La data de finalització ha de ser després de la data d'inici",
            Alarm: 'Alarma',
            AckUser: 'Acceptat per',
        },
        SEVERITY: {
            alarm: 'Alarma',
            info: 'Informació',
            success: 'Èxit',
            warning: 'Atenció',
        },
        TYPE: {
            device_alarm_off: 'Alarma Desactivada',
            device_alarm_on: 'Alarma Activada',
            device_added: 'Dispositiu Afegit',
            device_offline: 'Dispositiu fora de línia',
            device_online: 'Dispositiu en línia',
            device_removed: 'Dispositiu eliminat',
            user_defined: 'Definit per Usuari',
            device_warning_off: 'Avís Desactivat',
            device_warning_on: 'Avís Activat',
            device_first_data: 'Primera Dada',
        },
        ALARM: {
            ID: 'Id',
            Name: 'Nom',
            Description: 'Descripció',
            TimeWindow: 'Finestra de temps',
            Samples: 'Mostres',
            Threshold: 'Llindar',
            Limit: 'Límit',
            Severity: 'Severitat',
            Field: 'Camp',
            Remove: 'Elimina',
            time_window_instant: 'Instant',
            time_window_rolling_5: '5 minuts de mitjana rodant',
            time_window_rolling_30: '30 minuts mitjans rodants',
            time_window_bucket_5: '5 minuts bucket',
            threshold_cross_over: 'Creuem SOBRE',
            threshold_cross_below: 'Creuem SOTA',
            ADD: 'AFEGIR',
            SAVE: 'GUARDAR',
            CANCEL: 'CANCEL·LAR',
            AlarmDetail: "Detall d'alarma",
            NewAlarm: 'Nova Alarma',
            Notify: 'Notificar',
            Silent: 'Silenciosa',
            NewNotificationItem: 'email/telèfon',
            IgnoredDevices: 'Dispositius Ignorats',
        },
        FIELD: {
            sound_pressure_level: 'Nivel Soroll',
            battery: 'Bateria',
            power_supply_status: "Font d'alimetació",
            spectrum_13_octave: 'Espectre 1/3 Octava',
            temperature: 'Temperatura Ext.',
            overload: 'Sobrecàrrega',
            underrange: 'Underrange',
            wifi_signal_level: 'Senyal WiFi',
            cellullar_signal_level: 'Senyal Cel·lular',
        },
    },
};
