import { Protocol } from './protocol.model';

/**NOTE
 * Needs to be maintained in sync with the device_type.go file
 */
export enum DeviceType {
    NoiseSensor = 'noise_sensor',
    AcousticLimiter = 'acoustic_limiter',
}

export class ActiveProjectModel {
    id: string;
    name: string;
}

export class DeviceModel {
    id: string;
    name: string;
    picture: string;
    manufacturer: string;
    model: string;
    serialNumber: string;
    dataFrequency: number;
    protocol?: Protocol = new Protocol();
    deviceType: DeviceType;
    simOrImei: string;
    lastCalibrationDate: Date;
    activeInProject: ActiveProjectModel;
}
