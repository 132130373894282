import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AlarmModelThreshold, AlarmModelTimeWindow, AlarmModel } from 'app/models/alarm.model';
import { EventModelSeverity } from 'app/models/event.model';
import { EventComponent } from 'app/modules/events/event/event.component';
import { locale as catalan } from '../../modules/devices/i18n/ca';
import { locale as english } from '../../modules/devices/i18n/en';
import { locale as spanish } from '../../modules/devices/i18n/es';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { DataTypeModelKey } from '../../models/dataType.model';
import { ProjectModel } from '../../models/project.model';
import { ProjectAlarmModel } from '../../models/projectAlarm.model';
import { ProjectDeviceService } from '../../services/api/projectDevice.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { UserModel } from 'app/models/user.model';

enum AlarmAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
}

enum AlarmType {
    DEVICE = 'DEVICE',
    PROJECT = 'PROJECT',
}

@Component({
    selector: 'app-alarm-add-edit',
    templateUrl: './alarm-add-edit.component.html',
    styleUrls: ['./alarm-add-edit.component.scss'],
})
export class AlarmAddEditComponent implements OnInit {
    windowValues = [
        AlarmModelTimeWindow.AlarmModelTimeWindowInstant,
        AlarmModelTimeWindow.AlarmModelTimeWindowBucket5,
        AlarmModelTimeWindow.AlarmModelTimeWindowRolling5,
        AlarmModelTimeWindow.AlarmModelTimeWindowRolling30,
    ];
    severityValues = [
        EventModelSeverity.EventModelSeverityInfo,
        EventModelSeverity.EventModelSeveritySuccess,
        EventModelSeverity.EventModelSeverityWarning,
        EventModelSeverity.EventModelSeverityAlarm,
    ];
    thresholdValues = [
        AlarmModelThreshold.AlarmModelThresholdCrossingBelow,
        AlarmModelThreshold.AlarmModelThresholdCrossingOver,
    ];

    user: UserModel;

    public alarmForm: FormGroup;
    public action: AlarmAction;
    public alarmType: AlarmType;
    public device: ProjectDeviceModel;
    public alarm: AlarmModel | ProjectAlarmModel = new AlarmModel();
    public project: ProjectModel;
    public alarmFields: DataTypeModelKey[];
    public projectDevices: ProjectDeviceModel[] = [];
    

    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE]; // 1

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private formBuilder: FormBuilder,
        public matDialogRef: MatDialogRef<EventComponent>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private projectDeviceService: ProjectDeviceService,
        private authenticationService: AuthenticationService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish, catalan);

        if (_data.alarm) {
            this.action = AlarmAction.EDIT;
            this.alarm = _data.alarm;
        } else {
            this.action = AlarmAction.NEW;
        }

        if (_data.device) {
            this.device = _data.device;
            this.alarmType = AlarmType.DEVICE;
        } else if (_data.project) {
            this.project = _data.project;
            this.alarmType = AlarmType.PROJECT;
        }

        this.alarmFields =
            this.alarmType == AlarmType.DEVICE
                ? this.device.fields.filter(f => f !== DataTypeModelKey.spectrum13Octave)
                : Object.values(DataTypeModelKey);

        this.user = authenticationService.currentUser;
        this.createForm();
    }

    ngOnInit() {
        if (this.alarmType === AlarmType.PROJECT) {
            this.loadProjectDevices();
        }
    }

    private loadProjectDevices() {
        this.projectDeviceService.getAll(this.project.id).subscribe(
            devices => (this.projectDevices = devices),
            error => console.log(error),
        );
    }

    private createForm(): any {
        if (!this.alarm.notificationList) {
            this.alarm.notificationList = [];
        }

        const formFields = {
            name: [this.alarm.name, [Validators.required]],
            description: [this.alarm.description],
            field: [this.alarm.field, [Validators.required]],
            window: [this.alarm.window, [Validators.required]],
            threshold: [this.alarm.threshold, [Validators.required]],
            limit: [this.alarm.limit, [Validators.required]],
            samples: [this.alarm.samples, [Validators.required]],
            severity: [this.alarm.severity, [Validators.required]],
            notify: [this.alarm.notify, [Validators.required]],
            notificationList: [this.alarm.notificationList, [Validators.nullValidator]],
            silent: [this.alarm.silent, [Validators.required]],
            needACK: [this.alarm.needACK],
        };
        if (this.alarmType === AlarmType.PROJECT) {
            formFields['ignoredDevices'] = [(<ProjectAlarmModel>this.alarm).ignoredDevices];
        }

        this.alarmForm = this.formBuilder.group(formFields);

        const samplesField = this.alarmForm.get('samples');
        this.alarmForm.get('window').valueChanges.subscribe(value => {
            this.updateSamplesFormField(value, samplesField);
        });
        this.updateSamplesFormField(this.alarm.window, samplesField);
    }

    private updateSamplesFormField(value: AlarmModelTimeWindow, samplesField: AbstractControl) {
        if (
            value === AlarmModelTimeWindow.AlarmModelTimeWindowInstant ||
            value === AlarmModelTimeWindow.AlarmModelTimeWindowBucket5
        ) {
            samplesField.enable();
            samplesField.setValidators([Validators.required, Validators.min(1)]);
            samplesField.setValue(this.alarm.samples);
            this.alarmForm.updateValueAndValidity();
        } else {
            samplesField.disable();
            samplesField.setValidators(null);
            samplesField.setValue(null);
            this.alarmForm.updateValueAndValidity();
        }
    }

    public save() {
        const alarm = this.alarmForm.value;
        alarm.id = this.alarm.id;
        this.matDialogRef.close({
            alarm,
        });
    }

    public addNotificationItem(event: MatChipInputEvent) {
        const input = event.input;
        const value = event.value;
        if (value.trim() !== '') {
            this.alarm.notificationList.push(value);
            this.alarmForm.controls['notificationList'].markAsDirty();
            input.value = '';
        }
    }

    public removeNotificationItem(item: any) {
        let index = this.alarm.notificationList.indexOf(item, 0);
        if (index > -1) {
            this.alarm.notificationList.splice(index, 1);
        }
        this.alarmForm.controls['notificationList'].markAsDirty();
    }

    public cancel() {
        this.matDialogRef.close();
    }
}
