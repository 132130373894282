import { Component, OnInit, LOCALE_ID, Inject, NgZone } from '@angular/core';
import { timer, Observable, Subscription } from 'rxjs';
import {
	latLng,
	tileLayer,
	circle,
	polygon,
	marker,
	divIcon,
	Point,
	LatLngBounds,
	latLngBounds,
	point,
} from 'leaflet';

import { DeviceModel, DeviceType } from 'app/models/device.model';
import { DeviceService } from 'app/services/api/device.service';
import { ContextService } from 'app/services/context.service';
import { ProjectDeviceService } from 'app/services/api/projectDevice.service';
import { ProjectDeviceModel } from 'app/models/projectDevice.model';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { formatDate } from '@angular/common';
import { EventModelSeverity } from 'app/models/event.model';
import { Router } from '@angular/router';
import { DeviceDataType } from 'app/models/deviceData.model';
import { IMqttMessage } from "ngx-mqtt";
import { DeviceMqttService } from 'app/services/api/device.mqtt.service';

interface EmmaMarker {
	lat: number;
	lng: number;
	name?: string;
	lastValue?: string;
}

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
	devices: ProjectDeviceModel[] = [];
	currentProject: string;

	esriMap = tileLayer(
		'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
		{ maxZoom: 18, attribution: 'Esri ArcGIS' },
	);
	osMap = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		maxZoom: 18,
		attribution: 'OpenStreetMap',
	});

	mapOptions = {
		layers: [this.esriMap],
	};
	layersControl = {
		baseLayers: {
			'Esri ArcGIS': this.esriMap,
			'Open Street Map': this.osMap,
		},
	};
	layers = [];
	bounds = null;
	boundsOptions = { padding: point(32, 32) };

	subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

	loadingData = false;

	constructor(
		private projectDeviceService: ProjectDeviceService,
		private sessionService: ContextService,
		@Inject(LOCALE_ID) private locale: string,
		private router: Router,
		private zone: NgZone,
		private readonly deviceMqtt: DeviceMqttService,
	) { }

	ngOnInit() {
		this.currentProject = this.sessionService.currentProject;

		this.refreshData();
	}

	ngOnDestroy(): void {
		for (let subs of this.subscriptions.values()) {
			subs.unsubscribe();
		}
	}


	private subscribeToTopics(device: ProjectDeviceModel) {
		let subs = this.deviceMqtt.deviceTopic(device.id, DeviceDataType.instant, DataTypeModelKey.soundPressureLevel)
			.subscribe((data: IMqttMessage) => {
				let item = JSON.parse(data.payload.toString());
				device.lastData.soundPressureLevel.value = item.value;
				device.lastData.soundPressureLevel.timestamp = item.timestamp;
				this.updateMarkers();
			});
		this.subscriptions.set(device.id, subs);
	}


	private refreshData() {
		if (this.loadingData) {
			return;
		}
		this.loadingData = true;

		this.projectDeviceService.getAll(this.currentProject).subscribe(
			devices => {
				this.devices = devices;
				this.updateMarkers();
				devices.forEach(device => {
					this.subscribeToTopics(device);
				});
				this.loadingData = false;
			},
			error => {
				console.log(error);
				this.loadingData = false;
			},
		);
	}

	updateMarkers() {
		for (let i = 0; i < this.devices.length; i++) {
			//Get device info
			let device = Object.assign(new ProjectDeviceModel(), this.devices[i]);

			//Add marker based on device type
			switch (device.baseDeviceData.deviceType) {
				case DeviceType.NoiseSensor:
				case DeviceType.AcousticLimiter:
					let value = device.getLastDataValueAsString(
						DataTypeModelKey.soundPressureLevel,
					);
					let markerIcon = 'blue-marker-noise-sensor.png';
					switch (device.getAlarmStatus(DataTypeModelKey.soundPressureLevel)) {
						case EventModelSeverity.EventModelSeverityAlarm:
							markerIcon = 'red-marker-noise-sensor.png';
							break;
						case EventModelSeverity.EventModelSeverityWarning:
							markerIcon = 'orange-marker-noise-sensor.png';
					}
					if (!device.connected) {
						markerIcon = 'dark-marker-noise-sensor.png';
					}

					let date = device.getLastDataDate(DataTypeModelKey.soundPressureLevel);
					let dateString = date ? formatDate(date, 'short', this.locale) : '-';
					let deviceName = device.name ? device.name : device.baseDeviceData.name;
					let icon = divIcon({
						iconSize: new Point(50, 69),
						iconAnchor: new Point(25, 69),
						html:
							'<div class="emma-marker"> \
									<img src="/assets/' + markerIcon + '"> \
									<div class="emma-marker-label"><div class="emma-marker-name">' +
							deviceName +
							'</div>' +
							value +
							'<div class="emma-marker-date">' +
							dateString +
							'</div></div> \
								</div>',
					});
					this.layers[i] = marker([device.location.latitude, device.location.longitude], {
						icon,
					}).on('click', () => {
						this.zone.run(() => {
							this.loadingData = true;
							this.router.navigate(['/device/' + device.id]);
						});
					});
					break;
			}

			//Update map bounds
			if (this.bounds == null) {
				this.bounds = latLngBounds(
					latLng(device.location.latitude, device.location.longitude),
					latLng(device.location.latitude, device.location.longitude),
				);
			} else {
				this.bounds.extend(latLng(device.location.latitude, device.location.longitude));
			}
		}
	}
}
