import { Optional, Inject } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateAdapterOptions } from "@angular/material-moment-adapter";
import * as moment from 'moment';
import { dateFormat } from "highcharts";

export class EmmaDateAdapter extends MomentDateAdapter {
    public static dateFormat: string = "l";

	constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
		@Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
		_options?: MatMomentDateAdapterOptions) {
		super(dateLocale, _options);
	}

	format(date: moment.Moment, displayFormat: string): string {
		date = this.clone(date);
		if (!this.isValid(date)) {
			throw Error('MomentDateAdapter: Cannot format invalid date.');
        }
        if (displayFormat=="emma") {
            displayFormat = EmmaDateAdapter.dateFormat;
        }
		return date.format(displayFormat);
	}
}
