import { Data } from './data.model';
import { DataAggregation } from './dataAggregation.model';
import { DataTypeModelKey, IDataType } from './dataType.model';
import { DeviceModel } from './device.model';
import { EventModelSeverity } from './event.model';
import { AlarmModelTimeWindow } from './alarm.model';

export class Location {
    address: string;
    latitude: number;
    longitude: number;
}

export class ProjectDeviceEvent {
    severity: EventModelSeverity;
    window: AlarmModelTimeWindow;
    eventId: string;
    timestamp: Date;
}

export class AverageData implements IDataType<DataAggregation<any>> {
    soundPressureLevel?: DataAggregation<number>;
    battery?: DataAggregation<number>;
    overload?: DataAggregation<boolean>;
    underrange?: DataAggregation<boolean>;
    powerSupplyStatus?: DataAggregation<boolean>;
    wifiSignalLevel?: DataAggregation<number>;
    cellullarSignalLevel?: DataAggregation<number>;
    temperature?: DataAggregation<number>;
    spectrum13Octave?: DataAggregation<number[]>;
    freeStorage?: DataAggregation<number>;
}

export class LastData implements IDataType<Data<any>> {
    soundPressureLevel?: Data<number>;
    battery?: Data<number>;
    overload?: Data<boolean>;
    underrange?: Data<boolean>;
    powerSupplyStatus: Data<boolean>;
    wifiSignalLevel: Data<number>;
    cellullarSignalLevel: Data<number>;
    temperature?: Data<number>;
    spectrum13Octave?: Data<number[]>;
    freeStorage?: Data<number>;
}

export class ProjectDeviceFile {
    filename: string;
    status: string;
    originalName: string;
    uploadDate: Date;
}

export class ProjectDeviceModel {
    id: string;
    name?: string;
    baseDevice: string;
    baseDeviceData: DeviceModel;
    manufacturer: string;
    project: string;
    readonly picture?: string;
    comments: string;
    ftpFolder: string;
    active: boolean;
    dataFrequency: number;
    location: Location;
    lastData: LastData;
    dataAggregation: AverageData;
    dataAggregation30: AverageData;
    fields: DataTypeModelKey[];
    connected: boolean;
    activeEvents: ProjectDeviceEvent[];
    files: ProjectDeviceFile[];

    /**
     * Fallback for retro compatibility: some old devices doese not have a device name
     * and shall use the name of the parent device.
     */
    public get deviceName() {
        if (this.name) {
            return this.name;
        }

        return this.baseDeviceData ? this.baseDeviceData.name : '';
    }

    /**
     * Extract the last data update time by cycling through it
     */
    static lastDataTime(lastData: LastData): Date {
        for (const key in lastData) {
            if (lastData.hasOwnProperty(key) && lastData[key]) {
                const data = lastData[key] as Data<any>;
                if (data.timestamp !== undefined) {
                    return data.timestamp;
                }
            }
        }

        return null;
    }

    getLastDataValueAsString(dataType: DataTypeModelKey): string {
        switch (dataType) {
            case DataTypeModelKey.soundPressureLevel:
                return this.lastData && this.lastData.soundPressureLevel
                    ? this.lastData.soundPressureLevel.value.toFixed(1) + ' dBA'
                    : '-';
        }
    }

    getLastDataDate(dataType: DataTypeModelKey): Date {
        switch (dataType) {
            case DataTypeModelKey.soundPressureLevel:
                return this.lastData && this.lastData.soundPressureLevel
                    ? this.lastData.soundPressureLevel.timestamp
                    : null;
        }
    }

    getAlarmStatus(dataType: DataTypeModelKey, window?: AlarmModelTimeWindow): EventModelSeverity {
        let status: EventModelSeverity = EventModelSeverity.EventModelSeveritySuccess;

        if (this.activeEvents && this.activeEvents[dataType]) {
            this.activeEvents[dataType].forEach(element => {
                if (element.window == window || window == null) {
                    if (status != EventModelSeverity.EventModelSeverityAlarm) {
                        status = element.severity;
                    }
                }
            });
        }

        return status;
    }
}
