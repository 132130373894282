/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@asymmetrik/ngx-leaflet/dist/leaflet/core/leaflet.directive";
import * as i3 from "@asymmetrik/ngx-leaflet/dist/leaflet/layers/leaflet-layers.directive";
import * as i4 from "@asymmetrik/ngx-leaflet/dist/leaflet/layers/control/leaflet-control-layers.directive";
import * as i5 from "./map.component";
import * as i6 from "../../services/api/projectDevice.service";
import * as i7 from "../../services/context.service";
import * as i8 from "@angular/router";
import * as i9 from "../../services/api/device.mqtt.service";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "map"], ["leaflet", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 606208, null, 0, i2.LeafletDirective, [i1.ElementRef, i1.NgZone], { fitBoundsOptions: [0, "fitBoundsOptions"], options: [1, "options"], fitBounds: [2, "fitBounds"] }, null), i1.ɵdid(2, 475136, null, 0, i3.LeafletLayersDirective, [i2.LeafletDirective, i1.IterableDiffers, i1.NgZone], { layers: [0, "layers"] }, null), i1.ɵdid(3, 475136, null, 0, i4.LeafletLayersControlDirective, [i2.LeafletDirective, i1.KeyValueDiffers, i1.NgZone], { layersControlConfig: [0, "layersControlConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundsOptions; var currVal_1 = _co.mapOptions; var currVal_2 = _co.bounds; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.layers; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.layersControl; _ck(_v, 3, 0, currVal_4); }, null); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 245760, null, 0, i5.MapComponent, [i6.ProjectDeviceService, i7.ContextService, i1.LOCALE_ID, i8.Router, i1.NgZone, i9.DeviceMqttService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-map", i5.MapComponent, View_MapComponent_Host_0, {}, {}, []);
export { MapComponentNgFactory as MapComponentNgFactory };
