<div id="device" class="page-layout emma simple carded fullwidth">
    <div class="header accent">
        <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/admin/devices'" *ngIf="pageType == 'edit'">
            <mat-icon>arrow_back</mat-icon>
        </button>

        <h1 *ngIf="pageType == 'new'">
            {{ 'DEVICES.Device' | translate }} - {{ 'DEVICES.Create' | translate }}
        </h1>
        <h1 *ngIf="pageType == 'edit'">{{ 'DEVICES.Device' | translate }} - {{ device.name }}</h1>
    </div>

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-tab-group [backgroundColor]="'header'">
                <mat-tab label="{{ 'DEVICES.BasicInfo' | translate }}">
                    <div class="content">
                        <form name="productForm" [formGroup]="deviceForm" class="company">
                            <div class="section">
                                <h2 class="section__title">
                                    <mat-icon>business</mat-icon>
                                    <div>{{ 'DEVICES.DeviceInfo' | translate }}</div>
                                </h2>

                                <!-- Device name field -->
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'DEVICES.Name' | translate }}</mat-label>
                                    <input matInput name="name" formControlName="name" placeholder="{{ 'DEVICES.Name' | translate }}" required />
                                </mat-form-field>

                                <!-- Manufacturer & protocol fields -->
                                <div class="row">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="manufacturer" placeholder="{{ 'DEVICES.Manufacturer' | translate }}"
                                            [(value)]="selectedManufacturer" required>
                                            <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer">
                                                {{ manufacturer.name }}​
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="protocol" placeholder="{{ 'DEVICES.Protocol' | translate }}" required>
                                            ​
                                            <mat-option *ngFor="let protocol of selectedManufacturer?.protocols" [value]="protocol">​
                                                {{ protocol?.name }} - {{ protocol?.endpoint }}​
                                            </mat-option>​
                                        </mat-select> ​
                                    </mat-form-field>
                                </div>

                                <!-- Device type field​ -->
                                <div class="row">

                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            {{'DEVICES.DeviceType' | translate}}
                                        </mat-label>
                                        <mat-select formControlName="deviceType" placeholder="{{ 'DEVICES.DeviceType' | translate }}" required>
                                            ​
                                            <mat-option *ngFor="let type of avaliableDeviceType" [value]="type">
                                                {{ type | translate }}
                                            </mat-option>
                                            ​
                                        </mat-select>
                                    </mat-form-field>

                                    <!-- Device model field -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{ 'DEVICES.Model' | translate }}</mat-label>
                                        <input matInput name="model" formControlName="model" placeholder="{{ 'DEVICES.Model' | translate }}"
                                            required />
                                    </mat-form-field>

                                    <!-- Device serial field -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            {{ 'DEVICES.SerialNumber' | translate }}
                                        </mat-label>
                                        <input matInput name="serialNumber" formControlName="serialNumber"
                                            placeholder="{{ 'DEVICES.SerialNumber' | translate }}" required />
                                    </mat-form-field>

                                    <!-- Device Data Frequency field -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            {{ 'DEVICES.DataFrequency' | translate }}
                                        </mat-label>
                                        <input matInput type="number" name="dataFrequency" formControlName="dataFrequency"
                                            placeholder="{{ 'DEVICES.DataFrequency' | translate }}" required />
                                    </mat-form-field>
                                </div>

                                <!-- SIM/IMEI and device calibration date -->
                                <div class="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            {{ 'DEVICES.SimOrImei' | translate }}
                                        </mat-label>
                                        <input matInput type="text" name="simOrImei" formControlName="simOrImei"
                                            placeholder="{{ 'DEVICES.SimOrImei' | translate }}" />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>
                                            {{ 'DEVICES.LastCalibrationDate' | translate }}
                                        </mat-label>
                                        <input matInput type="date" name="lastCalibrationDate"
                                            formControlName="lastCalibrationDate"
                                            placeholder="{{ 'DEVICES.LastCalibrationDate' | translate }}" />
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="form-action">
                                <button mat-raised-button class="button-save" color="primary" [disabled]="deviceForm.invalid"
                                    *ngIf="pageType == 'new'" (click)="add()">
                                    {{ 'DEVICES.ADD' | translate }}
                                </button>
                                <button mat-raised-button (click)="cancel()" *ngIf="pageType == 'new'">
                                    {{ 'DEVICES.CANCEL' | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="pageType === 'edit'" label="{{ 'DEVICES.Image' | translate }}">
                    <div class="content" fxLayout="column">
                        <h2 class="section__title">
                            <mat-icon>image</mat-icon>
                            <mat-label>{{ 'DEVICES.Image' | translate }}</mat-label>
                        </h2>
                        <ngx-file-drop *ngIf="!imageUrl" dropZoneLabel="{{ 'DEVICES.DropFiles' | translate }}" (onFileDrop)="imageDropped($event)">
                        </ngx-file-drop>
                        <div *ngIf="imageUrl && uploadedFile" fxLayout="column" fxLayoutAlign="space-between center">
                            <img [src]="imageUrl" fxFlexAlign="center">
                            <button mat-raised-button class="button-remove" color="primary" (click)="removeImage()" fxFlexAlign="center">
                                <mat-icon>delete</mat-icon>
                                {{ 'DEVICES.RemoveImage' | translate }}
                            </button>
                        </div>
                        <div *ngIf="imageUrl && !uploadedFile" fxLayout="column" fxLayoutAlign="space-between center">
                            <cl-image public-id={{imageUrl}}>
                                <cl-transformation width="800" height="600" fetch-format="auto" crop="limit">
                                </cl-transformation>
                            </cl-image>
                            <button mat-raised-button class="button-remove" color="primary" (click)="removeImage()" fxFlexAlign="center">
                                <mat-icon>delete</mat-icon>
                                {{ 'DEVICES.RemoveImage' | translate }}
                            </button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>