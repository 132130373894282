import { Injectable } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserService extends ApiService {
    constructor(protected http: HttpClient) {
        super(http);
        this.apiEndpoint = 'user';
    }

    get(id: string): Observable<UserModel> {
        return this.http.get<UserModel>(this.getUrl(id));
    }

    getAll(parameters?: object): Observable<UserModel[]> {
        return this.http.get<UserModel[]>(this.getUrl(''), parameters);
    }

    create(parameters: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(this.getUrl(''), parameters);
    }

    edit(parameters: UserModel): Observable<UserModel> {
        return this.http.put<UserModel>(this.getUrl(''), parameters);
    }

    delete(id: string): Observable<UserModel> {
        return this.http.delete<UserModel>(this.getUrl(String(id)));
    }
}
