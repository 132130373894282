import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTypeModelKey } from 'app/models/dataType.model';
import { DeviceAggregatedData } from 'app/models/deviceData.model';
import { Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
import { ContextService } from '../context.service';

@Injectable()
export class DataService extends ApiService {
    constructor(protected http: HttpClient, private readonly contextService: ContextService) {
        super(http);
        this.apiEndpoint = 'data';
    }

    getDeviceDataSample(
        deviceId: string,
        types: DataTypeModelKey[],
        numSamples: number,
        aggregate: boolean,
    ): Observable<DeviceAggregatedData> {
        const endpoint = `device/${deviceId}`;
        return this.http
            .get<DeviceAggregatedData>(this.getUrl(endpoint), {
                params: {
                    sampleSize: numSamples.toString(),
                    type: types as string[],
                    aggregate: aggregate ? 'true' : 'false',
                },
            })
            .pipe(
                catchError(err => {
                    if (err.status === 404) {
                        // Something is wrong, reload the data
                        this.contextService.forceRefresh();
                    }
                    return throwError(err);
                }),
            );
    }

    getDeviceDataRange(
        deviceId: string,
        types: DataTypeModelKey[],
        startDate: Date,
        endDate: Date,
        aggregate: boolean,
    ): Observable<DeviceAggregatedData> {
        const endpoint = `device/${deviceId}`;
        return this.http
            .get<DeviceAggregatedData>(this.getUrl(endpoint), {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    type: types as string[],
                    aggregate: aggregate ? 'true' : 'false',
                },
            })
            .pipe(
                catchError(err => {
                    if (err.status === 404) {
                        // Something is wrong, reload the data
                        this.contextService.forceRefresh();
                    }
                    return throwError(err);
                }),
            );
    }
}
