<div class="emma-card auto-width">
    <!-- CONTENT CARD -->
    <div class="content-card">
        <app-alarm-list [alarms]="alarms" (rowClick)="onAlarmClick($event)" (deleteClick)="onAlarmDelete($event)">
        </app-alarm-list>
        <!-- Add alarm button -->
        <div class="add-button">
            <button mat-fab id="add-alarm-button" aria-label="add alarm" (click)="addAlarm()"
                [@animate]="{ value: '*', params: { delay: '300ms', scale: '.2' } }">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
    </div>
    <!-- / CONTENT CARD -->
</div>

<h2>{{ 'ALARM.ProjectAlarm' | translate }}</h2>
<div class="emma-card auto-width">
    <!-- CONTENT CARD -->
    <div class="content-card">
        <app-alarm-list [alarms]="projectAlarms" (rowClick)="onProjectAlarmClick($event)" (deleteClick)="onProjectAlarmDelete($event)">
        </app-alarm-list>
    </div>
    <!-- / CONTENT CARD -->
</div>