import { Component, Input, OnInit } from '@angular/core';
import { EventModelSeverity } from 'app/models/event.model';

@Component({
    selector: 'app-alarm-type-span',
    templateUrl: './alarm-type-span.component.html',
    styleUrls: ['./alarm-type-span.component.scss'],
})
export class AlarmTypeSpanComponent implements OnInit {
    @Input()
    public severity: EventModelSeverity;

    constructor() {}

    ngOnInit() {}
}
